import { getLazyQueryHook } from "@hooks/graphql";
import * as Ops from "./CommentSummary.Operation";

const CommentSummaryQuery = {
  list: getLazyQueryHook<
    Ops.CommentSummaryResponse,
    Ops.CommentSummaryVariables
  >(Ops.CommentSummaryQuery),
};

export default CommentSummaryQuery;
