import { gql } from '@apollo/client';
import { Thread, ThreadFragment } from './Thread';
import { Message, MessageFragment } from '@schema/Message/Message';
import { EvaluationFilter } from '@schema/Evaluation/EvaluationFilter';
import { MessageInput } from '@schema/Message/MessageInput';

// Read

export const ThreadQuery = gql`
	${ThreadFragment}
	${MessageFragment}
	query Thread($id: ID!) {
		Thread(id: $id) {
			...ThreadFragment
		}
	}
`;

export type ThreadRes = {
	Thread: Thread;
};

export type ThreadVars = {
	id: string;
};

// Initiate ---------------------------------------------------------------

export const ThreadInitiateQuery = gql`
	${ThreadFragment}
	${MessageFragment}
	query ThreadInitiateQuery($filter: EvaluationFilter!, $message: String) {
		ThreadInitiate(filter: $filter, message: $message) {
			...ThreadFragment
		}
	}
`;

export type ThreadInitiateRes = {
	ThreadInitiate: Thread;
};

export type ThreadInitiateVars = {
	filter: EvaluationFilter;
	message?: string | null;
};

// Chat ----------------------------------------------------------------
export const ThreadChatQuery = gql`
	${ThreadFragment}
	${MessageFragment}
	query ThreadChatQuery(
		$message: MessageInput!
		$filter: EvaluationFilter!
		$id: String!
	) {
		ThreadChat(message: $message, filter: $filter, id: $id) {
			...ThreadFragment
		}
	}
`;

export type ThreadChatRes = {
	ThreadChat: Thread;
};

export type ThreadChatVars = {
	message: MessageInput;
	filter: EvaluationFilter;
	id: string;
};

// Message Feedback

export const MessageFeedbackMutation = gql`
	${MessageFragment}
	mutation MessageFeedbackMutation($id: String!, $feedback: Int) {
		MessageFeedback(id: $id, feedback: $feedback) {
			...MessageFragment
		}
	}
`;

export type MessageFeedbackRes = {
	MessageFeedback: Message;
};

export type MessageFeedbackVars = {
	id: string;
	feedback: number | null;
};

// Message Share
export const ThreadShareMutation = gql`
	mutation ThreadShareMutation($thread: String!) {
		ThreadShare(thread: $thread)
	}
`;

export type ThreadShareRes = {
	ThreadShare: string;
};

export type ThreadShareVars = {
	thread: string;
};
