import { Box, Chip, Typography } from '@mui/joy';
import { AttributeInputPlural } from '@schema/Attribute/AttributeInput';
import { capFirst } from '@utils/capFirst';
import { splitByCapital } from '@utils/splitByCapital';
import React, { FC } from 'react';

export interface FilterRowProps {
	option: { key: string; label: string; values: Record<string, string> };
	value: AttributeInputPlural | null;
	onChange: (value: AttributeInputPlural | null) => void;
}

const FilterRow: FC<FilterRowProps> = ({ option, value, onChange }) => {
	return (
		<Box>
			<Typography level="h4">
				{splitByCapital(capFirst(option.label))}
			</Typography>
			<Box p={1} />
			<Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
				<Chip
					onClick={() => {
						if (value) {
							if (value.values.length === 0) {
								onChange(null);
							} else {
								onChange({
									...value,
									values: [],
								});
							}
						}
					}}
					size="lg"
					variant={
						value === null
							? 'solid'
							: value.values.length === 0
							? 'solid'
							: 'outlined'
					}
					color={
						value === null
							? 'primary'
							: value.values.length === 0
							? 'primary'
							: 'neutral'
					}
					sx={{
						paddingLeft: 2,
						paddingRight: 2,
						paddingTop: 1,
						paddingBottom: 1,
						transition: 'all 0.25s ease',
					}}
				>
					All
				</Chip>
				{Object.entries(option.values).map(([k, v]) => (
					<Chip
						onClick={() => {
							if (value) {
								if (value.values.includes(k)) {
									onChange({
										...value,
										values: value.values.filter((val) => val !== k),
									});
								} else {
									onChange({
										...value,
										values: [...value.values, k],
									});
								}
							} else {
								onChange({
									key: option.key,
									values: [k],
								});
							}
						}}
						size="lg"
						variant={
							value === null
								? 'outlined'
								: value.values.includes(k)
								? 'solid'
								: 'outlined'
						}
						color={
							value === null
								? 'neutral'
								: value.values.includes(k)
								? 'primary'
								: 'neutral'
						}
						key={`${k}-${k}`}
						sx={{
							paddingLeft: 2,
							paddingRight: 2,
							paddingTop: 1,
							paddingBottom: 1,
							transition: 'all 0.25s ease',
						}}
					>
						{v}
					</Chip>
				))}
			</Box>
		</Box>
	);
};

export default FilterRow;
