import { useState, useEffect, useRef } from 'react';

export type ScrollDirection = 'up' | 'down' | 'none';

const useScrollDirection = (
	ref: React.RefObject<HTMLDivElement>,
	scrollThreshold = 100
): ScrollDirection => {
	const [scrollDirection, setScrollDirection] =
		useState<ScrollDirection>('none');
	const lastScrollY = useRef(0);

	useEffect(() => {
		const handleScroll = () => {
			if (!ref.current) return;

			const currentScrollY = ref.current.scrollTop;
			const deltaY = currentScrollY - lastScrollY.current;

			if (Math.abs(deltaY) < scrollThreshold) {
				// If the scroll change is within the threshold, do nothing.
				return;
			}

			if (deltaY > 0) {
				setScrollDirection('down');
			} else if (deltaY < 0) {
				setScrollDirection('up');
			} else {
				setScrollDirection('none');
			}
			lastScrollY.current = currentScrollY;
		};

		const currentRef = ref.current;
		currentRef?.addEventListener('scroll', handleScroll);

		return () => currentRef?.removeEventListener('scroll', handleScroll);
	}, [ref]);

	return scrollDirection;
};

export default useScrollDirection;
