const productionConfig = {
	apiKey: 'AIzaSyAtXzVma2uT1FiuqQk-8jUp2K_pV7F_5F8',
	authDomain: 'teaching-framework.firebaseapp.com',
	projectId: 'teaching-framework',
	storageBucket: 'teaching-framework.appspot.com',
	messagingSenderId: '787886161757',
	appId: '1:787886161757:web:20250a5c3349d2309d32b4',
};

export default productionConfig;
