import { useState } from 'react';

export interface SvgExport {
	loading: boolean;
	download: (type: 'svg' | 'png') => void;
}

export const useSvgExport = (
	ref: React.RefObject<SVGSVGElement>
): SvgExport => {
	const [loading, setLoading] = useState(false);
	const downloadSVG = () => {
		// Check if the ref is a mutable ref object and not null
		if (ref && 'current' in ref && ref.current) {
			setLoading(true);
			try {
				const serializer = new XMLSerializer();
				const svgStr = serializer.serializeToString(ref.current);

				const blob = new Blob([svgStr], {
					type: 'image/svg+xml;charset=utf-8',
				});
				const url = URL.createObjectURL(blob);

				const link = document.createElement('a');
				link.href = url;
				link.download = 'chart.svg'; // Name the file as you wish
				document.body.appendChild(link);
				link.click();

				document.body.removeChild(link);
				URL.revokeObjectURL(url);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.error(error);
			}
		}
	};

	const downloadAsPng = () => {
		if (ref.current) {
			setLoading(true);
			try {
				const serializer = new XMLSerializer();
				const svgStr = serializer.serializeToString(ref.current);
				const svgBlob = new Blob([svgStr], {
					type: 'image/svg+xml;charset=utf-8',
				});
				const url = URL.createObjectURL(svgBlob);

				const img = new Image();
				img.onload = () => {
					// Create canvas
					const canvas = document.createElement('canvas');
					canvas.width = img.width;
					canvas.height = img.height;
					const ctx = canvas.getContext('2d');
					ctx!.drawImage(img, 0, 0);

					// Trigger download
					const pngUrl = canvas.toDataURL('image/png');
					const link = document.createElement('a');
					link.href = pngUrl;
					link.download = 'chart.png';
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);

					URL.revokeObjectURL(url);
				};
				img.src = url;
			} catch (error) {
				console.error(error);
				setLoading(false);
			}
		}
	};

	return {
		loading,
		download: (type: 'svg' | 'png') => {
			if (type === 'svg') {
				downloadSVG();
			} else {
				downloadAsPng();
			}
		},
	};
};
