import React, { FC } from 'react';
import { Toast } from '../..';
import { Stack } from '@mui/joy';
import ToastBar from '../ToastBar';
import { motion, AnimatePresence } from 'framer-motion';

export interface ToastContainerProps {
	children: Toast[];
}

const toastVariants = {
	initial: { opacity: 0, x: 100 },
	animate: { opacity: 1, x: 0 },
	exit: { opacity: 0, y: 25 },
};

const ToastContainer: FC<ToastContainerProps> = ({ children: queue }) => {
	return (
		<Stack direction="column" gap={1} sx={{ overflow: 'hidden' }}>
			<AnimatePresence>
				{queue.map((toast) => (
					<motion.div
						key={`toast_${toast.timestamp}`}
						variants={toastVariants}
						initial="initial"
						animate="animate"
						exit="exit"
						transition={{ type: 'spring', stiffness: 200, damping: 25 }} // Spring animation
						layout
					>
						<ToastBar>{toast}</ToastBar>
					</motion.div>
				))}
			</AnimatePresence>
		</Stack>
	);
};

export default ToastContainer;
