import { gql } from '@apollo/client';
import { EvaluationFilter } from '@schema/Evaluation/EvaluationFilter';
import { SimpleSummary, SimpleSummaryFragment } from './SimpleSummary';

export const SimpleSummaryQuery = gql`
	${SimpleSummaryFragment}
	query SimpleSummaryQuery($filter: EvaluationFilter!) {
		SimpleSummary(filter: $filter) {
			...SimpleSummaryFragment
		}
	}
`;

export type SimpleSummaryArgs = {
	filter: EvaluationFilter;
};

export type SimpleSummaryRes = {
	SimpleSummary: SimpleSummary;
};
