import { gql } from '@apollo/client';
import { EvaluationProjectionCluster } from './EvaluationProjectionCluster';
import { Coordinate } from '@schema/Coordinate/Coordinate';

export class EvaluationProjectionClusterResponse {
	clusters!: EvaluationProjectionCluster[];
	min!: Coordinate;
	max!: Coordinate;
	minCount!: number;
	maxCount!: number;
}

export const EvaluationProjectionClusterResponseFragment = gql`
	fragment EvaluationProjectionClusterResponseFragment on EvaluationProjectionClusterResponse {
		clusters {
			...EvaluationProjectionClusterFragment
		}
		min {
			x
			y
		}
		max {
			x
			y
		}
		minCount
		maxCount
	}
`;
