import { getQueryHook } from '@hooks/graphql';
import * as Ops from './Attribute.Operations';

const attributeResolver = {
	get: getQueryHook<
		Ops.AttributeOptionsQueryRes,
		Ops.AttributeOptionsQueryVars
	>(Ops.AttributeOptionsQUery),
};

export default attributeResolver;
