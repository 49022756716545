import Pop from '@components/Animation/Pop';
import Logo from '@components/Display/Logo';
import { useScrollPosition } from '@hooks/useScrollPosition';
import { Box, Button, Typography, useTheme } from '@mui/joy';
import React, { FC, useRef, useState } from 'react';
import { MdArrowForward } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Toggle from './components/Toggle';
import { LandingView } from './LandingView';
import { motion } from 'framer-motion';
import What from './components/What';
import How from './components/How';
import { useScreenSize } from '@hooks/useScreenSize';

const Landing: FC = () => {
	const { palette } = useTheme();
	const nav = useNavigate();
	const scrollRef = useRef<HTMLDivElement>(null);
	const scrollPosition = useScrollPosition(scrollRef);

	const { down } = useScreenSize();

	const small = down('md');

	const [view, setView] = useState<LandingView>(LandingView.What);

	const bg = {
		light: palette.background.level1,
		contrast: palette.background.body,
	};

	const breakPoints = [600, 2050, 1000000];
	const index = breakPoints.findIndex((bp) => scrollPosition <= bp);

	const bgValue = index % 2 === 0 ? bg.light : bg.contrast;

	return (
		<Box
			sx={{
				height: '100vh',
				display: 'flex',
				flexFlow: 'column',
				overflow: 'hidden',
			}}
		>
			<Box
				sx={{
					p: 2,
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
					display: 'flex',
					flexFlow: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					background: bgValue,
					transition: 'background 0.3s',
					position: 'relative',
				}}
			>
				{!small ? (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							gap: 1.5,
						}}
					>
						<Logo style={{ fill: palette.text.primary }} height={16} />
						<Typography level="h4">Teaching Framework</Typography>
					</Box>
				) : (
					<Box>
						<Logo style={{ fill: palette.text.primary }} height={16} />
					</Box>
				)}
				<Box
					sx={{
						position: 'absolute',
						left: '50%',
						transform: 'translateX(-50%)',
					}}
				>
					<Toggle
						value={view}
						onChange={(v) => {
							setView(v);
						}}
					/>
				</Box>
				{!small && (
					<Pop>
						<Button
							color="success"
							size="lg"
							onClick={() => {
								nav('/app');
							}}
							endDecorator={<MdArrowForward />}
							variant="solid"
						>
							Get Started
						</Button>
					</Pop>
				)}
			</Box>
			<Box
				ref={scrollRef}
				sx={{
					flex: 1,
					overflowY: 'auto',
					overflowX: 'hidden',
					position: 'relative',
				}}
			>
				<motion.div
					variants={variants}
					animate={view === LandingView.What ? 'visible' : 'hidden'}
					initial="hidden"
					exit="hidden"
					key="what"
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
						zIndex: view === LandingView.What ? 1 : 0,
					}}
				>
					<What />
				</motion.div>
				<motion.div
					variants={variants}
					animate={view === LandingView.How ? 'visible' : 'hidden'}
					initial="hidden"
					exit="hidden"
					key="how"
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
						zIndex: view === LandingView.How ? 1 : 0,
					}}
				>
					<How />
				</motion.div>
			</Box>
		</Box>
	);
};

export default Landing;

const variants = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
	},
};
