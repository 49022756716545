import { gql } from '@apollo/client';

export class EvaluationStats {
	enrollment!: number;
	responses!: number;
}

export const EvaluationStatsFragment = gql`
	fragment EvaluationStatsFragment on EvaluationStats {
		enrollment
		responses
	}
`;
