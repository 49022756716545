import { gql } from '@apollo/client';

export const AttributeOptionsQUery = gql`
	query AttributeOptionsQUery {
		Options: AttributeOptions {
			key
			values
		}
	}
`;

export type AttributeOptionsQueryRes = {
	Options: {
		key: string;
		values: string[];
	}[];
};

export type AttributeOptionsQueryVars = {};
