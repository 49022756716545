import admin from "./Admin/Admin.Resolver";
import attribute from "./Attribute/Attribute.Resolver";
import commentQuestions from "./CommentQuestions/CommentQuestions.Resolver";
import commentSummary from "./CommentSummary/CommentSummary.Resolver";
import evaluation from "./Evaluation/Evaluation.Resolver";
import evaluationProjection from "./EvaluationProjection/EvaluationProjection.Resolver";
import evaluationProjectionCluster from "./EvaluationProjectionCluster/EvaluationProjectionCluster.Resolver";
import evaluationStats from "./EvaluationStats/EvaluationStats.Resolver";
import evaluationSummary from "./EvaluationSummary/EvaluationSummary.Resolver";
import evaluationTimeline from "./EvaluationTimeline/EvaluationTimeline.Resolver";
import quantitySummary from "./QuantitySummary/QuantitySummary.Resolver";
import resource from "./Resource/Resource.Resolver";
import thread from "./Thread/Thread.Resolver";
import simpleSummary from "./SimpleSummary/SimpleSummary.Resolver";

import user from "./User/User.Resolver";
import unit from "./Unit/Unit.Resolver";

const schema = {
  admin,
  attribute,
  commentQuestions,
  commentSummary,
  evaluation,
  evaluationProjection,
  evaluationProjectionCluster,
  evaluationStats,
  evaluationSummary,
  quantitySummary,
  evaluationTimeline,
  resource,
  simpleSummary,
  thread,
  user,
  unit,
};

export default schema;
