import { gql } from '@apollo/client';
import { InsightFragment } from '@schema/Insight/Insight';
import {
	EvaluationSummary,
	EvaluationSummaryFragment,
} from './EvaluationSummary';
import { EvaluationFilter } from '@schema/Evaluation/EvaluationFilter';
import { EvaluationProjectionClusterFragment } from '@schema/EvaluationProjectionCluster/EvaluationProjectionCluster';

export const EvaluationSummaryQuery = gql`
	${EvaluationSummaryFragment}
	${InsightFragment}
	${EvaluationProjectionClusterFragment}
	query EvaluationSummary(
		$filter: EvaluationFilter!
		$skipCache: Boolean
		$count: Int
	) {
		EvaluationSummary(filter: $filter, skipCache: $skipCache, count: $count) {
			...EvaluationSummaryFragment
		}
	}
`;

export type EvaluationSummaryQueryRes = {
	EvaluationSummary: EvaluationSummary | null;
};

export type EvaluationSummaryQueryVars = {
	filter: EvaluationFilter;
	skipCache?: boolean;
	count?: number;
};

// Extarct Quotes

export const ExtractQuotesQuery = gql`
	query ExtractQuotesQuery($sentiment: String!, $filter: EvaluationFilter!) {
		ExtractQuotes(sentiment: $sentiment, filter: $filter)
	}
`;

export type ExtractQuotesQueryRes = {
	ExtractQuotes: string[];
};

export type ExtractQuotesQueryVars = {
	sentiment: string;
	filter: EvaluationFilter;
};
