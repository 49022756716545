import { Box, Button } from '@mui/joy';
import React, { FC } from 'react';

interface PromptsProps {
	prompts: string[];
	onClick: (prompt: string) => void;
	loading: boolean;
}

const Prompts: FC<PromptsProps> = ({ prompts, onClick, loading }) => {
	return (
		<div>
			<Box
				sx={{
					display: 'flex',
					overflowX: 'auto',
					p: 2,
					gap: 2,
				}}
			>
				{prompts.map((prompt, index) => (
					<Button
						disabled={loading}
						key={index}
						sx={{
							minWidth: 200,
							minHeight: 150,
							display: 'flex',
						}}
						variant="outlined"
						onClick={() => prompt && onClick(prompt)}
					>
						{prompt}
					</Button>
				))}
			</Box>
		</div>
	);
};

export default Prompts;
