import { Box, useTheme } from '@mui/joy';
import React, { ReactNode, useRef, forwardRef, ForwardedRef } from 'react';
import { TooltipWithBounds, defaultStyles, useTooltip } from '@visx/tooltip';
import { Circle } from '@visx/shape';
import { Group } from '@visx/group';
import { localPoint } from '@visx/event';
import { scaleLinear } from '@visx/scale';
import { useSize } from '@utils/useSize';

export type ChartSize =
	| number
	| { width: number; height: number }
	| [number, number];

export const getChartSize = (
	size: ChartSize,
	width: number
): { width: number; height: number } => {
	if (typeof size === 'number') {
		return { width: size, height: size };
	} else if (Array.isArray(size)) {
		return { width: width, height: (size[1] / size[0]) * width };
	} else {
		return size;
	}
};

export interface CartesianProps<D> {
	size?: ChartSize;
	data: D[];
	x: (d: D) => number;
	y: (d: D) => number;
	r: (d: D) => number;
	id: (d: D) => string;
	fill?: (d: D) => string;
	opacity?: (d: D) => number;
	onClick?: (d: D) => void;
	gridLines?: {
		x?: number[];
		y?: number[];
	};
	bounds: {
		x: [number, number];
		y: [number, number];
		r: [number, number];
	};
	rRange?: [number, number];
	children?: (provided: { height: number; width: number }) => ReactNode;
	tooltip?: (d: D) => ReactNode;
}

const Cartesian = forwardRef<SVGSVGElement, CartesianProps<any>>(
	(
		{
			data,
			x,
			y,
			r,
			rRange = [2, 10],
			id,
			fill,
			opacity = () => 0.7,
			bounds,
			size = [2, 1],
			gridLines = {
				x: [],
				y: [],
			},
			children,
			tooltip,
			onClick,
		},
		ref: ForwardedRef<SVGSVGElement>
	) => {
		const containerRef = useRef<HTMLDivElement>(null);
		const { width: boxWidth } = useSize(containerRef);
		const { width, height } = getChartSize(size, boxWidth);

		const { palette } = useTheme();

		const tooltipStyles = {
			...defaultStyles,
			backgroundColor: 'transparent',
			padding: 0,
			boxShadow: 'none',
		};

		const {
			showTooltip,
			hideTooltip,
			tooltipOpen,
			tooltipData,
			tooltipLeft,
			tooltipTop,
			updateTooltip,
		} = useTooltip();

		const scales = {
			x: scaleLinear<number>({
				domain: bounds.x,
				range: [0, width],
				clamp: true,
			}),
			y: scaleLinear<number>({
				domain: bounds.y,
				range: [height, 0],
				clamp: true,
			}),
			r: scaleLinear<number>({
				domain: bounds.r,
				range: rRange,
				clamp: true,
			}),
		};

		const onMouseOver = (event: React.MouseEvent<HTMLDivElement>) => {
			const coords = localPoint(event);
			if (coords) {
				// relative to container
				const { x: mouseX, y: mouseY } = coords;

				const tolerance = 20;

				const firstInRange = data.find((d) => {
					const cx = scales.x(x(d));
					const cy = scales.y(y(d));
					const dx = mouseX - cx;
					const dy = mouseY - cy;
					return dx * dx + dy * dy < tolerance * tolerance;
				});

				if (firstInRange) {
					showTooltip({
						tooltipData: firstInRange,
						tooltipLeft: mouseX,
						tooltipTop: mouseY,
					});
				} else {
					hideTooltip();
				}
			}
		};

		const onMouseLeave = () => {
			hideTooltip();
		};

		return (
			<Box
				sx={{ position: 'relative' }}
				ref={containerRef}
				onMouseLeave={onMouseLeave}
				onMouseOver={onMouseOver}
			>
				<svg
					ref={ref} // Pass the ref to the svg element
					pointerEvents={'all'}
					width={width}
					height={height}
					overflow={'visible'}
					cursor={tooltipData && onClick ? 'pointer' : 'default'}
					onClick={() => {
						if (tooltipData && onClick) {
							onClick(tooltipData);
						}
					}}
				>
					<Group pointerEvents={'all'}>
						{gridLines.x?.map((x, i) => (
							<line
								key={i}
								x1={scales.x(x)}
								y1={0}
								x2={scales.x(x)}
								y2={height}
								stroke={palette.divider}
								strokeWidth={1}
							/>
						))}
						{gridLines.y?.map((y, i) => (
							<line
								key={i}
								x1={0}
								y1={scales.y(y)}
								x2={width}
								y2={scales.y(y)}
								stroke={palette.divider}
								strokeWidth={1}
							/>
						))}
					</Group>
					<Group>
						{data.map((d, index) => {
							const crValue = scales.r(r(d));
							const cr = crValue < 0 ? 0 : crValue;
							return (
								<Circle
									key={`circle-${id(d)}`}
									cx={scales.x(x(d))}
									cy={scales.y(y(d))}
									r={cr}
									style={{
										transition: 'r 0.15s',
									}}
									fill={fill ? fill(d) : palette.text.primary}
									opacity={opacity(d)}
								/>
							);
						})}
					</Group>
					{children ? children({ height, width }) : null}
				</svg>
				{tooltipData && tooltipOpen && tooltip ? (
					<TooltipWithBounds
						left={tooltipLeft}
						top={tooltipTop}
						style={tooltipStyles}
					>
						{tooltip(tooltipData)}
					</TooltipWithBounds>
				) : null}
			</Box>
		);
	}
);

export default Cartesian;
