import { gql } from '@apollo/client';
import { QuantitySummary, QuantitySummaryFragment } from './QuantitySummary';
import { ResponseSummaryFragment } from '@schema/ResponseSummary/ResponseSummary';
import { UnitFragment } from '@schema/Unit/Unit';
import { ResponseGroupFragment } from '@schema/ResponseGroup/ResponseGroup';
import { UnitFilter } from '@schema/Unit/Unit.Filter';
import { EvaluationFilter } from '@schema/Evaluation/EvaluationFilter';

export const QuantitySummariesQuery = gql`
	${QuantitySummaryFragment}
	${UnitFragment}
	${ResponseSummaryFragment}
	${ResponseGroupFragment}
	query QuantitySummaries(
		$unitFilter: UnitFilter!
		$evaluationFilter: EvaluationFilter!
		$keys: [String!]
	) {
		QuantitySummaries(
			unitFilter: $unitFilter
			evaluationFilter: $evaluationFilter
			keys: $keys
		) {
			...QuantitySummaryFragment
		}
	}
`;

export type QuantitySummariesQueryRes = {
	QuantitySummaries: QuantitySummary[];
};

export type QuantitySummariesQueryVars = {
	unitFilter: UnitFilter;
	evaluationFilter: EvaluationFilter;
	keys?: string[] | null;
};
