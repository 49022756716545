import React, { FC, PropsWithChildren } from 'react';
import {
	ApolloClient,
	HttpLink,
	InMemoryCache,
	ApolloProvider,
	from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { auth } from '@providers/AuthenticationProvider';

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.forEach(({ message, locations, path, extensions }) => {
			if (extensions.code === 'UNAUTHENTICATED') {
				window.location.href = '/';
			}
		});
	if (networkError) console.error(`[Network error]: ${networkError}`);
});

const authLink = setContext(
	(request) =>
		new Promise((success, fail) => {
			if (auth.currentUser) {
				auth.currentUser.getIdToken().then((token) => {
					success({
						headers: {
							authorization: token,
						},
					});
				});
			} else {
				success({});
			}
		})
);

const httpLink = new HttpLink({
	uri: process.env.REACT_APP_API_URL,
});

const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: from([errorLink, authLink, httpLink]),
});

const DataProvider: FC<PropsWithChildren> = ({ children }) => {
	return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default DataProvider;
