import {
	getLazyQueryHook,
	getMutationHook,
	getQueryHook,
} from '@hooks/graphql';
import * as Ops from './Thread.Operations';

const threadResolver = {
	get: getQueryHook<Ops.ThreadRes, Ops.ThreadVars>(Ops.ThreadQuery),
	initiate: getLazyQueryHook<Ops.ThreadInitiateRes, Ops.ThreadInitiateVars>(
		Ops.ThreadInitiateQuery
	),
	chat: getLazyQueryHook<Ops.ThreadChatRes, Ops.ThreadChatVars>(
		Ops.ThreadChatQuery
	),
	feedback: getMutationHook<Ops.MessageFeedbackRes, Ops.MessageFeedbackVars>(
		Ops.MessageFeedbackMutation
	),
	share: getMutationHook<Ops.ThreadShareRes, Ops.ThreadShareVars>(
		Ops.ThreadShareMutation
	),
};

export default threadResolver;
