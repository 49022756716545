import { gql } from '@apollo/client';

export class SimpleSummary {
	body!: string;
	takeaways!: string[];
}

export const SimpleSummaryFragment = gql`
	fragment SimpleSummaryFragment on SimpleSummary {
		body
		takeaways
	}
`;
