import BasePage from '@components/Layout/BasePage';
import { useError } from '@hooks/useError';
import { Box, Button, ToggleButtonGroup, Tooltip } from '@mui/joy';
import { UserQuery, UsersQuery } from '@schema/User/User.Operations';
import { UserUpdate } from '@schema/User/User.Update';
import schema from '@schema/index';
import React, { FC, useState } from 'react';
import { MdOutlineDeleteForever, MdOutlineKey } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import ModifyUserForm from './components/ModifyUserForm';
import { useSuccess } from '@hooks/useSuccess';
import PasswordForm from './components/PasswordForm';
import DeleteForm from './components/DeleteForm';

export interface ModifyUserProps {}

export type ModifyUserState = Omit<UserUpdate, 'assignments'> & {
	assignments?: { _id: string; name: string; type: string }[] | null;
};

const ModifyUser: FC<ModifyUserProps> = ({}) => {
	const onError = useError();
	const onSuccess = useSuccess();

	const { uid } = useParams();

	const [state, setState] = useState<ModifyUserState>({});

	const { data: userData, loading: userLoading } = schema.user.get({
		variables: {
			uid: uid!,
		},
		onError,
		onCompleted: ({ User }) => {
			setState({
				displayName: User.displayName,
				email: User.email,
				role: User.claims.role,
				assignments: User.assignments,
			});
		},
	});

	const { data: unitData, loading: unitLoading } = schema.unit.list({
		variables: {
			filter: {
				types: ['College', 'Department'],
			},
		},
	});

	const [handleUpdate, { loading: updateLoading }] = schema.user.update({
		variables: {
			uid: uid!,
			data: {
				displayName: state.displayName,
				email: state.email,
				role: state.role,
				assignments: state.assignments?.map((a) => a._id),
			},
		},
		refetchQueries: [UserQuery, UsersQuery],
		onCompleted: (updateData) => {
			setState({
				displayName: updateData.User.displayName,
				email: updateData.User.email,
				role: updateData.User.claims.role,
				assignments: updateData.User.assignments,
			});
			onSuccess('User updated!');
		},
	});

	const [handleDelete, { loading: deleteLoading }] = schema.user.delete({
		refetchQueries: [UserQuery, UsersQuery],
	});

	const loading = userLoading || unitLoading || updateLoading || deleteLoading;

	const title = !userData ? 'Loading' : userData.User.displayName || 'User';

	const units = unitData?.List.records ?? [];

	const getIsModified = () => {
		if (!userData) return false;
		const user = userData.User;
		return (
			state.displayName !== user.displayName ||
			state.email !== user.email ||
			state.role !== user.claims.role ||
			state.assignments?.length !== user.assignments.length ||
			state.assignments?.some(
				(a, i) =>
					a._id !== user.assignments[i]._id ||
					a.name !== user.assignments[i].name ||
					a.type !== user.assignments[i].type
			)
		);
	};

	const isModified = getIsModified();

	const getHoldup = () => {
		if (!state.displayName) return 'Please enter a name';
		else if (!state.email) return 'Please enter an email';
		else if (!state.role) return 'Please select a role';
		else if (state.role === 'User' && !state.assignments?.length)
			return 'Please create at least one unit assignment';
		else if (state.role === 'User' && state.assignments?.some((a) => !a._id))
			return 'Please select a unit for each assignment';
		else return null;
	};

	const holdup = getHoldup();

	return (
		<BasePage
			maxWidth={650}
			back={{
				label: 'Back to Users',
				path: '/app/admin/access',
			}}
			title={title}
			action={
				<Box
					sx={{
						display: 'flex',
						gap: 2,
					}}
				>
					<PasswordForm>
						{({ onClick }) => (
							<Button
								variant="soft"
								onClick={onClick}
								endDecorator={<MdOutlineKey />}
							>
								Reset Password
							</Button>
						)}
					</PasswordForm>
					<DeleteForm>
						{({ onClick }) => (
							<Button
								variant="soft"
								onClick={onClick}
								endDecorator={<MdOutlineDeleteForever />}
							>
								Delete Account
							</Button>
						)}
					</DeleteForm>
				</Box>
			}
		>
			<ModifyUserForm state={state} setState={setState} units={units} />
			<Box p={2} />
			<Tooltip title={holdup ?? ''} arrow>
				<Box>
					<Button
						loading={updateLoading}
						onClick={() => {
							handleUpdate();
						}}
						disabled={!isModified || Boolean(holdup)}
						color="primary"
						fullWidth
						size="lg"
					>
						Save
					</Button>
				</Box>
			</Tooltip>
		</BasePage>
	);
};

export default ModifyUser;
