import { gql } from '@apollo/client';
import {
	EvaluationProjectionClusterFragment,
	EvaluationProjectionClusterSummarized,
	EvaluationProjectionClusterTagged,
} from './EvaluationProjectionCluster';
import { EvaluationFilter } from '@schema/Evaluation/EvaluationFilter';
import {
	EvaluationProjectionClusterResponse,
	EvaluationProjectionClusterResponseFragment,
} from './EvaluationProjectionClusterResponse';

// Clusters -------------------------------------------------------------

export const EvaluationProjectionClustersQuery = gql`
	${EvaluationProjectionClusterFragment}
	${EvaluationProjectionClusterResponseFragment}
	query EvaluationProjectionClusters(
		$granularity: Float!
		$filter: EvaluationFilter!
		$mode: String
		$threshold: Float
	) {
		EvaluationProjectionClusters(
			granularity: $granularity
			filter: $filter
			mode: $mode
			threshold: $threshold
		) {
			...EvaluationProjectionClusterResponseFragment
		}
	}
`;

export type EvaluationProjectionClusterQueryRes = {
	EvaluationProjectionClusters: EvaluationProjectionClusterResponse;
};

export type EvaluationProjectionClusterQueryVars = {
	granularity: number;
	filter: EvaluationFilter;
	mode?: string;
	threshold?: number;
};

// Cluster Tag -------------------------------------------------------------

export const EvaluationProjectionClusterTagQuery = gql`
	query EvaluationProjectionClusterTagQuery(
		$y: Float!
		$x: Float!
		$mode: String!
		$granularity: Float!
		$filter: EvaluationFilter!
	) {
		EvaluationProjectionCluster(
			y: $y
			x: $x
			mode: $mode
			granularity: $granularity
			filter: $filter
		) {
			id
			ids
			count
			score
			coordinate {
				x
				y
			}
			tag
		}
	}
`;

export type EvaluationProjectionClusterTagQueryRes = {
	EvaluationProjectionCluster: EvaluationProjectionClusterTagged;
};

export type EvaluationProjectionClusterTagQueryVars = {
	x: number;
	y: number;
	granularity: number;
	filter: EvaluationFilter;
	mode: string;
};

// Cluster Summary -------------------------------------------------------------

export const EvaluationProjectionClusterSummaryQuery = gql`
	query EvaluationProjectionClusterSummaryQuery(
		$y: Float!
		$x: Float!
		$mode: String!
		$granularity: Float!
		$filter: EvaluationFilter!
	) {
		EvaluationProjectionCluster(
			y: $y
			x: $x
			mode: $mode
			granularity: $granularity
			filter: $filter
		) {
			id
			ids
			count
			score
			coordinate {
				x
				y
			}
			summary: tag(full: true)
		}
	}
`;

export type EvaluationProjectionClusterSummaryQueryRes = {
	EvaluationProjectionCluster: EvaluationProjectionClusterSummarized;
};

export type EvaluationProjectionClusterSummaryQueryVars = {
	x: number;
	y: number;
	granularity: number;
	filter: EvaluationFilter;
	mode: string;
};
