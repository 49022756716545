import CopyButton from '@components/Input/CopyButton';
import ResponsiveDialog from '@components/Layout/ResponsiveDialog';
import { useError } from '@hooks/useError';
import { useSuccess } from '@hooks/useSuccess';
import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Grid,
	IconButton,
	Input,
	ToggleButtonGroup,
	Typography,
} from '@mui/joy';
import { User } from '@schema/User/User';
import { UserQuery, UsersQuery } from '@schema/User/User.Operations';
import schema from '@schema/index';
import { generatePassword } from '@utils/generatePassword';
import React, { FC, ReactNode, useState } from 'react';
import { MdClear } from 'react-icons/md';
import { useParams } from 'react-router';

export interface PasswordFormProps {
	children: (provided: { onClick: () => void }) => ReactNode;
}

const PasswordForm: FC<PasswordFormProps> = ({ children }) => {
	const onError = useError();
	const onSuccess = useSuccess();

	const { uid } = useParams();

	const { data } = schema.user.get({
		variables: { uid: uid! },
	});

	const user = data?.User ?? null;

	const [password, setPassword] = useState('');

	const [open, setOpen] = useState(false);

	const onClose = () => {
		setOpen(false);
		setPassword('');
	};

	const [handleUpdate, { loading }] = schema.user.update({
		variables: {
			uid: uid!,
			data: {
				password,
			},
		},
		onCompleted: () => {
			onSuccess('Password reset successfully');
			onClose();
		},
		onError,
		refetchQueries: [UsersQuery, UserQuery],
	});

	return (
		<>
			{children({ onClick: () => setOpen(true) })}
			<ResponsiveDialog open={open} onClose={onClose}>
				<Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							gap: 6,
						}}
					>
						<Typography level="h4">
							{`Reset password for ${user?.displayName ?? ''}`}
						</Typography>
						<IconButton
							disabled={loading}
							onClick={() => {
								onClose();
							}}
						>
							<MdClear />
						</IconButton>
					</Box>
					<Box p={1} />
					<FormControl>
						<FormLabel>New Password</FormLabel>
						<Input
							size="lg"
							placeholder="Enter a Password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							endDecorator={
								<Box sx={{ display: 'flex', gap: 1 }}>
									<Button
										onClick={() => {
											setPassword(generatePassword(10));
										}}
										size="sm"
									>
										Generate
									</Button>
									<CopyButton disabled={!password} size="sm" value={password} />
								</Box>
							}
						/>
					</FormControl>
					<Box p={1.5} />
					<Button
						onClick={() => handleUpdate()}
						loading={loading}
						size="lg"
						variant="solid"
						fullWidth
						color="primary"
						disabled={!password}
					>
						Reset Password
					</Button>
				</Box>
			</ResponsiveDialog>
		</>
	);
};

export default PasswordForm;
