import { gql } from '@apollo/client';
import { Resource, ResourceFragment } from './Resource';
import { List } from '@schema/List/List';
import { ResourceFilter } from './Resource.Filter';
import { Sort } from '@schema/Sort/Sort';
import { ResourceCreate } from './Resource.Create';
import { ResourceUpdate } from './Resource.Update';

// Resource
export const ResourceQuery = gql`
	${ResourceFragment}
	query ResourceQuery($id: ID!) {
		Resource(id: $id) {
			...ResourceFragment
		}
	}
`;

export type ResourceQueryResult = {
	Resource: Resource;
};

export type ResourceQueryVars = {
	id: string;
};

// Resource From Link
export const ResourceFromLinkQuery = gql`
	${ResourceFragment}
	query ResourceFromLinkQuery($url: String!) {
		ResourceFromLink(url: $url) {
			...ResourceFragment
		}
	}
`;

export type ResourceFromLinkQueryResult = {
	ResourceFromLink: Resource;
};

export type ResourceFromLinkQueryVars = {
	url: string;
};

// Resource Image Options
export const ResourceImageOptionsQuery = gql`
	query ResourceImageOptionsQuery($url: String!) {
		ResourceImageOptions(url: $url)
	}
`;

export type ResourceImageOptionsQueryResult = {
	ResourceImageOptions: string[];
};

export type ResourceImageOptionsQueryVars = {
	url: string;
};

// Resource List
export const ResourceListQuery = gql`
	${ResourceFragment}
	query ResourceListQuery(
		$filter: ResourceFilter!
		$skip: Int
		$take: Int
		$sort: [Sort!]
	) {
		ResourceList(filter: $filter, skip: $skip, take: $take, sort: $sort) {
			hasMore
			total
			records {
				...ResourceFragment
			}
		}
	}
`;

export type ResourceListQueryResult = {
	ResourceList: List<Resource>;
};

export type ResourceListQueryVars = {
	filter: ResourceFilter;
	skip?: number;
	take?: number;
	sort?: Sort[];
};

// Resource Create
export const ResourceCreateMutation = gql`
	${ResourceFragment}
	mutation ResourceCreateMutation($data: ResourceCreate!) {
		ResourceCreate(data: $data) {
			...ResourceFragment
		}
	}
`;

export type ResourceCreateMutationResult = {
	ResourceCreate: Resource;
};

export type ResourceCreateMutationVars = {
	data: ResourceCreate;
};

// Resource Update
export const ResourceUpdateMutation = gql`
	${ResourceFragment}
	mutation ResourceUpdateMutation($id: String!, $data: ResourceUpdate!) {
		ResourceUpdate(id: $id, data: $data) {
			...ResourceFragment
		}
	}
`;

export type ResourceUpdateMutationResult = {
	ResourceUpdate: Resource;
};

export type ResourceUpdateMutationVars = {
	id: string;
	data: ResourceUpdate;
};

// Resource Delete
export const ResourceDeleteMutation = gql`
	mutation ResourceDeleteMutation($id: String!) {
		ResourceDelete(id: $id)
	}
`;

export type ResourceDeleteMutationResult = {
	ResourceDelete: boolean;
};

export type ResourceDeleteMutationVars = {
	id: string;
};

// Resource Search
export const ResourceSearchQuery = gql`
	${ResourceFragment}
	query ResourceSearchQuery($units: [String!], $queryString: String!) {
		ResourceSearch(units: $units, queryString: $queryString) {
			...ResourceFragment
		}
	}
`;

export type ResourceSearchQueryResult = {
	ResourceSearch: Resource[];
};

export type ResourceSearchQueryVars = {
	queryString: string;
	units?: string[];
};
