import { Box, Typography, useTheme } from '@mui/joy';
import React, { FC, ReactNode } from 'react';

export interface BarProps {
	label: ReactNode;
	value: number;
	max?: number;
}

const Bar: FC<BarProps> = ({ label, value, max = 100 }) => {
	const { palette } = useTheme();
	return (
		<Box>
			<Box
				sx={{
					position: 'relative',
					height: '8px',
				}}
			>
				<Box
					sx={{
						position: 'absolute',
						height: '100%',
						width: '100%',
						borderRadius: '12px',
						background: palette.background.level1,
						overflow: 'hidden',
					}}
				>
					<Box
						sx={{
							height: '100%',
							width: `${value}%`,
							background: palette.text.primary,
							transition: 'width 0.3s ease-in-out',
						}}
					></Box>
				</Box>
			</Box>
			<Box p={0.15} />
			{typeof label === 'string' ? (
				<Typography level="body-sm">{label}</Typography>
			) : (
				label
			)}
		</Box>
	);
};

export default Bar;
