import { useEffect, useState } from 'react';

export const useStoredState = <T>(
	key: string,
	defaultValue: T
): [T, (value: T) => void] => {
	const [value, setValue] = useState<T>(() => {
		const storedValue = localStorage.getItem(key);
		return storedValue ? JSON.parse(storedValue) : defaultValue;
	});

	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(value));
	}, [key, value]);

	return [value, setValue];
};
