import { useEffect, useState } from 'react';

export const useLoading = (watch: boolean, threshold = 200) => {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		let timeout: number;
		if (watch) {
			timeout = window.setTimeout(() => {
				setLoading(true);
			}, threshold);
		} else {
			setLoading(false);
		}
		return () => {
			window.clearTimeout(timeout);
		};
	}, [watch]);

	return loading;
};
