import { useBackgroundQuery } from '@apollo/client';
import { Box, Tooltip, Typography, useColorScheme, useTheme } from '@mui/joy';
import schema from '@schema/index';
import React, { FC, useEffect, useRef, useState } from 'react';

export interface ScoreBarProps {
	children: number | null;
	parentScore: number | null;
	parentLabel: string;
	unitLabel: string;
}

const ScoreBar: FC<ScoreBarProps> = ({
	parentScore,
	children: value,
	parentLabel,
	unitLabel,
}) => {
	const { palette } = useTheme();
	const { terrible, bad, ok, great } = useBreakpointTheme();

	const bars = [
		{
			background: terrible.background,
			color: terrible.color,
			label: '-100 - 0',
			left: 0,
			width: '50%',
		},
		{
			background: bad.background,
			color: bad.color,
			label: '0 - 50',
			left: '50%',
			width: '25%',
		},
		{
			background: ok.background,
			color: ok.color,
			label: '50 - 80',
			left: '75%',
			width: '15%',
		},
		{
			background: great.background,
			color: great.color,
			label: '80 - 100',
			left: '90%',
			width: '10%',
		},
	];

	const activeBarIndex =
		value === null ? -1 : value < 0 ? 0 : value < 50 ? 1 : value < 80 ? 2 : 3;

	const parentBarIndex =
		parentScore === null
			? -1
			: parentScore < 0
			? 0
			: parentScore < 50
			? 1
			: parentScore < 80
			? 2
			: 3;

	const bar = bars[activeBarIndex] ?? null;
	const parentBar = bars[parentBarIndex] ?? null;

	return (
		<Box>
			<Box
				sx={{
					position: 'relative',
					display: 'flex',
					height: '16px',
					background: palette.background.level1,
					borderRadius: '4px',
					alignItems: 'stretch',
				}}
			>
				{value !== null && bar && (
					<Tooltip title={unitLabel} arrow placement="top">
						<Box
							sx={{
								borderRadius: '4px',
								background: bar.background,
								width: `${(value + 100) / 2}%`,
								transition: 'width 0.5s ease',
							}}
						/>
					</Tooltip>
				)}
				{parentScore !== null && parentLabel && (
					<Tooltip title={parentLabel} arrow placement="top">
						<Box
							sx={{
								position: 'absolute',
								top: '-4px',
								bottom: '-4px',
								left: `${(parentScore + 100) / 2}%`,
								transition: 'left 0.5s ease',
								width: '4px',
								background: palette.text.secondary,
							}}
						/>
					</Tooltip>
				)}
			</Box>
			<Box p={1} />
			<Box
				sx={{
					position: 'relative',
					height: '16px',
					borderLeft: `1px solid ${palette.divider}`,
					borderRight: `1px solid ${palette.divider}`,
				}}
			>
				<Box
					sx={{
						position: 'absolute',
						top: 0,
						bottom: 0,
						left: 0,
						width: '50%',
						borderRight: `1px solid ${palette.divider}`,
					}}
				/>
				<Typography
					level="body-xs"
					sx={{
						position: 'absolute',
						top: 0,
						bottom: 0,
						left: 0,
						transform: 'translate(-50%, 18px)',
					}}
				>
					-100
				</Typography>
				<Box
					sx={{
						position: 'absolute',
						top: 0,
						bottom: 0,
						left: 0,
						width: '75%',
						borderRight: `1px solid ${palette.divider}`,
					}}
				/>
				<Typography
					level="body-xs"
					sx={{
						position: 'absolute',
						top: 0,
						bottom: 0,
						left: '50%',
						transform: 'translate(-50%, 18px)',
					}}
				>
					0
				</Typography>
				<Box
					sx={{
						position: 'absolute',
						top: 0,
						bottom: 0,
						left: 0,
						width: '90%',
						borderRight: `1px solid ${palette.divider}`,
					}}
				/>
				<Typography
					level="body-xs"
					sx={{
						position: 'absolute',
						top: 0,
						bottom: 0,
						left: '75%',
						transform: 'translate(-50%, 18px)',
					}}
				>
					50
				</Typography>
				<Typography
					level="body-xs"
					sx={{
						position: 'absolute',
						top: 0,
						bottom: 0,
						left: '90%',
						transform: 'translate(-50%, 18px)',
					}}
				>
					80
				</Typography>
				<Typography
					level="body-xs"
					sx={{
						position: 'absolute',
						top: 0,
						bottom: 0,
						right: 0,
						transform: 'translate(50%, 18px)',
					}}
				>
					100
				</Typography>
			</Box>
		</Box>
	);
};

export default ScoreBar;

export const useBreakpointTheme = () => {
	const { palette } = useTheme();

	const { colorScheme } = useColorScheme();

	return {
		terrible: {
			background: palette.danger[300],
			color: palette.danger[500],
		},
		bad: {
			background: palette.warning[300],
			color: palette.warning[500],
		},
		ok: {
			background: palette.success[300],
			color: palette.success[500],
		},
		great: {
			background: palette.success[400],
			color: palette.success[600],
		},
	};
};
