import { getMutationHook, getQueryHook } from '@hooks/graphql';
import * as Types from './User.Operations';

const userSchema = {
	get: getQueryHook<Types.UserQueryRes, Types.UserQueryArgs>(Types.UserQuery),
	list: getQueryHook<Types.UsersQueryRes, Types.UsersQueryArgs>(
		Types.UsersQuery
	),
	self: getQueryHook<Types.UserSelfQueryRes, Types.UserSelfQueryArgs>(
		Types.UserSelfQuery
	),
	update: getMutationHook<
		Types.UserUpdateMutationRes,
		Types.UserUpdateMutationArgs
	>(Types.UserUpdateMutation),
	create: getMutationHook<
		Types.UserCreateMutationRes,
		Types.UserCreateMutationArgs
	>(Types.UserCreateMutation),
	updateSelf: getMutationHook<
		Types.UserUpdateSelfMutationRes,
		Types.UserUpdateSelfMutationArgs
	>(Types.UserUpdateSelfMutation),
	delete: getMutationHook<
		Types.UserDeleteMutationRes,
		Types.UserDeleteMutationArgs
	>(Types.UserDeleteMutation),
};

export default userSchema;
