import { useEffect, useState } from 'react';

export type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const breakpoints: Record<Size, number> = {
	xs: 0,
	sm: 600,
	md: 960,
	lg: 1280,
	xl: 1920,
};

interface ScreenSizeState {
	size: Size;
	width: number;
	height: number;
	down: (size: Size) => boolean;
	up: (size: Size) => boolean;
}

const determineSize = (width: number): Size => {
	let size: Size = 'xs';
	Object.keys(breakpoints).forEach((key) => {
		if (width >= breakpoints[key as Size]) {
			size = key as Size;
		}
	});
	return size;
};

export const useScreenSize = (
	callback?: (size: Size) => void
): ScreenSizeState => {
	const initialWidth = window.innerWidth;
	const initialSize = determineSize(initialWidth);

	const [size, setSize] = useState<Size>(initialSize);
	const [width, setWidth] = useState<number>(initialWidth);
	const [height, setHeight] = useState<number>(window.innerHeight);

	const down = (size: Size) => {
		return breakpoints[size] > width;
	};

	const up = (size: Size) => {
		return breakpoints[size] <= width;
	};

	useEffect(() => {
		const onResize = () => {
			const newWidth = window.innerWidth;
			const newHeight = window.innerHeight;
			const newSize = determineSize(newWidth);

			setSize(newSize);
			setWidth(newWidth);
			setHeight(newHeight);

			if (callback) {
				callback(newSize);
			}
		};

		window.addEventListener('resize', onResize);

		onResize();

		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, [callback]);

	return {
		size,
		width,
		height,
		down,
		up,
	};
};
