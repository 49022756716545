import React from 'react';

interface PositionedProps {
	top?: number;
	right?: number;
	bottom?: number;
	left?: number;
	zIndex?: number;
}

const Positioned = <P extends object>(
	WrappedComponent: React.ComponentType<P>
): React.FC<P & PositionedProps> => {
	return ({
		top,
		right,
		bottom,
		left,
		zIndex,
		...props
	}: PositionedProps & P) => {
		return (
			<div
				style={{
					position: 'absolute',
					top,
					right,
					bottom,
					left,
					zIndex,
				}}
			>
				<WrappedComponent {...(props as P)} />
			</div>
		);
	};
};

export default Positioned;
