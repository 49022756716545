import { gql } from '@apollo/client';
import { ResponseSummary } from '@schema/ResponseSummary/ResponseSummary';
import { Unit } from '@schema/Unit/Unit';

export class QuantitySummary {
	unit!: Unit;
	responses!: ResponseSummary[];
}

export const QuantitySummaryFragment = gql`
	fragment QuantitySummaryFragment on QuantitySummary {
		unit {
			...UnitFragment
		}
		responses {
			...ResponseSummaryFragment
		}
	}
`;
