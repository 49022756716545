import { EvaluationFilter } from "@schema/Evaluation/EvaluationFilter";
import schema from "@schema/index";
import { FC, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Button,
  Typography,
  CircularProgress,
} from "@mui/joy";
import { useError } from "@hooks/useError";
import NoData from "@components/Display/NoData";

export interface CommentsProps {
  filter: EvaluationFilter;
  response: {
    question: string;
    comments: string[];
    total: number;
  };
  onValueChange: (value: number) => void;
}

const Comments: FC<CommentsProps> = ({ filter, response, onValueChange }) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [comments, setComments] = useState<string[]>(response.comments || []);
  const pageSize = 10;

  const onError = useError();
  const [fetchData, { called, loading, data }] = schema.commentSummary.list();

  useEffect(() => {
    onValueChange(comments.length);
  }, [onValueChange, comments]);

  const fetchMoreComments = () => {
    fetchData({
      variables: {
        filter: filter,
        pageSize: pageSize,
        pageNumber: pageNumber + 1,
        question: response.question,
      },
      onCompleted: (data) => {
        setComments((prevComments) => {
          const newComments = [
            ...prevComments,
            ...data.CommentSummary.responses,
          ];
          return newComments;
        });
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
      },
      onError,
    });
  };

  const fetchAllComments = () => {
    fetchData({
      variables: {
        filter: filter,
        pageSize: response.total,
        pageNumber: 1,
        question: response.question,
      },
      onCompleted: (data) => {
        setComments(data.CommentSummary.responses);
        setPageNumber(1);
      },
      onError,
    });
  };

  return (
    <Box
      sx={{
        marginY: 2,
        maxHeight: 400,
        overflowY: "auto",
        paddingRight: 1,
      }}
    >
      {comments.length === 0 ? (
        <NoData />
      ) : (
        comments.map((response, index) => (
          <Card key={index} variant="outlined" sx={{ marginBottom: 1 }}>
            <CardContent>
              <Typography>{response}</Typography>
            </CardContent>
          </Card>
        ))
      )}

      {comments.length < response.total ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button onClick={fetchMoreComments}>
            {" "}
            {loading ? <CircularProgress /> : "Show More"}
          </Button>
          <Button onClick={fetchAllComments}>
            {" "}
            {loading ? <CircularProgress /> : "Show All"}
          </Button>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export default Comments;
