import React from 'react';
import ReactDOM from 'react-dom/client';
import AuthenticationProvider from '@providers/AuthenticationProvider';
import DataProvider from '@providers/ToastProvider/DataProvider';
import ThemeProvider from '@providers/ThemeProvider';
import ToastProvider from '@providers/ToastProvider';
import ClaimsProvider from '@providers/ClaimsProvider';
import 'index.css';
import AnchorProvider from '@providers/AnchorProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import Root from '@scenes/Root';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<Router>
			<ThemeProvider>
				<ToastProvider>
					<AuthenticationProvider>
						<ClaimsProvider>
							<DataProvider>
								<AnchorProvider>
									<Root />
								</AnchorProvider>
							</DataProvider>
						</ClaimsProvider>
					</AuthenticationProvider>
				</ToastProvider>
			</ThemeProvider>
		</Router>
	</React.StrictMode>
);
