import { useToastContext } from '@providers/ToastProvider';

export const useSuccess = (): ((message: string) => void) => {
	const { enqueue } = useToastContext();

	return (message: string) => {
		enqueue({
			variant: 'success',
			message,
		});
	};
};
