import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import AccessList from './components/AccessList';
import ModifyUser from './components/ModifyUser';
import CreateUser from './components/CreateUser';

const Access: FC = () => {
	return (
		<Routes>
			<Route index element={<AccessList />} />
			<Route path=":uid" element={<ModifyUser />} />
			<Route path="new" element={<CreateUser />} />
		</Routes>
	);
};

export default Access;
