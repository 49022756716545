import BasePage from '@components/Layout/BasePage';
import React, { FC, useState } from 'react';
import {
	FormControl,
	FormLabel,
	Grid,
	Input,
	Select,
	Option,
	Box,
	Tooltip,
	IconButton,
	FormHelperText,
	Button,
} from '@mui/joy';
import { UserCreate } from '@schema/User/User.Create';
import { Role } from '@auth/Role';
import schema from '@schema/index';
import { useError } from '@hooks/useError';
import { useSuccess } from '@hooks/useSuccess';
import { useNavigate } from 'react-router-dom';
import { UserQuery, UsersQuery } from '@schema/User/User.Operations';
import Collapse from '@components/Layout/Collapse';
import AssignmentForm from '../ModifyUser/components/ModifyUserForm/components/AssignmentForm';
import { MdAdd } from 'react-icons/md';
import CopyButton from '@components/Input/CopyButton';
import { generatePassword } from '@utils/generatePassword';

export interface CreateUserProps {}

const CreateUser: FC<CreateUserProps> = ({}) => {
	const onError = useError();
	const onSuccess = useSuccess();
	const nav = useNavigate();

	const [state, setState] = useState<
		Omit<UserCreate, 'assignments'> & {
			assignments: { _id: string; name: string; type: string }[];
		}
	>({
		uid: '',
		displayName: '',
		email: '',
		password: '',
		role: Role.User,
		assignments: [{ _id: '', name: '', type: 'College' }],
	});

	const { data: unitData, loading: unitLoading } = schema.unit.list({
		variables: {
			filter: {
				types: ['College', 'Department'],
			},
		},
	});

	const { data: usersData } = schema.user.list({});

	const users = usersData?.Users ?? [];

	const existing = users.some((u) => u.uid === state.uid) ?? null;

	const units = unitData?.List.records ?? [];

	const [handleCreate, { loading }] = schema.user.create({
		variables: {
			data: {
				...state,
				assignments: state.assignments.map((a) => a._id),
			},
		},
		onError,
		onCompleted: (data) => {
			onSuccess('Account created!');
			nav(`/app/admin/access/${data.User.uid}`);
		},
		refetchQueries: [UserQuery, UsersQuery],
	});

	const getHoldup = () => {
		if (!state.displayName) return 'Please enter a name';
		else if (!state.uid) return 'Please enter a UID';
		else if (!state.email) return 'Please enter an email';
		else if (!state.role) return 'Please select a role';
		else if (state.role === 'User' && !state.assignments?.length)
			return 'Please create at least one unit assignment';
		else if (state.role === 'User' && state.assignments?.some((a) => !a._id))
			return 'Please select a unit for each assignment';
		else if (existing) return 'This UID is already in use';
		else if (!state.password) return 'Please enter a password';
		else if (state.password.length < 6)
			return 'Password must be at least 6 characters';
		else return null;
	};

	const holdup = getHoldup();

	return (
		<BasePage
			maxWidth={650}
			back={{
				path: '/app/admin/access',
				label: 'Back to Users',
			}}
			title="New Account"
		>
			<Grid container spacing={3}>
				<Grid xs={12}>
					<FormControl>
						<FormLabel>Name</FormLabel>
						<Input
							value={state.displayName ?? ''}
							onChange={(e) => {
								setState({
									...state,
									displayName: e.target.value,
								});
							}}
							placeholder="Name"
							size="lg"
							variant="soft"
						/>
					</FormControl>
				</Grid>
				<Grid xs={12}>
					<FormControl>
						<FormLabel>UID</FormLabel>
						<Input
							error={existing ? true : false}
							value={state.uid ?? ''}
							onChange={(e) => {
								setState({
									...state,
									uid: e.target.value,
								});
							}}
							placeholder="Name"
							size="lg"
							variant="soft"
						/>
						{existing && (
							<FormHelperText>This UID is already in use</FormHelperText>
						)}
					</FormControl>
				</Grid>
				<Grid xs={12}>
					<FormControl>
						<FormLabel>Email</FormLabel>
						<Input
							value={state.email ?? ''}
							onChange={(e) => {
								setState({
									...state,
									email: e.target.value,
								});
							}}
							placeholder="Name"
							size="lg"
							variant="soft"
						/>
					</FormControl>
				</Grid>
				<Grid xs={12}>
					<FormControl>
						<FormLabel>Password</FormLabel>
						<Input
							value={state.password ?? ''}
							onChange={(e) => {
								setState({
									...state,
									password: e.target.value,
								});
							}}
							placeholder="Password"
							size="lg"
							variant="soft"
							endDecorator={
								<Box sx={{ display: 'flex', gap: 1, size: 'sm' }}>
									<Button
										onClick={() => {
											setState({
												...state,
												password: generatePassword(),
											});
										}}
										variant="soft"
									>
										Generate
									</Button>
									<CopyButton
										disabled={!state.password}
										value={state.password ?? ''}
										variant="soft"
									/>
								</Box>
							}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12}>
					<FormControl>
						<FormLabel>Role</FormLabel>
						<Select
							value={state.role}
							onChange={(e, v) => {
								if (v) {
									const match = units.find(
										(unit) =>
											unit.type === 'College' &&
											!state.assignments?.find((a) => a._id === unit._id)
									);
									setState({
										...state,
										role: v,
										assignments:
											v === Role.User
												? [
														{
															_id: match?._id ?? '',
															name: match?.name ?? '',
															type: 'College',
														},
												  ]
												: [],
									});
								}
							}}
							placeholder="Select a Role"
							size="lg"
							variant="soft"
						>
							{Object.keys(Role).map((role) => (
								<Option key={role} value={role as Role}>
									{role}
								</Option>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Box sx={{ width: '100%' }}>
					<Collapse open={state.role === Role.User}>
						<Grid xs={12}>
							<FormControl>
								<FormLabel>Assignments</FormLabel>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: 2,
									}}
								>
									{state.assignments?.map((a, i) => (
										<AssignmentForm
											key={`${a._id}-${i}`}
											value={a}
											onChange={(v) => {
												if (v) {
													const assignments = [...state.assignments!];
													assignments[i] = v;
													setState({
														...state,
														assignments,
													});
												} else {
													const assignments = [...state.assignments!];
													assignments.splice(i, 1);
													setState({
														...state,
														assignments,
													});
												}
											}}
											units={units}
											removable={state.assignments?.length !== 1}
											taken={
												state.assignments
													?.filter((a) => a._id)
													.map((a) => a._id) as string[]
											}
										/>
									))}
								</Box>
							</FormControl>
							<Box p={1} />
							<Tooltip arrow title="Add a unit assignment">
								<IconButton
									variant="soft"
									size="lg"
									onClick={() => {
										const match = units.find(
											(unit) =>
												unit.type === 'College' &&
												!state.assignments?.find((a) => a._id === unit._id)
										);

										setState({
											...state,
											assignments: [
												...state.assignments!,
												{
													_id: match?._id ?? '',
													name: match?.name ?? '',
													type: 'College',
												},
											],
										});
									}}
								>
									<MdAdd />
								</IconButton>
							</Tooltip>
						</Grid>
					</Collapse>
				</Box>
				<Grid xs={12}>
					<Box p={1} />
					<Tooltip arrow title={holdup ?? ''}>
						<Box>
							<Button
								color="primary"
								disabled={holdup !== null}
								loading={loading}
								fullWidth
								variant="solid"
								size="lg"
								onClick={() => {
									handleCreate();
								}}
							>
								Create Account
							</Button>
						</Box>
					</Tooltip>
				</Grid>
			</Grid>
		</BasePage>
	);
};

export default CreateUser;
