import { Box, Typography, useTheme } from '@mui/joy';
import React, { FC, ReactNode } from 'react';
import { FaCircle } from 'react-icons/fa';

export interface BulletsProps {
	children: ReactNode | ReactNode[];
	bump?: number;
}

const Bullets: FC<BulletsProps> = ({ children, bump = 0 }) => {
	const arr = React.Children.toArray(children);
	const { palette } = useTheme();
	return (
		<Box
			sx={{
				display: 'flex',
				flexFlow: 'column',
				gap: 1,
			}}
		>
			{arr.map((child, index) => (
				<Box
					key={`bullet-${index}`}
					sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}
				>
					<Box sx={{ marginTop: `${bump}px` }}>
						<FaCircle
							style={{
								color: palette.background.level3,
								fontSize: '.5rem',
							}}
						/>
					</Box>
					{typeof child === 'string' ? <Typography>{child}</Typography> : child}
				</Box>
			))}
		</Box>
	);
};

export default Bullets;
