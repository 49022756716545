import ResponsiveDialog from '@components/Layout/ResponsiveDialog';
import { Box, Card, Divider, IconButton, Typography } from '@mui/joy';
import schema from '@schema/index';
import { Resource } from '@schema/Resource/Resource';
import React, { FC, ReactNode, useState } from 'react';
import UrlForm from './components/UrlForm';
import ResourceFormInput from './components/ResourceFormInput';
import { useError } from '@hooks/useError';
import { MdClear } from 'react-icons/md';
import {
	ResourceListQuery,
	ResourceQuery,
} from '@schema/Resource/Resource.Operations';
import { useSuccess } from '@hooks/useSuccess';

export interface ResourceFormProps {
	children: (provided: {
		onClick: (resource?: string | Resource) => void;
	}) => ReactNode;
}

type State =
	| { mode: 'create' | 'update'; type: 'url'; url: string }
	| { mode: 'create' | 'update'; type: 'resource'; resource: Resource };

const ResourceForm: FC<ResourceFormProps> = ({ children }) => {
	const onSuccess = useSuccess();
	const onError = useError();

	const [state, setState] = useState<State | null>(null);
	const [error, setError] = useState<string | null>(null);

	const onClose = () => {
		setState(null);
		setError(null);
	};

	const [handleCreate, { loading: createLoading }] = schema.resource.create({
		refetchQueries: [ResourceListQuery],
		variables:
			state && state.mode === 'create' && state.type === 'resource'
				? {
						data: {
							name: state.resource.name,
							unit: state.resource.unit._id,
							description: state.resource.description,
							metadata: state.resource.metadata,
							type: state.resource.type,
							image: state.resource.image,
							url: state.resource.url,
						},
				  }
				: undefined,
		onError,
		onCompleted: () => {
			onSuccess('Resource created!');
			onClose();
		},
	});

	const [handleUpdate, { loading: updateLoading }] = schema.resource.update({
		refetchQueries: [ResourceListQuery, ResourceQuery],
		variables:
			state && state.mode === 'update' && state.type === 'resource'
				? {
						id: state.resource._id,
						data: {
							name: state.resource.name,
							unit: state.resource.unit._id,
							description: state.resource.description,
							metadata: state.resource.metadata,
							type: state.resource.type,
							image: state.resource.image,
						},
				  }
				: undefined,
		onError,
		onCompleted: () => {
			onSuccess('Resource updated!');
			onClose();
		},
	});

	const [handleDelete, { loading: deleteLoading }] = schema.resource.delete({
		refetchQueries: [ResourceListQuery],
		onError,
		onCompleted: () => {
			onSuccess('Resource deleted');
			onClose();
		},
		variables:
			state && state.type === 'resource'
				? { id: state.resource._id }
				: undefined,
	});

	const { data: unitData } = schema.unit.list({
		variables: {
			filter: {
				types: ['University', 'College', 'Department'],
			},
			skip: 0,
			take: 1000,
		},
	});

	const units = unitData?.List.records ?? [];

	const [getResource, { loading: getLoading }] = schema.resource.getLazy();
	const [getResourceFromLink, { loading: linkLoading }] =
		schema.resource.fromLinkLazy();

	const onClick = (resource?: string | Resource) => {
		if (!resource) {
			setState({
				type: 'url',
				url: '',
				mode: 'create',
			});
		} else if (typeof resource === 'string') {
			getResource({
				variables: {
					id: resource,
				},
			});
		} else {
			setState({
				type: 'resource',
				resource,
				mode: 'update',
			});
		}
	};

	return (
		<>
			{children({ onClick })}

			<ResponsiveDialog open={Boolean(state)} onClose={onClose}>
				<Card
					variant="outlined"
					sx={{
						width: '650px',
						maxHeight: '95vh',
						display: 'flex',
						flexDirection: 'column',
						gap: 0,
						p: 0,
						overflow: 'hidden',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							paddingLeft: 3,
							paddingTop: 2,
							paddingBottom: 2,
							paddingRight: 2,
						}}
					>
						<Typography level="h3">
							{state?.mode === 'create' ? 'Create Resource' : 'Edit Resource'}
						</Typography>
						<IconButton onClick={onClose}>
							<MdClear />
						</IconButton>
					</Box>
					<Divider />
					<Box sx={{ flex: 1, overflowY: 'auto' }}>
						<Box sx={{ p: 3 }}>
							{state?.type === 'url' ? (
								<UrlForm
									loading={linkLoading}
									value={state.url}
									onChange={(url) => setState({ ...state, type: 'url', url })}
									onSubmit={() => {
										setError(null);
										getResourceFromLink({
											variables: {
												url: state.url,
											},
											onCompleted: ({ ResourceFromLink }) =>
												setState({
													...state,
													type: 'resource',
													resource: {
														_id: ResourceFromLink._id,
														name: ResourceFromLink.name,
														description: ResourceFromLink.description,
														type: ResourceFromLink.type,
														metadata: ResourceFromLink.metadata,
														image: ResourceFromLink.image,
														url: ResourceFromLink.url,
														unit: ResourceFromLink.unit,
														unitType: ResourceFromLink.unit.type,
													},
												}),
											onError: (e) => {
												setError("Couldn't fetch resource from link");
											},
										});
									}}
								/>
							) : state?.type === 'resource' ? (
								<ResourceFormInput
									loading={
										getLoading ||
										createLoading ||
										updateLoading ||
										deleteLoading
									}
									value={state.resource}
									onChange={(resource) =>
										setState({ ...state, type: 'resource', resource })
									}
									onSubmit={() => {
										if (state.mode === 'create') {
											handleCreate();
										} else {
											handleUpdate();
										}
									}}
									onDelete={
										state.mode === 'update'
											? () => {
													setError(null);
													handleDelete();
											  }
											: undefined
									}
								/>
							) : null}
							{error && (
								<Typography color="danger" level="body-sm">
									{error}
								</Typography>
							)}
						</Box>
					</Box>
				</Card>
			</ResponsiveDialog>
		</>
	);
};

export default ResourceForm;
