import { gql } from "@apollo/client";
import { EvaluationFilter } from "@schema/Evaluation/EvaluationFilter";

export const CommentQuestionsQuery = gql`
  query CommentQuestions($filter: EvaluationFilter!) {
    CommentQuestions(filter: $filter) {
      responses {
        question
        comments
        total
      }
    }
  }
`;

export interface CommentQuestionsQueryVariables {
  filter: EvaluationFilter;
}

export interface CommentQuestionsQueryResponse {
  CommentQuestions: {
    responses: {
      question: string;
      comments: string[];
      total: number;
    }[];
  };
}
