import { getQueryHook } from '@hooks/graphql';
import * as Ops from './EvaluationStats.Operations';

const evaluationStatsResolver = {
	get: getQueryHook<Ops.EvaluationStatsQueryRes, Ops.EvaluationStatsArgs>(
		Ops.EvaluationStatsQuery
	),
};

export default evaluationStatsResolver;
