import React, { FC, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import App from './scenes/App';
import Landing from './scenes/Landing';
import Signin from './scenes/Signin';
import Signup from './scenes/Signup';
import NotFound from './scenes/NotFound';
import Authenticated from '@auth/components/Authenticated';
import Unauthenticated from '@auth/components/Unauthenticated';
import { Box, useTheme } from '@mui/joy';
import Signout from './scenes/Signout';
import Chat from './scenes/Chat';

const Root: FC = () => {
	const { palette, typography } = useTheme();

	// set the background color of the root component to the body background color
	useEffect(() => {
		document.body.style.backgroundColor = palette.background.body;
	}, [palette.background.body]);

	return (
		<Box
			sx={{
				background: palette.background.body,
				...typography['body-md'],
				height: '100vh',
				width: '100vw',
				overflow: 'hidden',
			}}
		>
			<Routes>
				<Route index element={<Landing />} />
				<Route
					path="app/*"
					element={
						<Authenticated>
							<App />
						</Authenticated>
					}
				/>
				<Route path="chat/:id" element={<Chat />} />
				<Route
					path="signin"
					element={
						<Unauthenticated>
							<Signin />
						</Unauthenticated>
					}
				/>
				<Route
					path="signup"
					element={
						<Unauthenticated>
							<Signup />
						</Unauthenticated>
					}
				/>
				<Route path="signout" element={<Signout />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</Box>
	);
};

export default Root;
