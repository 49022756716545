import { gql } from '@apollo/client';
import { ResponseGroup } from '@schema/ResponseGroup/ResponseGroup';

export class ResponseSummary {
	prompt!: string;
	responses!: ResponseGroup[];
}

export const ResponseSummaryFragment = gql`
	fragment ResponseSummaryFragment on ResponseSummary {
		prompt
		responses {
			...ResponseGroupFragment
		}
	}
`;
