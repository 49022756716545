import React, { PropsWithChildren } from 'react';
import { CssVarsProvider, extendTheme } from '@mui/joy';

const theme = extendTheme({
	// colorSchemes: {
	// 	light: {
	// 		palette: {
	// 			// primary: {
	// 			// 	50: '#FFC2C2',
	// 			// 	100: '#FFADAD',
	// 			// 	200: '#FF8585',
	// 			// 	300: '#FF5C5C',
	// 			// 	400: '#FF3333',
	// 			// 	500: '#F50000',
	// 			// 	600: '#CC0000',
	// 			// 	700: '#8F0000',
	// 			// 	800: '#660000',
	// 			// 	900: '#3D0000',
	// 			// },
	// 			neutral: {
	// 				50: '#FCFCFD',
	// 				100: '#F2F3F6',
	// 				200: '#E2E4E9',
	// 				300: '#D3D4DB',
	// 				400: '#A3A4A9',
	// 				500: '#68686F',
	// 				600: '#5B5C63',
	// 				700: '#35363A',
	// 				800: '#19191B',
	// 				900: '#0C0C0D',
	// 				solidBg: '#0C0C0D',
	// 				solidHoverBg: '#19191B',
	// 				solidActiveBg: '#35363A',
	// 			},
	// 		},
	// 	},
	// 	dark: {
	// 		palette: {
	// 			// primary: {
	// 			// 	50: '#FFC2C2',
	// 			// 	100: '#FFADAD',
	// 			// 	200: '#FF8585',
	// 			// 	300: '#FF5C5C',
	// 			// 	400: '#FF3333',
	// 			// 	500: '#F50000',
	// 			// 	600: '#CC0000',
	// 			// 	700: '#8F0000',
	// 			// 	800: '#660000',
	// 			// 	900: '#3D0000',
	// 			// },
	// 			neutral: {
	// 				50: '#FCFCFD',
	// 				100: '#F2F3F6',
	// 				200: '#E2E4E9',
	// 				300: '#D3D4DB',
	// 				400: '#A3A4A9',
	// 				500: '#68686F',
	// 				600: '#5B5C63',
	// 				700: '#35363A',
	// 				800: '#19191B',
	// 				900: '#0C0C0D',
	// 				solidBg: '#35363A',
	// 				solidHoverBg: '#19191B',
	// 				solidActiveBg: '#0C0C0D',
	// 			},
	// 		},
	// 	},
	// },
	fontFamily: {
		display: 'Montserrat, sans-serif',
		body: 'Source Sans, sans-serif',
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1920,
		},
	},
	components: {
		JoyButton: {
			defaultProps: {
				color: 'neutral',
				variant: 'solid',
			},
			styleOverrides: {
				// `ownerState` contains the component props and internal state
				root: ({ ownerState, theme }) => ({
					...(ownerState.variant === 'solid' &&
						ownerState.color === 'neutral' &&
						theme.palette.mode === 'light' && {
							background: theme.palette.neutral[900],
						}),
				}),
			},
		},
		JoyInput: {
			defaultProps: {
				variant: 'soft',
			},
		},
		JoySelect: {
			defaultProps: {
				variant: 'soft',
			},
		},
		JoyAutocomplete: {
			defaultProps: {
				variant: 'soft',
			},
		},
		JoyLinearProgress: {
			defaultProps: {
				color: 'neutral',
				size: 'sm',
			},
		},
		JoySlider: {
			defaultProps: {
				color: 'neutral',
			},
		},
		JoySkeleton: {
			defaultProps: {
				animation: false,
			},
		},
	},
});

const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
	return <CssVarsProvider theme={theme}>{children}</CssVarsProvider>;
};

export default ThemeProvider;
