import React, { FC } from 'react';
import { ModifyUserState } from '../..';
import {
	Box,
	FormControl,
	FormLabel,
	Grid,
	Input,
	InputProps,
	Select,
	Option,
	IconButton,
	Tooltip,
	Button,
} from '@mui/joy';
import { Role } from '@auth/Role';
import Collapse from '@components/Layout/Collapse';
import AssignmentForm from './components/AssignmentForm';
import { Unit } from '@schema/Unit/Unit';
import { MdAdd } from 'react-icons/md';

export interface ModifyUserFormProps {
	state: ModifyUserState;
	setState: (state: ModifyUserState) => void;
	units: Unit[];
}

const ip: InputProps = {
	size: 'lg',
	variant: 'soft',
};

const ModifyUserForm: FC<ModifyUserFormProps> = ({
	state,
	setState,
	units,
}) => {
	return (
		<Box>
			<Grid container spacing={2}>
				<Grid xs={12}>
					<FormControl>
						<FormLabel>Name</FormLabel>
						<Input
							value={state.displayName ?? ''}
							onChange={(e) => {
								setState({
									...state,
									displayName: e.target.value,
								});
							}}
							placeholder="Name"
							{...ip}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12}>
					<FormControl>
						<FormLabel>Email</FormLabel>
						<Input
							value={state.email ?? ''}
							onChange={(e) => {
								setState({
									...state,
									email: e.target.value,
								});
							}}
							placeholder="Name"
							{...ip}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12}>
					<FormControl>
						<FormLabel>Role</FormLabel>
						<Select
							value={state.role}
							onChange={(e, v) => {
								if (v) {
									const match = units.find(
										(unit) =>
											unit.type === 'College' &&
											!state.assignments?.find((a) => a._id === unit._id)
									);
									setState({
										...state,
										role: v,
										assignments:
											v === Role.User
												? [
														{
															_id: match?._id ?? '',
															name: match?.name ?? '',
															type: 'College',
														},
												  ]
												: [],
									});
								}
							}}
							placeholder="Select a Role"
							size="lg"
							variant="soft"
						>
							{Object.keys(Role).map((role) => (
								<Option key={role} value={role as Role}>
									{role}
								</Option>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Box sx={{ width: '100%' }}>
					<Collapse open={state.role === Role.User}>
						<Grid xs={12}>
							<FormControl>
								<FormLabel>Assignments</FormLabel>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: 2,
									}}
								>
									{state.assignments?.map((a, i) => (
										<AssignmentForm
											key={`${a._id}-${i}`}
											value={a}
											onChange={(v) => {
												if (v) {
													const assignments = [...state.assignments!];
													assignments[i] = v;
													setState({
														...state,
														assignments,
													});
												} else {
													const assignments = [...state.assignments!];
													assignments.splice(i, 1);
													setState({
														...state,
														assignments,
													});
												}
											}}
											units={units}
											removable={state.assignments?.length !== 1}
											taken={
												state.assignments
													?.filter((a) => a._id)
													.map((a) => a._id) as string[]
											}
										/>
									))}
								</Box>
							</FormControl>
							<Box p={1} />
							<Tooltip arrow title="Add a unit assignment">
								<IconButton
									variant="soft"
									size="lg"
									onClick={() => {
										const match = units.find(
											(unit) =>
												unit.type === 'College' &&
												!state.assignments?.find((a) => a._id === unit._id)
										);

										setState({
											...state,
											assignments: [
												...state.assignments!,
												{
													_id: match?._id ?? '',
													name: match?.name ?? '',
													type: 'College',
												},
											],
										});
									}}
								>
									<MdAdd />
								</IconButton>
							</Tooltip>
						</Grid>
					</Collapse>
				</Box>
			</Grid>
		</Box>
	);
};

export default ModifyUserForm;
