import React, { FC } from 'react';
import { Box, useTheme } from '@mui/joy';
import Chip from './components/Chip';
import { LandingView } from '../../LandingView';
import { motion } from 'framer-motion';

export interface ToggleProps {
	value: LandingView;
	onChange: (view: LandingView) => void;
}

const hide = true;

export const landingViewLabels: Record<LandingView, string> = {
	[LandingView.What]: 'Feature Spotlight',
	[LandingView.How]: 'Behind The Scenes',
};

export const chipWidth = 172;

const Toggle: FC<ToggleProps> = ({ value, onChange }) => {
	const { palette } = useTheme();

	if (hide) return null;

	return (
		<Box
			sx={{
				position: 'relative',
				display: 'flex',
				background: palette.background.surface,
				alignItems: 'center',
				p: 0.5,
				borderRadius: '36px',
			}}
		>
			<motion.div
				style={{
					position: 'absolute',
					height: '40px',
					borderRadius: '20px',
					background: palette.background.level1,
					width: `${chipWidth}px`,
				}}
				animate={{
					x: Object.values(LandingView).indexOf(value) * chipWidth,
				}}
				transition={{
					type: 'spring',
					stiffness: 300,
					damping: 30,
				}}
			/>
			{Object.values(LandingView).map((view) => (
				<Chip
					key={view}
					onClick={() => {
						onChange(view);
					}}
				>
					{landingViewLabels[view]}
				</Chip>
			))}
		</Box>
	);
};

export default Toggle;
