import {
	getQueryHook,
	getMutationHook,
	getLazyQueryHook,
} from '@hooks/graphql';
import * as Ops from './Resource.Operations';

const resourceResolver = {
	get: getQueryHook<Ops.ResourceQueryResult, Ops.ResourceQueryVars>(
		Ops.ResourceQuery
	),
	getLazy: getLazyQueryHook<Ops.ResourceQueryResult, Ops.ResourceQueryVars>(
		Ops.ResourceQuery
	),
	list: getQueryHook<Ops.ResourceListQueryResult, Ops.ResourceListQueryVars>(
		Ops.ResourceListQuery
	),
	fromLink: getQueryHook<
		Ops.ResourceFromLinkQueryResult,
		Ops.ResourceFromLinkQueryVars
	>(Ops.ResourceFromLinkQuery),
	fromLinkLazy: getLazyQueryHook<
		Ops.ResourceFromLinkQueryResult,
		Ops.ResourceFromLinkQueryVars
	>(Ops.ResourceFromLinkQuery),
	imageOptions: getQueryHook<
		Ops.ResourceImageOptionsQueryResult,
		Ops.ResourceImageOptionsQueryVars
	>(Ops.ResourceImageOptionsQuery),
	update: getMutationHook<
		Ops.ResourceUpdateMutationResult,
		Ops.ResourceUpdateMutationVars
	>(Ops.ResourceUpdateMutation),
	create: getMutationHook<
		Ops.ResourceCreateMutationResult,
		Ops.ResourceCreateMutationVars
	>(Ops.ResourceCreateMutation),
	delete: getMutationHook<
		Ops.ResourceDeleteMutationResult,
		Ops.ResourceDeleteMutationVars
	>(Ops.ResourceDeleteMutation),
	search: getQueryHook<
		Ops.ResourceSearchQueryResult,
		Ops.ResourceSearchQueryVars
	>(Ops.ResourceSearchQuery),
};

export default resourceResolver;
