import { gql } from '@apollo/client';
import { Coordinate } from '@schema/Coordinate/Coordinate';

export class EvaluationProjectionCluster {
	id!: string;
	coordinate!: Coordinate;
	count!: number;
	score!: number;
	ids?: string[] | null;
}

export class EvaluationProjectionClusterTagged extends EvaluationProjectionCluster {
	tag!: string;
}

export class EvaluationProjectionClusterSummarized extends EvaluationProjectionCluster {
	summary!: string;
}

export const EvaluationProjectionClusterFragment = gql`
	fragment EvaluationProjectionClusterFragment on EvaluationProjectionCluster {
		id
		coordinate {
			x
			y
		}
		count
		score
	}
`;
