export type LandingPhoto =
	| 'Chat'
	| 'Perceptions'
	| 'Quotes'
	| 'Scatter'
	| 'Sidebar'
	| 'Cluster';

export type LandingPhotoType = 'Light' | 'Dark';

export const landingPhoto = (photo: LandingPhoto, type: LandingPhotoType) => {
	return `https://storage.googleapis.com/teaching-framework-static/Screenshots/Pilot/${photo}-${type}.png`;
};
