import React, { PropsWithChildren } from 'react';
import { motion } from 'framer-motion';

export interface PopProps {
	scale?: number;
	duration?: number;
}

const Pop: React.FC<PropsWithChildren<PopProps>> = ({
	children,
	scale = 1.03,
	duration = 0.2,
}) => {
	return (
		<motion.div
			whileHover={{ scale }}
			transition={{ type: 'spring', duration }}
			style={{
				zIndex: 1,
			}}
		>
			{children}
		</motion.div>
	);
};

export default Pop;
