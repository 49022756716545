import {
	Box,
	Divider,
	Drawer,
	Skeleton,
	Stack,
	Typography,
	useTheme,
} from '@mui/joy';
import schema from '@schema/index';
import { ResourceIcon } from '@schema/Resource/Resource';
import React, { FC, ReactNode, useState } from 'react';

export interface ResourcePanelProps {
	children: (provided: {
		onClick: (queryString: string, units: string[]) => void;
	}) => ReactNode;
}

const ResourcePanel: FC<ResourcePanelProps> = ({ children }) => {
	const { palette } = useTheme();

	const [state, setState] = useState<{
		queryString: string;
		units: string[];
	} | null>(null);

	const { data, loading } = schema.resource.search({
		variables: state ? state : undefined,
		skip: !state,
	});

	const onClose = () => {
		setState(null);
	};

	const resources = data?.ResourceSearch ?? [];

	return (
		<>
			{children({
				onClick: (queryString: string, units: string[]) => {
					setState({ queryString, units });
				},
			})}
			<Drawer anchor="right" open={Boolean(state)} onClose={onClose}>
				<Stack direction="column" sx={{ height: '100%' }}>
					<Box sx={{ p: 2, background: palette.background.level1 }}>
						<Typography
							sx={{
								fontWeight: 'bold',
								fontStyle: 'italic',
							}}
						>
							Insight
						</Typography>
						<Box p={0.5} />
						<Typography level="h4">{state?.queryString}</Typography>
					</Box>
					<Divider />
					<Box
						sx={{
							flex: 1,
							overflowY: 'auto',
							p: 2,
						}}
					>
						<Typography
							sx={{
								fontWeight: 'bold',
								fontStyle: 'italic',
							}}
						>
							Resources Related to this Insight
						</Typography>
						{loading ? (
							<Box
								sx={{
									display: 'flex',
									flexFlow: 'column',
									gap: 4,
									paddingTop: 4,
								}}
							>
								{Array.from({ length: 5 }).map((_, i) => (
									<Typography key={`insightLoader_${i}`}>
										<Skeleton>
											<Typography>
												Lorem ipsum dolor sit amet, consectetur adipiscing elit.
												Nulla nec dui nec nunc aliquam tincidunt nec nec nunc.
												Nulla nec dui nec nunc aliquam tincidunt nec nec nunc.
											</Typography>
										</Skeleton>
									</Typography>
								))}
							</Box>
						) : (
							<Box
								sx={{
									display: 'flex',
									flexFlow: 'column',
									gap: 4,
									paddingTop: 4,
								}}
							>
								{resources.map((resource, i) => (
									<Box
										onClick={() => {
											window.open(resource.url, '_blank');
										}}
										key={`quote_${i}`}
										sx={{
											display: 'flex',
											gap: 1,
											cursor: 'pointer',
											borderRadius: '8px',
											':hover': {
												background: palette.background.level1,
											},
										}}
									>
										<Box>
											{resource.image ? (
												<img
													style={{
														padding: '16px',
														width: '50px',
														objectFit: 'cover',
														background: palette.background.level1,
														borderRadius: '8px',
													}}
													src={resource.image}
													alt={resource.name}
												/>
											) : (
												<Box
													sx={{
														width: '82px',
														height: '82px',
														background: palette.background.level1,
														borderRadius: '8px',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												>
													<ResourceIcon size="2rem" />
												</Box>
											)}
										</Box>
										<Box p={1}>
											<Typography level="title-md">{resource.name}</Typography>
											<Typography level="body-sm">
												{trim(resource.description, 30)}
											</Typography>
											<Box p={0.25} />
											<Typography level="body-xs">
												{`${((resource.score ?? 0) * 100).toFixed(0)}% Match`}
											</Typography>
										</Box>
									</Box>
								))}
							</Box>
						)}
					</Box>
				</Stack>
			</Drawer>
		</>
	);
};

export default ResourcePanel;

const trim = (str: string, length: number) => {
	const words = str.split(' ');
	if (words.length <= length) return str;
	else return words.slice(0, length).join(' ') + '...';
};
