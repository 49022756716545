import React, { FC, ReactElement, ReactNode } from 'react';
import { Table as MuiTable } from '@mui/joy';

export interface TableProps<
	D,
	T extends Record<string, (d: D) => ReactNode>,
	K extends keyof T,
	I extends Partial<Record<string, number>>
> {
	data: D[];
	id: (d: D) => string;
	children: T;
	headers?: Partial<Record<K, ReactNode>>;
	onClick?: (e: React.MouseEvent<HTMLTableRowElement>, d: D) => void;
	clickDisabled?: (d: D) => boolean;
	contextMent?: (d: D) => ReactNode;
	indexMap?: I;
	stickyHeader?: boolean;
}

const Table = <
	D,
	T extends Record<string, (d: D) => ReactNode>,
	K extends keyof T,
	I extends Partial<Record<string, number>>
>({
	data,
	id,
	children,
	headers = {},
	onClick,
	contextMent,
	clickDisabled = () => false,
	indexMap = {} as I,
	stickyHeader = false,
}: TableProps<D, T, K, I>): ReactElement => {
	const getHeaderContent = (key: K): ReactNode => {
		if (headers[key]) {
			return headers[key];
		}
		return key as string;
	};

	const keyLength = Object.keys(children).length;

	const sortedKeys = Object.keys(children).sort((a, b) => {
		const aIndex = indexMap[a as string] ?? keyLength;
		const bIndex = indexMap[b as string] ?? keyLength;
		return aIndex - bIndex;
	});

	return (
		<MuiTable stickyHeader={stickyHeader} hoverRow={Boolean(onClick)}>
			<thead>
				{sortedKeys.map((key) => (
					<th key={key}>{getHeaderContent(key as K)}</th>
				))}
			</thead>
			<tbody>
				{data.map((d) => (
					<tr
						style={{
							cursor:
								onClick && !clickDisabled(d)
									? 'pointer'
									: onClick
									? 'not-allowed'
									: 'default',
						}}
						key={id(d)}
						onClick={(e) => {
							if (onClick) {
								if (!clickDisabled(d)) {
									onClick(e, d);
								}
							}
						}}
					>
						{sortedKeys.map((key) => (
							<td
								key={key}
								style={{
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}
							>
								{children[key as K](d)}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</MuiTable>
	);
};

export default Table;
