import React, { FC, useState } from "react";
import { DashboardScope } from "../../useDashboardScope";
import { Unit } from "@schema/Unit/Unit";
import { Box, Button, Divider, Stack, Tooltip } from "@mui/joy";
import Breadcrumbs from "./components/Breadcrumbs";
import Title from "./components/Title";
import Chips from "./components/Chips";
import Collapse from "@components/Layout/Collapse";
import Chat from "../Chat";
import { EvaluationType } from "@schema/Evaluation/EvaluationType";
import { FaMagic } from "react-icons/fa";
import EvaluationReport from "../Report";
import { BsFileEarmarkPdf } from "react-icons/bs";
import schema from "@schema/index";
import RawComments from "../RawComments";

export interface HeaderProps {
  scope: DashboardScope;
  setScope: (scope: DashboardScope) => void;
  type: string;
  setType: (type: string) => void;
  options: string[];
  units: Unit[];
  onNav: (unit: Unit) => void;
  open: boolean;
  evaluationType: EvaluationType;
}

const Header: FC<HeaderProps> = ({
  scope,
  setScope,
  type,
  setType,
  options,
  units,
  onNav,
  open,
  evaluationType,
}) => {
  const [showComments, setShowComments] = useState<boolean>(false);

  const { data } = schema.unit.list({
    variables: {
      skip: 0,
      take: 20,
      filter: { ids: scope.ancestry },
    },
    onCompleted: (data) => {
      const status = data.List.records.some(
        (unit) => unit.type === "Instructor" || unit.type === "Course"
      );
      setShowComments(status);
    },
  });

  return (
    <Box
      sx={{
        paddingLeft: 1.5,
        paddingRight: 1.5,
      }}
    >
      <Collapse open={open}>
        <Box p={0.5} />
        <Breadcrumbs
          onClick={(clicked) => {
            const index = scope.ancestry.findIndex((id) => id === clicked._id);
            if (index === -1) {
              return;
            } else {
              setScope({
                ...scope,
                ancestry: scope.ancestry.slice(0, index + 1),
              });
            }
          }}
          units={units}
          sx={{ marginLeft: -1 }}
        />
      </Collapse>
      <Box p={0.5} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title unit={units[units.length - 1] ?? null} />
        <Stack direction="row" spacing={2}>
          <EvaluationReport
            filter={{
              attributes: scope.attributes,
              ancestry: scope.ancestry,
              type: evaluationType,
              anchorMin: scope.anchors.start,
              anchorMax: scope.anchors.end,
            }}
          >
            {({ onClick, loading: reportLoading, error: reportError }) => (
              <Button
                loading={reportLoading}
                disabled={Boolean(reportError)}
                endDecorator={<BsFileEarmarkPdf />}
                variant="soft"
                color="neutral"
                onClick={onClick}
              >
                Report
              </Button>
            )}
          </EvaluationReport>

          {showComments && (
            <RawComments
              filter={{
                attributes: scope.attributes,
                ancestry: scope.ancestry,
                type: evaluationType,
                anchorMin: scope.anchors.start,
                anchorMax: scope.anchors.end,
              }}
            >
              {({ onClick }) => (
                <Button onClick={onClick}>Show Comments</Button>
              )}
            </RawComments>
          )}

          <Chat
            filter={{
              attributes: scope.attributes,
              ancestry: scope.ancestry,
              type: evaluationType,
              anchorMin: scope.anchors.start,
              anchorMax: scope.anchors.end,
            }}
          >
            {({ onClick, error }) => (
              <Tooltip
                title={
                  error
                    ? "Sorry, the chat isn't working properly for this unit."
                    : ""
                }
                arrow
              >
                <Box>
                  <Button
                    disabled={Boolean(error)}
                    color="primary"
                    onClick={() => onClick()}
                    endDecorator={<FaMagic />}
                  >
                    Ask Questions
                  </Button>
                </Box>
              </Tooltip>
            )}
          </Chat>
        </Stack>
      </Box>
      <Collapse open={open}>
        <Collapse open={units[units.length - 1]?.type !== "Listing"}>
          <Box p={0.5} />
          <Chips
            type={type}
            setType={setType}
            options={options}
            ancestry={scope.ancestry}
            onClick={onNav}
          />
        </Collapse>
      </Collapse>
      <Box p={0.5} />
      <Divider />
    </Box>
  );
};

export default Header;
