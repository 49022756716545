import React, { FC } from 'react';
import { NetScore } from '../..';
import Collapse from '@components/Layout/Collapse';
import { Box, Table, useTheme } from '@mui/joy';
import { insightScores } from '@schema/Evaluation/utils';
import { commas } from '@utils/commas';

export interface NetExplanationProps {
	score: NetScore;
	open: boolean;
}

const NetExplanation: FC<NetExplanationProps> = ({
	open,
	score: { groups },
}) => {
	const { palette } = useTheme();

	const positive = groups.filter((g) => insightScores[g.value] > 0);
	const negative = groups.filter((g) => insightScores[g.value] < 0);
	const neutral = groups.filter((g) => insightScores[g.value] === 0);

	const positiveCount = positive.reduce((acc, g) => acc + g.count, 0);
	const negativeCount = negative.reduce((acc, g) => acc + g.count, 0);
	const neutralCount = neutral.reduce((acc, g) => acc + g.count, 0);

	const total = positiveCount + negativeCount + neutralCount;

	const positiveShare = (positiveCount / total) * 100;
	const negativeShare = (negativeCount / total) * 100;

	const calculatedScore = positiveShare - negativeShare;

	return (
		<Collapse open={open}>
			<Box p={4} />
			<Table
				size="sm"
				variant="plain"
				borderAxis="none"
				style={{
					borderCollapse: 'collapse',
				}}
			>
				<tbody>
					<tr>
						<td style={{ width: '14px' }} />
						<td style={{ width: '120px' }}>Positive Responses</td>
						<td style={{ width: '44px' }}>{positiveShare.toFixed(1)}%</td>
						<td>({commas(positiveCount)})</td>
					</tr>
					<tr
						style={{
							borderBottom: `1px solid ${palette.divider}`,
						}}
					>
						<td style={{ width: '14px' }}>-</td>
						<td style={{ width: '120px' }}>Negative Responses</td>
						<td style={{ width: '44px' }}>{negativeShare.toFixed(1)}%</td>
						<td>({commas(negativeCount)})</td>
					</tr>
					<tr>
						<td style={{ width: '14px' }}>=</td>
						<td style={{ width: '120px' }}>Net Score</td>
						<td style={{ width: '44px' }}>{calculatedScore.toFixed(1)}%</td>
					</tr>
				</tbody>
			</Table>
		</Collapse>
	);
};

export default NetExplanation;
