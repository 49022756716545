import { Input, InputProps } from '@mui/joy';
import React, { FC, useEffect, useState } from 'react';

export interface DebounceInputProps
	extends Omit<InputProps, 'value' | 'onChange'> {
	value: string;
	onChange: (value: string) => void;
}

const threshold = 300;

const DebounceInput: FC<DebounceInputProps> = ({
	value,
	onChange,
	...props
}) => {
	const [inputState, setInputState] = useState(value);

	useEffect(() => {
		const timer = setTimeout(() => {
			onChange(inputState);
		}, threshold);

		return () => clearTimeout(timer);
	}, [inputState]);

	useEffect(() => {
		setInputState(value);
	}, [value]);

	return (
		<Input
			value={inputState}
			onChange={(e) => setInputState(e.target.value)}
			{...props}
		/>
	);
};

export default DebounceInput;
