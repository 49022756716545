import { gql } from '@apollo/client';
import { Attribute } from '@schema/Attribute/Attribute';
import { Identified } from '@schema/Identified/Identified';

export class Unit extends Identified {
	name!: string;
	type!: string;
	// parents!: Ref<Unit, string>[];
	// ancestry!: Ref<Unit, string>[];
	attributes!: Attribute[];
	// anchors!: number[];
}

export const UnitFragment = gql`
	fragment UnitFragment on Unit {
		_id
		name
		type
		attributes {
			key
			value
		}
	}
`;
