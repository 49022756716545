import { Box, LinearProgress } from '@mui/joy';
import React, { FC } from 'react';

const Loading: FC = () => {
	return (
		<Box>
			<LinearProgress />
		</Box>
	);
};

export default Loading;
