import Logo from '@components/Display/Logo';
import ResponsiveDialog from '@components/Layout/ResponsiveDialog';
import { useScreenSize } from '@hooks/useScreenSize';
import {
	Box,
	Button,
	Divider,
	FormControl,
	FormLabel,
	Input,
	Typography,
	useTheme,
} from '@mui/joy';
import React, { FC, useState } from 'react';
import { MdArrowForward } from 'react-icons/md';
import {
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
} from '@firebase/auth';
import { auth } from '@providers/AuthenticationProvider';
import { useError } from '@hooks/useError';
import { useSuccess } from '@hooks/useSuccess';

const Signin: FC = () => {
	const onSuccess = useSuccess();
	const onError = useError();
	const { width } = useScreenSize();
	const { palette } = useTheme();

	const small = width < 400;

	const [state, setState] = useState({
		email: '',
		password: '',
	});

	const [loading, setLoading] = useState(false);

	const [forgotPassword, setForgotPassword] = useState<null | string>(null);

	const handleReset = async () => {
		if (forgotPassword) {
			setLoading(true);
			sendPasswordResetEmail(auth, forgotPassword)
				.then(() => {
					setLoading(false);
					setForgotPassword(null);
					onSuccess('Reset email sent');
				})
				.catch(() => {
					setLoading(false);
					onError(new Error('Failed to send reset email'));
				});
		}
	};

	const handleSignin = async () => {
		if (state.email && state.password) {
			setLoading(true);
			signInWithEmailAndPassword(auth, state.email, state.password)
				.then((credential) => {
					setLoading(false);
					if (credential.user.displayName) {
						onSuccess(`Welcome back, ${credential.user.displayName}`);
					} else {
						onSuccess('Welcome back');
					}
				})
				.catch(() => {
					setLoading(false);
					onError(new Error('Failed to sign in'));
				});
		}
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100vh',
				width: '100vw',
			}}
		>
			<Box
				sx={{
					flex: small ? 1 : undefined,
					width: small ? '100%' : 400,
					height: small ? '100%' : undefined,
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Box p={4}>
					<Box
						sx={{
							display: 'flex',
							flexFlow: 'column',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Logo
							height={24}
							style={{
								fill: palette.text.primary,
							}}
						/>
						<Box p={2} />
						<Typography level="h3">Welcome Back</Typography>
					</Box>
					<Box p={1} />
					<Divider />
					<Box p={1} />
					<FormControl>
						<FormLabel>Email</FormLabel>
						<Input
							size="lg"
							placeholder="Email"
							value={state.email}
							onChange={(e) => {
								setState({ ...state, email: e.target.value });
							}}
						/>
					</FormControl>
					<Box p={2} />
					<FormControl>
						<FormLabel>Password</FormLabel>
						<Input
							size="lg"
							placeholder="Password"
							value={state.password}
							onChange={(e) => {
								setState({ ...state, password: e.target.value });
							}}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									handleSignin();
								}
							}}
							type="password"
						/>
					</FormControl>
				</Box>
				<Box sx={{ flex: 1 }} />
				<Box
					p={4}
					sx={{
						paddingTop: 0,
					}}
				>
					<Button
						color="primary"
						size="lg"
						fullWidth
						loading={loading}
						onClick={() => {
							handleSignin();
						}}
					>
						Signin
					</Button>
				</Box>
				<Box
					p={4}
					sx={{
						display: 'flex',
						flexFlow: 'column',
						alignItems: 'flex-start',
					}}
				>
					<Button
						onClick={() => {
							setForgotPassword('');
						}}
						endDecorator={<MdArrowForward />}
						variant="plain"
						size="sm"
					>
						I forgot my password
					</Button>
				</Box>
			</Box>
			<ResponsiveDialog
				open={forgotPassword !== null}
				onClose={() => {
					setForgotPassword(null);
				}}
			>
				<Box>
					<Typography level="h3">Forgot Password</Typography>
					<Box p={1} />
					<Typography>
						Please enter your email address and we will send you a link to reset
						your password.
					</Typography>
					<Box p={1.5} />
					<Input
						size="lg"
						placeholder="Email"
						value={forgotPassword || ''}
						onChange={(e) => {
							setForgotPassword(e.target.value);
						}}
					/>
					<Box p={1.5} />
					<Button
						loading={loading}
						disabled={!forgotPassword}
						color="primary"
						size="lg"
						fullWidth
						onClick={() => {
							handleReset();
						}}
					>
						Send Reset Link
					</Button>
				</Box>
			</ResponsiveDialog>
		</Box>
	);
};

export default Signin;
