import { getQueryHook } from '@hooks/graphql';
import * as Ops from './QuantitySummary.Operations';

const quantitySummaryResolver = {
	get: getQueryHook<
		Ops.QuantitySummariesQueryRes,
		Ops.QuantitySummariesQueryVars
	>(Ops.QuantitySummariesQuery),
};

export default quantitySummaryResolver;
