import { useError } from '@hooks/useError';
import { Card, Skeleton, Typography } from '@mui/joy';
import { EvaluationFilter } from '@schema/Evaluation/EvaluationFilter';
import { EvaluationProjectionCluster } from '@schema/EvaluationProjectionCluster/EvaluationProjectionCluster';
import schema from '@schema/index';
import React, { FC, useEffect, useState } from 'react';

export interface TopicTooltipProps {
	children: EvaluationProjectionCluster;
	mode: string;
	granularity: number;
	filter: EvaluationFilter;
}

const threshold = 2000;

const TopicTooltip: FC<TopicTooltipProps> = ({
	children: cluster,
	mode,
	granularity,
	filter,
}) => {
	const [tag, setTag] = useState('');

	const onError = useError();

	const [getTag, { loading }] = schema.evaluationProjectionCluster.tagged({
		variables: {
			x: cluster.coordinate.x,
			y: cluster.coordinate.y,
			granularity,
			filter,
			mode,
		},
		onCompleted: (data) => {
			setTag(data.EvaluationProjectionCluster.tag);
		},
		onError,
	});

	useEffect(() => {
		const timeout = setTimeout(() => {
			getTag();
		}, threshold);

		return () => {
			clearTimeout(timeout);
		};
	}, []);

	return (
		<Card>
			<Typography>
				<Skeleton animation="wave" loading={!tag || loading}>
					{tag || 'loading loading loading'}
				</Skeleton>
			</Typography>
		</Card>
	);
};

export default TopicTooltip;
