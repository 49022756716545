import { EvaluationFilter } from '@schema/Evaluation/EvaluationFilter';
import schema from '@schema/index';
import React, { FC } from 'react';
import { useSection } from '../Seciton';
import { useError } from '@hooks/useError';
import { Box, Skeleton, Typography } from '@mui/joy';
import Problem from '@scenes/Root/scenes/Problem';
import NoData from '@components/Display/NoData';
import Bullets from '@components/Display/Bullets';

export interface SummaryProps {
	filter: EvaluationFilter;
}

const Summary: FC<SummaryProps> = ({ filter }) => {
	const onError = useError();

	const { data, error, loading } = schema.simpleSummary.get({
		variables: { filter },
		onError,
	});

	useSection(loading);

	if (error)
		return (
			<NoData sx={{ height: '250px' }}>
				<Typography>
					We're sorry, There was a problem loading the summary.
				</Typography>
			</NoData>
		);

	return (
		<Box>
			<Typography level="body-sm">
				Summary of Student Course Feedback, Peer Reviews, and Instructor
				Self-Reflections
			</Typography>
			<Box p={0.5} />
			<Typography
				level="body-lg"
				sx={{
					// break lines at paragraph
					overflowWrap: 'break-word',
					whiteSpace: 'pre-wrap',
				}}
			>
				<Skeleton loading={!data}>
					{data?.SimpleSummary.body ??
						Array.from({ length: 150 })
							.map((_, i) => 'Words')
							.join(' ')}
				</Skeleton>
			</Typography>
			<Box p={1.5} />
			<Box>
				<Typography level="body-sm">Key Takeaways</Typography>
				<Box p={0.5} />
				<Bullets>
					{(
						data?.SimpleSummary.takeaways ??
						Array.from({ length: 3 }).map(
							(_, i) => 'Takeaway Takeaway Takeaway Takeaway'
						)
					).map((takeaway, i) => (
						<Typography key={`takeaway-${i}`} level="body-md">
							<Skeleton loading={!data}>{takeaway}</Skeleton>
						</Typography>
					))}
				</Bullets>
			</Box>
		</Box>
	);
};

export default Summary;
