import { Box, LinearProgress } from '@mui/joy';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC } from 'react';

export interface LoaderProps {
	loading?: boolean;
}

const threshold = 200;

const Loader: FC<LoaderProps> = ({ loading = true }) => {
	const [show, setShow] = React.useState(false);

	React.useEffect(() => {
		let timeout: number;
		if (loading) {
			timeout = window.setTimeout(() => {
				setShow(true);
			}, threshold);
		} else {
			setShow(false);
		}
		return () => {
			window.clearTimeout(timeout);
		};
	}, [loading]);

	return (
		<AnimatePresence>
			{show && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
					}}
				>
					<LinearProgress />
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default Loader;
