import DebounceInput from '@components/Input/DebounceInput';
import { useError } from '@hooks/useError';
import { Box, Chip, Option, Select, useTheme } from '@mui/joy';
import { nav } from '@scenes/Root/scenes/App/navigation/Nav';
import { List } from '@schema/List/List';
import { Unit } from '@schema/Unit/Unit';
import schema from '@schema/index';
import { useSize } from '@utils/useSize';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, useEffect, useRef, useState, useTransition } from 'react';
import { MdSearch } from 'react-icons/md';
import ChipDrawer from './components/ChipDrawer';
import Pop from '@components/Animation/Pop';

export interface ChipsProps {
	type: string;
	setType: (type: string) => void;
	options: string[];
	ancestry: string[];
	onClick: (unit: Unit) => void;
}

const Chips: FC<ChipsProps> = ({
	type,
	setType,
	options,
	ancestry,
	onClick,
}) => {
	const [pending, startTransition] = useTransition();
	const onError = useError();

	const ref = useRef<HTMLDivElement>(null);
	const { width } = useSize(ref);

	const { palette } = useTheme();

	const [name, setName] = useState('');

	useEffect(() => {
		setName('');
	}, [options]);

	const [list, setList] = useState<List<Unit>>({
		records: [],
		total: 0,
		hasMore: false,
	});

	const { loading } = schema.unit.list({
		variables: {
			filter: {
				name,
				type,
				ancestry,
			},
			skip: 0,
			take: 15,
		},
		onError,
		onCompleted: (data) => {
			startTransition(() => {
				setList(data.List);
			});
		},
	});

	const { units } = list.records.reduce<{ units: Unit[]; full: boolean }>(
		(acc, unit) => {
			if (acc.full) return acc;
			const runningWidth = [...acc.units, unit].reduce(
				(rAcc, u) => rAcc + chipWidth(u.name),
				0
			);

			if (runningWidth > width) {
				return { units: acc.units, full: true };
			} else {
				return { units: [...acc.units, unit], full: false };
			}
		},
		{ units: [], full: false }
	);

	const more = list.total - units.length;

	return (
		<Box sx={{ display: 'flex', gap: 1 }}>
			<Box
				sx={{
					background: palette.background.level1,
					borderRadius: '8px',
					display: 'flex',
				}}
			>
				<DebounceInput
					placeholder="Search"
					sx={{ width: '140px' }}
					size="sm"
					value={name}
					onChange={setName}
					startDecorator={<MdSearch />}
				/>
				<Select
					sx={{ width: '120px', border: 'none' }}
					value={type}
					onChange={(e, v) => {
						if (v) {
							setType(v);
						}
					}}
					size="sm"
					renderValue={(v) => v?.value + 's' ?? ''}
				>
					{options.map((option) => (
						<Option key={option} value={option}>
							{option}s
						</Option>
					))}
				</Select>
			</Box>
			<Box sx={{ flex: 1, display: 'flex', gap: 1 }} ref={ref}>
				{units.map((unit, index) => (
					<AnimatePresence key={`chip_${unit._id}`}>
						<motion.div
							variants={motionVariants}
							initial="hidden"
							animate="visible"
							transition={{ duration: 0.3 }}
							custom={index}
						>
							<Pop>
								<Chip
									onClick={() => {
										onClick(unit);
									}}
									sx={{
										height: '32px',
										paddingLeft: 2,
										paddingRight: 2,
									}}
								>
									{unit.name}
								</Chip>
							</Pop>
						</motion.div>
					</AnimatePresence>
				))}
				{!loading && more > 0 && (
					<AnimatePresence>
						<motion.div
							variants={motionVariants}
							initial="hidden"
							animate="visible"
							transition={{ duration: 0.3 }}
							custom={units.length + 1}
						>
							<ChipDrawer type={type} ancestry={ancestry} onClick={onClick}>
								{({ onClick: unitClick }) => (
									<Pop>
										<Chip
											onClick={unitClick}
											sx={{
												height: '32px',
												paddingLeft: 2,
												paddingRight: 2,
											}}
										>
											+{more} more
										</Chip>
									</Pop>
								)}
							</ChipDrawer>
						</motion.div>
					</AnimatePresence>
				)}
			</Box>
		</Box>
	);
};

export default Chips;

const chipWidth = (label: string) => {
	const length = label.length;
	return length * 10 + 24;
};

const motionVariants = {
	hidden: {
		opacity: 0,
		y: 20,
	},
	visible: (i: number) => ({
		opacity: 1,
		y: 0,
		transition: {
			delay: i * 0.1,
		},
	}),
};
