import { getQueryHook } from '@hooks/graphql';
import * as Ops from './EvaluationTimeline.Operations';

const evalutionTimelineResolver = {
	get: getQueryHook<Ops.EvaluationTimelineQueryRes, Ops.EvaluationTimelineArgs>(
		Ops.EvaluationTimelineQuery
	),
};

export default evalutionTimelineResolver;
