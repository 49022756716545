// User Query
// -----------------------------------

import { gql } from '@apollo/client';
import {
	UserFragment,
	UserMetadataFragmment,
	UserInfoFragmment,
	ClaimsFragmment,
	User,
} from './User';
import { UserCreate } from './User.Create';
import { UserUpdateSelf } from './User.Update.Self';
import { UserUpdate } from './User.Update';

export const UserQuery = gql`
	${UserFragment}
	${UserMetadataFragmment}
	${UserInfoFragmment}
	${ClaimsFragmment}
	query User($uid: ID!) {
		User(uid: $uid) {
			...UserFragment
		}
	}
`;

export type UserQueryRes = {
	User: User;
};

export type UserQueryArgs = {
	uid: string;
};

// User Self Query
// -----------------------------------

export const UserSelfQuery = gql`
	${UserFragment}
	${UserMetadataFragmment}
	${UserInfoFragmment}
	${ClaimsFragmment}
	query UserSelf {
		User: UserSelf {
			...UserFragment
		}
	}
`;

export type UserSelfQueryRes = {
	User: User;
};

export type UserSelfQueryArgs = {};

// Users Query
// -----------------------------------
export const UsersQuery = gql`
	${UserFragment}
	${UserMetadataFragmment}
	${UserInfoFragmment}
	${ClaimsFragmment}
	query Users {
		Users {
			...UserFragment
		}
	}
`;

export type UsersQueryRes = {
	Users: User[];
};

export type UsersQueryArgs = {};

// User Create Mutation
// -----------------------------------
export const UserCreateMutation = gql`
	${UserFragment}
	${UserMetadataFragmment}
	${UserInfoFragmment}
	${ClaimsFragmment}
	mutation UserCreate($data: UserCreate!) {
		User: UserCreate(data: $data) {
			...UserFragment
		}
	}
`;

export type UserCreateMutationRes = {
	User: User;
};

export type UserCreateMutationArgs = {
	data: UserCreate;
};

// User Update Mutation
// -----------------------------------
export const UserUpdateMutation = gql`
	${UserFragment}
	${UserMetadataFragmment}
	${UserInfoFragmment}
	${ClaimsFragmment}
	mutation UserUpdate($data: UserUpdate!, $uid: ID!) {
		User: UserUpdate(data: $data, uid: $uid) {
			...UserFragment
		}
	}
`;

export type UserUpdateMutationRes = {
	User: User;
};

export type UserUpdateMutationArgs = {
	data: UserUpdate;
	uid: string;
};

// User Update Self Mutation
// -----------------------------------
export const UserUpdateSelfMutation = gql`
	${UserFragment}
	${UserMetadataFragmment}
	${UserInfoFragmment}
	${ClaimsFragmment}
	mutation UserUpdateSelf($data: UserUpdateSelf!) {
		User: UserUpdateSelf(data: $data) {
			...UserFragment
		}
	}
`;

export type UserUpdateSelfMutationRes = {
	User: User;
};

export type UserUpdateSelfMutationArgs = {
	data: UserUpdateSelf;
};

// User Delete Mutation
// -----------------------------------
export const UserDeleteMutation = gql`
	mutation UserDeleteMutation($uid: ID!) {
		UserDelete(uid: $uid)
	}
`;

export type UserDeleteMutationRes = {
	UserDelete: boolean;
};

export type UserDeleteMutationArgs = {
	uid: string;
};
