import BasePage from '@components/Layout/BasePage';
import { useError } from '@hooks/useError';
import { useSuccess } from '@hooks/useSuccess';
import {
	FormControl,
	FormLabel,
	Grid,
	Input,
	Box,
	Button,
	Tooltip,
	ButtonGroup,
} from '@mui/joy';
import { User } from '@schema/User/User';
import { UserQuery, UsersQuery } from '@schema/User/User.Operations';
import schema from '@schema/index';
import React, { FC, useEffect, useState } from 'react';
import PasswordForm from './components/PasswordForm';
import { MdOutlineLockOpen } from 'react-icons/md';
import CopyButton from '@components/Input/CopyButton';
import { useAuthentication } from '@providers/AuthenticationProvider';

const tokenTimeout = 30000;

const AccessForm: FC = () => {
	const onError = useError();
	const onSuccess = useSuccess();

	const { user } = useAuthentication();

	const [self, setSelf] = useState<User | null>(null);

	const [token, setToken] = useState<{
		value: string;
		timestamp: number;
	} | null>(null);

	useEffect(() => {
		const interval = setInterval(() => {
			if (token && Date.now() - token.timestamp > tokenTimeout) {
				setToken(null);
			}
		}, 3000);
		return () => clearInterval(interval);
	}, [token]);

	useEffect(() => {
		if (user && !token) {
			user.getIdToken().then((value) => {
				setToken({ value, timestamp: Date.now() });
			});
		}
	}, [user, token]);

	const [state, setState] = useState({
		name: '',
		email: '',
	});

	const { loading: userLoading } = schema.user.self({
		onError,
		onCompleted: (data) => {
			setSelf(data.User);
			setState({
				name: data.User.displayName || '',
				email: data.User.email || '',
			});
		},
	});

	const [handleUpdate, { loading: updateLoading }] = schema.user.updateSelf({
		variables: {
			data: {
				email: state.email,
				displayName: state.name,
			},
		},
		onError,
		onCompleted: (data) => {
			setSelf(data.User);
			setState({
				name: data.User.displayName || '',
				email: data.User.email || '',
			});
			onSuccess('Account updated');
		},
		refetchQueries: [UsersQuery, UserQuery],
	});

	const loading = userLoading || updateLoading;

	const getHoldup = () => {
		if (!state.name) return 'Please enter a name';
		else if (!state.email) return 'Please enter an email';
		else return null;
	};

	const isSame =
		self?.displayName === state.name && self?.email === state.email;

	const holdup = getHoldup();

	const onSubmit = () => {
		if (holdup) return;
		else handleUpdate();
	};

	return (
		<BasePage
			maxWidth={720}
			title="My Account"
			loading={loading}
			action={
				<ButtonGroup variant="soft">
					<CopyButton disabled={!token} value={token?.value ?? ''}>
						Copy API Token
					</CopyButton>
					<PasswordForm>
						{({ onClick }) => (
							<Button endDecorator={<MdOutlineLockOpen />} onClick={onClick}>
								Update Password
							</Button>
						)}
					</PasswordForm>
				</ButtonGroup>
			}
		>
			<Grid container spacing={4}>
				<Grid xs={12}>
					<FormControl>
						<FormLabel>Name</FormLabel>
						<Input
							autoFocus
							size="lg"
							placeholder="Name"
							value={state.name}
							onChange={(e) => setState({ ...state, name: e.target.value })}
						/>
					</FormControl>
				</Grid>

				<Grid xs={12}>
					<FormControl>
						<FormLabel>Email</FormLabel>
						<Input
							size="lg"
							placeholder="Email"
							value={state.email}
							onChange={(e) => setState({ ...state, email: e.target.value })}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12}>
					<Tooltip arrow title={holdup || ''}>
						<Box>
							<Button
								onClick={onSubmit}
								loading={loading}
								disabled={Boolean(holdup) || isSame}
								fullWidth
								color="success"
								size="lg"
							>
								Update Account
							</Button>
						</Box>
					</Tooltip>
				</Grid>
			</Grid>
		</BasePage>
	);
};

export default AccessForm;
