import React, { FC } from "react";
import { DashboardScope } from "../../useDashboardScope";
import { Box } from "@mui/joy";
import Section from "../../components/Seciton";
import { useParams } from "react-router-dom";
import { View } from "@scenes/Root/scenes/App/navigation/Nav";
import { EvaluationType } from "@schema/Evaluation/EvaluationType";
import Perceptions from "../../components/Perceptions";
import NetScores from "../../components/NetScores";
import Benchmarks from "../../components/Benchmarks";
import Comparison from "../../components/Comparison";
import Stats from "../../components/Stats";

export interface ScopedProps {
  scope: DashboardScope;
  setScope: (scope: DashboardScope) => void;
  type: string;
  setType: (type: string) => void;
  options: string[];
  evaluationType: EvaluationType;
  setEvaluationType: (evaluationType: EvaluationType) => void;
}

const Scoped: FC<ScopedProps> = ({
  scope,
  setScope,
  type,
  setType,
  options,
}) => {
  const { view } = useParams();

  const typeMap: Partial<Record<View, EvaluationType>> = {
    [View.InstructorReflection]: EvaluationType.InstructorReflection,
    [View.PeerReviews]: EvaluationType.PeerReview,
    [View.StudentFeedback]: EvaluationType.StudentCourseFeedback,
  };

  const evalType = typeMap[view as View];

  return (
    <Box>
      <Box p={1} />
      {evalType === EvaluationType.StudentCourseFeedback && (
        <Stats
          filter={{
            type: evalType,
            ancestry: scope.ancestry,
            attributes: scope.attributes,
            anchorMin: scope.anchors.start,
            anchorMax: scope.anchors.end,
          }}
        />
      )}
      <Box p={1} />
      <Section
        id="perceptions"
        minHeight={416}
        label={[
          view!,
          {
            [View.StudentFeedback]: "Student Perceptions",
            [View.InstructorReflection]: "Instructor Self Perceptions",
            [View.PeerReviews]: "Instructor Peer Perceptions",
          },
        ]}
      >
        <Perceptions
          filter={{
            type: evalType,
            ancestry: scope.ancestry,
            attributes: scope.attributes,
            anchorMin: scope.anchors.start,
            anchorMax: scope.anchors.end,
          }}
        />
      </Section>
      <Section
        id="netscores"
        label={[
          view!,
          {
            [View.StudentFeedback]: "Net Recommendation Scores",
            [View.InstructorReflection]: "Net Framework Scores",
            [View.PeerReviews]: "Net Framework Scores",
          },
        ]}
      >
        <NetScores
          filter={{
            type: evalType,
            ancestry: scope.ancestry,
            attributes: scope.attributes,
            anchorMin: scope.anchors.start,
            anchorMax: scope.anchors.end,
          }}
        />
      </Section>
      <Section
        id="benchmarks"
        label={[
          view!,
          {
            [View.StudentFeedback]: "Key Indicators",
            [View.InstructorReflection]: "Key Indicators",
            [View.PeerReviews]: "Key Indicators",
          },
        ]}
      >
        <Benchmarks
          filter={{
            type: evalType,
            ancestry: scope.ancestry,
            attributes: scope.attributes,
            anchorMin: scope.anchors.start,
            anchorMax: scope.anchors.end,
          }}
        />
      </Section>
      {evalType === EvaluationType.StudentCourseFeedback && (
        <Section id="comparison" label="Net Recommendation Comparison">
          <Comparison
            filter={{
              type: evalType,
              ancestry: scope.ancestry,
              attributes: scope.attributes,
              anchorMin: scope.anchors.start,
              anchorMax: scope.anchors.end,
            }}
            type={type}
            options={options}
            setType={setType}
            onClick={(u) => {
              setScope({
                ...scope,
                ancestry: [...scope.ancestry, u._id],
              });
            }}
          />
        </Section>
      )}
      <Box p={10} />
    </Box>
  );
};

export default Scoped;
