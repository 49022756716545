export const surveyQuestions: Record<string, string> = {
  whyenrollexplain: "Why did you enroll in this course?",
  chosetoapplyexplain: "I chose to apply myself to the course.",
  consideringmaterialsexplain:
    "Considering materials I was asked to purchase for this course:",
  understoodgradesexplain:
    "I understood how my grades were determined in this course.",
  recommendcourseexplain: "I would recommend this course.",
  recommendinstructorexplain: "I would recommend this instructor.",
  comfortableaskingquestionsexplain:
    "I felt comfortable asking questions and openly expressing and discussing my views in this course.",
  expectedtolearnexplain:
    "Was it clear what you were expected to learn in this class?",
  usedincourseexplain:
    "Which of these were used in the course and helped you learn?",
  instructorhelplearn: "What does this instructor do to help you learn?",
  instructorimprove:
    "What could this instructor do to improve or increase learning opportunities in this class?",
  anythingelseinstructortoknow:
    "Is there anything else you would like this instructor to know?",
};
