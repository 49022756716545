import { AspectRatio, Box, Button, Card, Typography, useTheme } from '@mui/joy';
import { Resource } from '@schema/Resource/Resource';
import React, { FC } from 'react';
import { IoDocumentAttachOutline } from 'react-icons/io5';
import { MdArrowForward } from 'react-icons/md';

export interface ResourceCardProps {
	resource: Resource;
	edit: () => void;
}

const ResourceCard: FC<ResourceCardProps> = ({ resource, edit }) => {
	const { palette } = useTheme();
	return (
		<Card variant="soft" sx={{ flex: 1 }}>
			<div>
				<Typography level="title-lg">{resource.name}</Typography>

				<Typography level="body-sm">
					{`${resource.description.split(' ').slice(0, 20).join(' ')}${
						resource.description.split(' ').length > 20 ? '...' : ''
					}`}
				</Typography>

				{/* <IconButton
					aria-label="bookmark Bahamas Islands"
					variant="plain"
					color="neutral"
					size="sm"
					sx={{ position: 'absolute', top: '0.875rem', right: '0.5rem' }}
				>
					<BookmarkAdd />
				</IconButton> */}
			</div>

			{resource.image ? (
				<img
					src={resource.image}
					loading="lazy"
					alt=""
					style={{
						maxHeight: '200px',
						background: palette.background.level1,
						padding: '24px',
					}}
				/>
			) : (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '200px',
						background: palette.background.level1,
					}}
				>
					<IoDocumentAttachOutline size="4rem" color={palette.text.icon} />
				</Box>
			)}

			<Box sx={{ flex: 1 }} />
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Button onClick={edit} variant="soft" size="md" color="neutral">
					Edit
				</Button>
				<Box sx={{ flex: 1 }} />
				<Button
					variant="soft"
					size="md"
					color="neutral"
					endDecorator={<MdArrowForward />}
					onClick={() => window.open(resource.url, '_blank')}
				>
					View Resource
				</Button>
			</Box>
		</Card>
	);
};

export default ResourceCard;
