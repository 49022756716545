import Logo from '@components/Display/Logo';
import { usePhoto } from '@hooks/usePhoto';
import { Box, Skeleton, Typography, useTheme } from '@mui/joy';
import { useAuthentication } from '@providers/AuthenticationProvider';
import { Message } from '@schema/Message/Message';
import React, { FC } from 'react';
import { MdOutlineAccountCircle } from 'react-icons/md';
import ReactMarkdown from 'react-markdown';

export interface MessageRenderProps {
	authenticated?: boolean;
	children: Message;
	sendFeedback: (value: -1 | null | 1) => void;
}

const MessageRender: FC<MessageRenderProps> = ({
	authenticated = true,
	children: message,
	sendFeedback,
}) => {
	const { user } = useAuthentication();
	const { palette, typography } = useTheme();
	const photo = usePhoto();

	if (message.role === 'system') return null;
	return (
		<Box
			sx={{
				display: 'flex',
				gap: 4,
				alignItems: 'stretch',
				paddingRight: 6,
			}}
		>
			<Box sx={{ display: 'flex', flexFlow: 'column', gap: 1 }}>
				<Box
					sx={{
						height: '48px',
						width: '48px',
						borderRadius: '8px',
						background: palette.background.level2,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					{message.role === 'user' ? (
						authenticated ? (
							<img
								src={photo}
								alt="User"
								style={{
									height: '100%',
									width: '100%',
									objectFit: 'cover',
									borderRadius: '8px',
								}}
							/>
						) : (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<MdOutlineAccountCircle style={{ fontSize: '1.5rem' }} />
							</Box>
						)
					) : (
						<Logo height={18} />
					)}
				</Box>
				<Box sx={{ flex: 1 }}>
					<Box
						sx={{
							height: '100%',
							width: '2px',
							borderRadius: '1px',
							background: palette.background.level2,
						}}
					/>
				</Box>
			</Box>
			<Box sx={{ paddingBottom: 4, width: '100%' }}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						width: '100%',
						alignItems: 'flex-start',
					}}
				>
					<Typography level="body-sm">
						{message.role === 'user'
							? authenticated
								? 'You'
								: 'User'
							: 'System'}
					</Typography>
				</Box>
				{message.content === 'Loading' && message.role === 'assistant' ? (
					<Typography>
						<Skeleton animation="wave">
							Loading loading loading loading
						</Skeleton>
					</Typography>
				) : (
					<Box sx={{ marginTop: -1 }}>
						<ReactMarkdown
							components={{
								// Customize how different elements are rendered here, if necessary
								p: ({ node, ...props }) => (
									<p
										{...props}
										style={
											{
												...typography['body-md'],
											} as any
										}
									/>
								),
								h1: ({ node, ...props }) => (
									<p
										{...props}
										style={
											{
												...typography['h1'],
											} as any
										}
									/>
								),
							}}
						>
							{message.content}
						</ReactMarkdown>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default MessageRender;
