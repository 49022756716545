import { gql } from "@apollo/client";
import { EvaluationFilter } from "@schema/Evaluation/EvaluationFilter";

export const CommentSummaryQuery = gql`
  query CommentSummary(
    $filter: EvaluationFilter!
    $question: String!
    $pageSize: Int!
    $pageNumber: Int!
  ) {
    CommentSummary(
      filter: $filter
      question: $question
      pageSize: $pageSize
      pageNumber: $pageNumber
    ) {
      question
      responses
    }
  }
`;

export interface CommentSummaryResponse {
  CommentSummary: {
    question: string;
    responses: string[];
  };
}

export interface CommentSummaryVariables {
  filter: EvaluationFilter;
  pageSize: number;
  pageNumber: number;
  question: string;
}
