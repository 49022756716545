import { gql } from '@apollo/client';
import { EvaluationProjectionCluster } from '@schema/EvaluationProjectionCluster/EvaluationProjectionCluster';

export class Insight {
	value!: string;
	cluster!: EvaluationProjectionCluster & { ids: string[] };
}

export const InsightFragment = gql`
	fragment InsightFragment on Insight {
		value
		cluster {
			...EvaluationProjectionClusterFragment
			ids
		}
	}
`;
