import { getQueryHook } from '@hooks/graphql';
import * as Ops from './Unit.Operations';

const unitSchema = {
	get: getQueryHook<Ops.UnitQueryResult, Ops.UnitQueryVars>(Ops.UnitQuery),
	getNullable: getQueryHook<
		Ops.UnitNullableQueryResult,
		Ops.UnitNullableQueryVars
	>(Ops.UnitNullableQuery),
	list: getQueryHook<Ops.UnitListQueryResult, Ops.UnitListQueryVars>(
		Ops.UnitListQuery
	),
	roots: getQueryHook<Ops.UnitRootsQueryResult, Ops.UnitRootsQueryVars>(
		Ops.UnitRootsQuery
	),
};

export default unitSchema;
