import { Claims } from './Claims';
import { Role } from './Role';

export const hasRole = (role: Role, claims: Claims | null) => {
	if (!claims) return false;
	else {
		switch (role) {
			case Role.User:
				return true;
			case Role.Expanded:
				return (
					claims.role === Role.Admin ||
					claims.role === Role.Owner ||
					claims.role === Role.Expanded
				);
			case Role.Owner:
				return claims.role === Role.Admin || claims.role === Role.Owner;
			case Role.Admin:
				return claims.role === Role.Admin;
		}
	}
};
