import { Button, ButtonProps, useTheme } from '@mui/joy';
import { useToastContext } from '@providers/ToastProvider';
import React, { FC } from 'react';
import { MdCheckCircle, MdCopyAll } from 'react-icons/md';

export interface CopyButtonProps extends ButtonProps {
	value: string;
}

const CopyButton: FC<CopyButtonProps> = ({ value, ...rest }) => {
	const { enqueue } = useToastContext();
	const { palette } = useTheme();

	const [copied, setCopied] = React.useState(false);

	React.useEffect(() => {
		if (copied) {
			const timeout = setTimeout(() => {
				setCopied(false);
			}, 2000);
			return () => clearTimeout(timeout);
		}
	}, [copied]);

	return (
		<Button
			{...rest}
			endDecorator={
				!copied ? (
					<MdCopyAll />
				) : (
					<MdCheckCircle style={{ color: palette.success[500] }} />
				)
			}
			onClick={
				rest.onClick ||
				((e) => {
					e.stopPropagation();
					navigator.clipboard.writeText(value);
					setCopied(true);
					enqueue({
						message: 'Copied to clipboard',
						variant: 'success',
					});
				})
			}
		>
			{copied ? 'Copied!' : rest.children || 'Copy'}
		</Button>
	);
};

export default CopyButton;
