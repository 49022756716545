import NoData from "@components/Display/NoData";
import { useError } from "@hooks/useError";
import { useLoading } from "@hooks/useLoading";
import { Box, Grid, Skeleton, Typography } from "@mui/joy";
import { EvaluationFilter } from "@schema/Evaluation/EvaluationFilter";
import { EvaluationStats } from "@schema/EvaluationStats/EvaluationStats";
import schema from "@schema/index";
import { commas } from "@utils/commas";
import React, { FC, useState } from "react";

export interface StatsProps {
  filter: EvaluationFilter;
}

const Stats: FC<StatsProps> = ({ filter }) => {
  const onError = useError();

  const [stats, setStats] = useState<EvaluationStats>({
    enrollment: 0,
    responses: 0,
  });

  const { error, loading: statsLoading } = schema.evaluationStats.get({
    variables: { filter },
    onCompleted: (data) => {
      setStats(data.EvaluationStats);
    },
    onError,
  });

  const loading = useLoading(statsLoading);

  if (error) return <NoData>{error.message}</NoData>;
  return (
    <Box
      sx={{
        paddingLeft: 2,
        paddingRight: 2,
      }}
    >
      <Grid container spacing={6}>
        <Grid xs={12} sm={4}>
          <Typography>
            <Skeleton loading={loading}>
              {commas(stats.enrollment ?? 0)}
            </Skeleton>
          </Typography>
          <Typography level="body-sm">Course Enrollments</Typography>
        </Grid>
        <Grid xs={12} sm={4}>
          <Typography>
            <Skeleton loading={loading}>
              {commas(stats.responses ?? 0)}
            </Skeleton>
          </Typography>
          <Typography level="body-sm">Comments</Typography>
        </Grid>
        {/* <Grid xs={12} sm={4}>
					<Typography>
						<Skeleton loading={loading}>
							{isNaN(stats.responses / stats.enrollment)
								? 'NA'
								: ((stats.responses / stats.enrollment) * 100).toFixed() + '%'}
						</Skeleton>
					</Typography>
					<Typography level="body-sm">Response Rate</Typography>
				</Grid> */}
      </Grid>
    </Box>
  );
};

export default Stats;
