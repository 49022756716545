import BasePage from '@components/Layout/BasePage';
import { Box } from '@mui/joy';
import schema from '@schema/index';
import { motion } from 'framer-motion';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import MessageRender from '../App/scenes/Dashboard/components/Chat/components/MessageRender';
import Problem from '../Problem';

const Chat: FC = () => {
	const { id } = useParams();

	const { data, error, loading } = schema.thread.get({
		variables: {
			id: id!,
		},
	});

	return (
		<BasePage title="Shared Chat" loading={loading} maxWidth={900}>
			<Box
				sx={{
					display: 'flex',
					flexFlow: 'column',
					gap: 2,
				}}
			>
				{error ? (
					<Problem error={new Error('Hmmm. This link appears to be broken.')} />
				) : (
					data?.Thread.messages.map((message) => (
						<motion.div
							key={`message-${message._id}`}
							initial={{ opacity: 0, y: -10, scale: 0.9 }}
							animate={{ opacity: 1, y: 0, scale: 1 }}
							transition={{
								duration: 0.25,
								delay: message.role === 'assistant' ? 0.2 : 0,
							}}
						>
							<MessageRender
								authenticated={false}
								sendFeedback={(v) => {
									//
								}}
							>
								{message}
							</MessageRender>
						</motion.div>
					))
				)}
			</Box>
		</BasePage>
	);
};

export default Chat;
