import React, { FC, useEffect } from 'react';
import Loading from '../Loading';
import { useApolloClient } from '@apollo/client';
import { signOut } from '@firebase/auth';
import { auth } from '@providers/AuthenticationProvider';

const Signout: FC = () => {
	const { clearStore } = useApolloClient();

	const signout = async () => {
		await signOut(auth);
		clearStore();
	};

	useEffect(() => {
		// signout, then delay for 100 ms, then navigate to the landing page
		signout().then(() => {
			setTimeout(() => {
				window.location.href = '/';
			}, 100);
		});
	}, []);

	return <Loading />;
};

export default Signout;
