import { Box, Card, CardContent, Typography } from "@mui/joy";
import { EvaluationFilter } from "@schema/Evaluation/EvaluationFilter";
import { FC, useState } from "react";
import Comments from "../Comments";
import { surveyQuestions } from "@utils/surveyQsMap";

export interface QuestionCardProps {
  filter: EvaluationFilter;
  response: {
    question: string;
    comments: string[];
    total: number;
  };
}

const QuestionCard: FC<QuestionCardProps> = ({ filter, response }) => {
  const [commentsCount, setCommentsCount] = useState<number>(
    response.comments.length || 0
  );

  const handleValueChange = (value: number) => {
    setCommentsCount(value);
  };

  const getQuestionText = (key: string) => {
    const cleanedKey = key.replace(/\d.*$/, ""); // Remove everything starting from the first number

    return surveyQuestions[cleanedKey.toLowerCase()]
      ? surveyQuestions[cleanedKey.toLowerCase()]
      : cleanedKey.replace(/([A-Z])/g, " $1").trim(); //Add a space before each uppercase letter
  };

  return (
    <Card variant="plain">
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
            }}
          >
            {getQuestionText(response.question)}
          </Typography>

          <Typography sx={{ fontWeight: "bold" }}>
            {commentsCount} / {response.total}
          </Typography>
        </Box>
        <Comments
          filter={filter}
          response={response}
          onValueChange={handleValueChange}
        />
      </CardContent>
    </Card>
  );
};

export default QuestionCard;
