import { EvaluationFilter } from '@schema/Evaluation/EvaluationFilter';
import { QuantitySummary } from '@schema/QuantitySummary/QuantitySummary';
import schema from '@schema/index';
import React, { FC, useRef, useState, useTransition } from 'react';
import { useSection } from '../Seciton';
import {
	Box,
	Card,
	Select,
	Typography,
	useTheme,
	Option,
	Dropdown,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	Tooltip,
} from '@mui/joy';
import Cartesian from '@components/Display/Cartesian';
import { insightScores } from '@schema/Evaluation/utils';
import { Unit } from '@schema/Unit/Unit';
import { Group } from '@visx/group';
import { useBreakpointTheme } from '../NetScores/components/ScoreBar';
import { useSvgExport } from '@utils/useSvgExport';
import { PiDownloadSimpleBold } from 'react-icons/pi';

const bar = 4;

export interface ComparisonProps {
	filter: EvaluationFilter;
	type: string;
	options: string[];
	setType: (type: string) => void;
	onClick: (unit: Unit) => void;
}

const Comparison: FC<ComparisonProps> = ({
	filter,
	type,
	options,
	setType,
	onClick,
}) => {
	const svgRef = useRef<SVGSVGElement>(null);
	const { palette } = useTheme();

	const { download } = useSvgExport(svgRef);

	const { points, loading } = usePoints(type, filter);

	useSection(loading);

	const maxR = Math.max(...points.map((p) => p.r));
	const minR = Math.min(...points.map((p) => p.r));

	const brTheme = useBreakpointTheme();

	return (
		<Box>
			<Box sx={{ position: 'relative' }}>
				<Cartesian
					ref={svgRef}
					gridLines={{
						x: [-100, 0, 50, 80, 100],
						y: [-100, 0, 50, 80, 100],
					}}
					data={points}
					x={(d) => d.x}
					y={(d) => d.y}
					r={(d) => d.r}
					id={(d) => d.id}
					bounds={{
						x: [-100.5, 100.5],
						y: [-100.5, 100.5],
						r: [minR, maxR],
					}}
					rRange={[2, 10]}
					tooltip={(d) => (
						<Card variant="soft" sx={{ gap: 0, padding: 0.75 }}>
							<Typography>{d.data.unit.name}</Typography>
							<Typography level="body-xs">
								Course: {d.x > 0 ? '+' : ''}
								{d.x.toFixed(1)}
							</Typography>
							<Typography level="body-xs">
								Instructor: {d.y > 0 ? '+' : ''}
								{d.y.toFixed(1)}
							</Typography>
						</Card>
					)}
					onClick={(d) => onClick(d.data.unit)}
				>
				{({ height, width }) => (
					<Group>
						<text
							x={32}
							y={height - 10}
							fill={palette.text.primary}
							fontSize="1rem"
							// rotate around the bottom left corner
							transform={`rotate(-90 18 ${height - 16})`}
						>
							Instructor →
						</text>
						<text
							x={32}
							y={height - 10}
							fill={palette.text.primary}
							fontSize="1rem"
						>
							Course →
						</text>
						<rect
							width={bar}
							fill={brTheme.great.background}
							height={height * 0.1}
						/>
						<rect
							width={bar}
							fill={brTheme.ok.background}
							height={height * 0.15}
							y={height * 0.1}
						/>
						<rect
							width={bar}
							fill={brTheme.bad.background}
							height={height * 0.25}
							y={height * 0.25}
						/>
						<rect
							width={bar}
							fill={brTheme.terrible.background}
							height={height * 0.5}
							y={height * 0.5}
						/>
						<rect
							height={bar}
							fill={brTheme.terrible.background}
							width={width * 0.5}
							y={height - bar}
						/>
						<rect
							height={bar}
							fill={brTheme.bad.background}
							width={width * 0.25}
							y={height - bar}
							x={width * 0.5}
						/>
						<rect
							height={bar}
							fill={brTheme.ok.background}
							width={width * 0.15}
							y={height - bar}
							x={width * 0.75}
						/>
						<rect
							height={bar}
							fill={brTheme.great.background}
							width={width * 0.1}
							y={height - bar}
							x={width * 0.9}
						/>
					</Group>
				)}

				</Cartesian>
				{/* </Cartesian> */}
				<Box
					sx={{
						position: 'absolute',
						bottom: 0,
						right: 0,
						p: 2,
						paddingBottom: 3,
					}}
				>
					<Dropdown>
						<Tooltip title="Download chart" arrow placement="top">
							<MenuButton
								slots={{ root: IconButton }}
								slotProps={{ root: { variant: 'soft', color: 'neutral' } }}
							>
								<PiDownloadSimpleBold />
							</MenuButton>
						</Tooltip>
						<Menu variant="soft" placement="bottom-end">
							<MenuItem onClick={() => download('svg')}>SVG</MenuItem>
							<MenuItem onClick={() => download('png')}>PNG</MenuItem>
						</Menu>
					</Dropdown>
				</Box>
			</Box>
			<Box p={1} />
			<Box sx={{ display: 'flex' }}>
				<Select
					value={type}
					onChange={(e, v) => {
						if (v) {
							setType(v);
						}
					}}
				>
					{options.map((option) => (
						<Option key={option} value={option}>
							{option}
						</Option>
					))}
				</Select>
			</Box>
		</Box>
	);
};

export default Comparison;

type Point<T> = {
	id: string;
	x: number;
	y: number;
	r: number;
	data: T;
};

const usePoints = (
	type: string,
	filter: EvaluationFilter
): { points: Point<QuantitySummary>[]; loading: boolean } => {
	const [pending, startTransition] = useTransition();
	const [points, setPoints] = useState<Point<QuantitySummary>[]>([]);

	const { loading: dataLoading } = schema.quantitySummary.get({
		variables: {
			unitFilter: {
				type,
				ancestry: filter.ancestry,
			},
			evaluationFilter: filter,
			keys: ['RecommendCourse20210101', 'RecommendInstructor20210101'],
		},
		onCompleted: (data) => {
			const res: Point<QuantitySummary>[] = [];

			for (const record of data.QuantitySummaries) {
				const course = record.responses.find((response) =>
					response.prompt.includes('RecommendCourse20210101')
				);

				const instructor = record.responses.find((response) =>
					response.prompt.includes('RecommendInstructor20210101')
				);

				if (course && instructor) {
					const courseTotal = course.responses.reduce(
						(acc, cur) => acc + cur.count,
						0
					);

					const instructorTotal = instructor.responses.reduce(
						(acc, cur) => acc + cur.count,
						0
					);

					const courseReduction = course.responses.reduce<{
						p: number;
						d: number;
					}>(
						(acc, cur) => {
							const score = insightScores[cur.value] ?? 0;
							if (score > 0) {
								return { p: acc.p + cur.count, d: acc.d };
							} else if (score < 0) {
								return { p: acc.p, d: acc.d + cur.count };
							} else {
								return acc;
							}
						},
						{ p: 0, d: 0 }
					);

					const instructorReduction = instructor.responses.reduce<{
						p: number;
						d: number;
					}>(
						(acc, cur) => {
							const score = insightScores[cur.value] ?? 0;
							if (score > 0) {
								return { p: acc.p + cur.count, d: acc.d };
							} else if (score < 0) {
								return { p: acc.p, d: acc.d + cur.count };
							} else {
								return acc;
							}
						},
						{ p: 0, d: 0 }
					);

					const x =
						(courseReduction.p / courseTotal -
							courseReduction.d / courseTotal) *
						100;

					const y =
						(instructorReduction.p / instructorTotal -
							instructorReduction.d / instructorTotal) *
						100;

					const r = courseTotal + instructorTotal;

					res.push({
						id: record.unit._id,
						x,
						y,
						r,
						data: record,
					});
				}
			}

			startTransition(() => {
				setPoints(res);
			});
		},
	});

	const loading = dataLoading || pending;

	return { points, loading };
};
