import {
	Box,
	Divider,
	Drawer,
	Skeleton,
	Stack,
	Typography,
	useTheme,
} from '@mui/joy';
import { Insight } from '@schema/Insight/Insight';
import schema from '@schema/index';
import React, { FC } from 'react';
import { BiSolidQuoteRight } from 'react-icons/bi';

export interface PerceptionDetailProps {
	insight: Insight | null;
	onClose: () => void;
}

const PerceptionDetail: FC<PerceptionDetailProps> = ({ insight, onClose }) => {
	const { palette } = useTheme();

	const { data, loading } = schema.evaluationSummary.extractQuotes({
		variables: {
			filter: {
				ids: insight?.cluster.ids ?? [],
			},
			sentiment: insight?.value ?? '',
		},
		skip: !insight,
	});

	return (
		<Drawer anchor="right" open={Boolean(insight)} onClose={onClose}>
			<Stack direction="column" sx={{ height: '100%' }}>
				<Box sx={{ p: 2, background: palette.background.level1 }}>
					<Typography
						color={(insight?.cluster.score ?? 0) > 50 ? 'success' : undefined}
						sx={{
							fontWeight: 'bold',
							fontStyle: 'italic',
						}}
					>
						Insight
					</Typography>
					<Box p={0.5} />
					<Typography level="h4">{insight?.value}</Typography>
				</Box>
				<Divider />
				<Box
					sx={{
						flex: 1,
						overflowY: 'auto',
						p: 2,
					}}
				>
					<Typography
						sx={{
							fontWeight: 'bold',
							fontStyle: 'italic',
						}}
					>
						Examples of this insight
					</Typography>
					{loading ? (
						<Box
							sx={{
								display: 'flex',
								flexFlow: 'column',
								gap: 4,
								paddingTop: 4,
							}}
						>
							{Array.from({ length: 5 }).map((_, i) => (
								<Typography key={`insightLoader_${i}`}>
									<Skeleton>
										<Typography>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit.
											Nulla nec dui nec nunc aliquam tincidunt nec nec nunc.
											Nulla nec dui nec nunc aliquam tincidunt nec nec nunc.
										</Typography>
									</Skeleton>
								</Typography>
							))}
						</Box>
					) : (
						<Box
							sx={{
								display: 'flex',
								flexFlow: 'column',
								gap: 4,
								paddingTop: 4,
							}}
						>
							{data?.ExtractQuotes.map((quote, i) => (
								<Box key={`quote_${i}`} sx={{ display: 'flex', gap: 2 }}>
									<Box>
										<BiSolidQuoteRight
											style={{
												fontSize: '1.5rem',
												color: palette.background.level2,
											}}
										/>
									</Box>
									<Typography>{quote}</Typography>
								</Box>
							))}
						</Box>
					)}
				</Box>
			</Stack>
		</Drawer>
	);
};

export default PerceptionDetail;
