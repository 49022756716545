import { EvaluationFilter } from "@schema/Evaluation/EvaluationFilter";
import { useState } from "react";
import { Box, DialogContent, Drawer, DialogTitle, ModalClose } from "@mui/joy";
import Questions from "./components/Questions";

interface RawCommentsProp {
  children: (provided: { onClick: () => void }) => React.ReactNode;
  filter: EvaluationFilter;
}

const RawComments: React.FC<RawCommentsProp> = ({
  children,
  filter,
}: RawCommentsProp) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  return (
    <div>
      {children({ onClick: openPopup })}
      <Box sx={{ display: "flex" }}>
        <Drawer
          open={showPopup}
          anchor="right"
          onClose={() => setShowPopup(false)}
          size="lg"
          variant="plain"
        >
          <ModalClose />
          <DialogTitle
            sx={{
              borderBottom: "1px solid",
              borderColor: "divider",
              paddingBottom: 2,
            }}
          >
            Students Comments
          </DialogTitle>
          <DialogContent>
            {showPopup && <Questions filter={filter} />}
          </DialogContent>
        </Drawer>
      </Box>
    </div>
  );
};

export default RawComments;
