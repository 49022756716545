import Pop from '@components/Animation/Pop';
import Loader from '@components/Display/Loader';
import DebounceInput from '@components/Input/DebounceInput';
import { Box, Divider, Drawer, ListItem, List, ListItemButton } from '@mui/joy';
import { Unit } from '@schema/Unit/Unit';
import schema from '@schema/index';
import React, { FC, useState } from 'react';
import { MdSearch } from 'react-icons/md';

export interface ChipDrawerProps {
	ancestry: string[];
	type: string;
	children: (provided: { onClick: () => void }) => React.ReactNode;
	onClick: (unit: Unit) => void;
}

const ChipDrawer: FC<ChipDrawerProps> = ({
	ancestry,
	type,
	children,
	onClick,
}) => {
	const [open, setOpen] = useState(false);
	const [name, setName] = useState('');

	const { data, loading } = schema.unit.list({
		variables: {
			filter: {
				name,
				type,
				ancestry,
			},
		},
	});

	const units = data?.List.records ?? [];

	const onClose = () => {
		setOpen(false);
		setName('');
	};

	return (
		<>
			{children({ onClick: () => setOpen(!open) })}
			<Drawer anchor="right" open={open} onClose={onClose}>
				<Box sx={{ display: 'flex', flexFlow: 'column' }}>
					<Box sx={{ p: 2 }}>
						<DebounceInput
							startDecorator={<MdSearch />}
							placeholder={`Search ${type}s`}
							size="lg"
							value={name}
							onChange={(v) => setName(v)}
						/>
					</Box>
					<Divider />
					<Box sx={{ flex: 1, overflowY: 'auto', position: 'relative' }}>
						<Loader loading={loading} />
						<List size="lg">
							{units.map((unit) => (
								<Pop key={`dr_${unit._id}`} scale={1.02}>
									<ListItem>
										<ListItemButton onClick={() => onClick(unit)}>
											{unit.name}
										</ListItemButton>
									</ListItem>
								</Pop>
							))}
						</List>
					</Box>
				</Box>
			</Drawer>
		</>
	);
};

export default ChipDrawer;
