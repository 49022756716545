import { Box, Button, Typography, useColorScheme, useTheme } from '@mui/joy';
import React, { FC } from 'react';
import { MdArrowForward } from 'react-icons/md';
import Pop from '@components/Animation/Pop';
import Device from '@components/Display/Device';
import { LandingPhotoType, landingPhoto } from '../../screenshots';
import CenterFlex from '@components/Layout/CenterFlex';

const textWidth = 500;

const GetStarted: FC<{ children?: string }> = ({ children }) => {
	return (
		<Box sx={{ display: 'flex' }}>
			<Pop>
				<Button size="lg" color="success" endDecorator={<MdArrowForward />}>
					{children || 'Get Started'}
				</Button>
			</Pop>
		</Box>
	);
};

const How: FC = () => {
	const theme = useTheme();

	const { colorScheme } = useColorScheme();

	const photoType: LandingPhotoType = colorScheme === 'dark' ? 'Dark' : 'Light';

	return (
		<Box>
			<Box
				sx={{
					'& h2': {
						whiteSpace: 'pre-line',
					},
					'& p': {
						maxWidth: textWidth,
					},
				}}
			>
				<CenterFlex>
					<Box p={4} />
					<Typography level="h1">
						Translating vast amounts of qualitative data into actionable
						insights.
					</Typography>
				</CenterFlex>
				<Box p={12} />
			</Box>
		</Box>
	);
};

export default How;
