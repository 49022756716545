import { Role } from '@auth/Role';
import Table from '@components/Display/Table';
import CopyButton from '@components/Input/CopyButton';
import BasePage from '@components/Layout/BasePage';
import { useError } from '@hooks/useError';
import { Box, Button, Input, Typography } from '@mui/joy';
import { User } from '@schema/User/User';
import schema from '@schema/index';
import React, { FC } from 'react';
import { MdAdd, MdSearch } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const Search: FC<{
	label: string;
	value: string;
	onChange: (value: string) => void;
}> = ({ label, value, onChange }) => {
	return (
		<Input
			startDecorator={<MdSearch />}
			variant="plain"
			size="sm"
			placeholder={label}
			value={value}
			onChange={(e) => onChange(e.target.value)}
		/>
	);
};

const AccessList: FC = () => {
	const nav = useNavigate();
	const onError = useError();

	const { data, loading } = schema.user.list({
		onError,
	});

	const users = data ? data.Users : [];

	const [filter, setFilter] = React.useState({
		name: '',
		email: '',
		role: '',
		uid: '',
		unit: '',
	});

	const filterUser = (user: User): boolean => {
		const name = user.displayName?.toLowerCase() ?? '';
		const email = user.email?.toLowerCase() ?? '';
		const role = user.claims.role?.toLowerCase() ?? '';
		const uid = user.uid?.toLowerCase() ?? '';
		const unit = user.assignments
			.map((a) => a.name)
			.join(' ')
			.toLowerCase();

		const nameMatch = name.includes(filter.name.toLowerCase());
		const emailMatch = email.includes(filter.email.toLowerCase());
		const roleMatch = role.includes(filter.role.toLowerCase());
		const uidMatch = uid.includes(filter.uid.toLowerCase());
		const unitMatch = unit.includes(filter.unit.toLowerCase());

		if (filter.name && !nameMatch) return false;
		if (filter.email && !emailMatch) return false;
		if (filter.role && !roleMatch) return false;
		if (filter.uid && !uidMatch) return false;
		if (filter.unit && !unitMatch) return false;

		return true;
	};

	const filtered = users.filter(filterUser);

	return (
		<BasePage
			title="Pilot Access"
			loading={loading}
			action={
				<Button
					onClick={() => nav('new')}
					color="success"
					endDecorator={<MdAdd />}
				>
					New Account
				</Button>
			}
		>
			<Table
				data={filtered}
				id={(u) => u.uid}
				onClick={(e, u) => nav(u.uid)}
				clickDisabled={(u) => u.claims.role === Role.Admin}
				headers={{
					Name: (
						<Search
							label="Name"
							value={filter.name}
							onChange={(value) => setFilter({ ...filter, name: value })}
						/>
					),
					Email: (
						<Search
							label="Email"
							value={filter.email}
							onChange={(value) => setFilter({ ...filter, email: value })}
						/>
					),
					Role: (
						<Search
							label="Role"
							value={filter.role}
							onChange={(value) => setFilter({ ...filter, role: value })}
						/>
					),
					UID: (
						<Search
							label="UID"
							value={filter.uid}
							onChange={(value) => setFilter({ ...filter, uid: value })}
						/>
					),
					Units: (
						<Search
							label="Unit"
							value={filter.unit}
							onChange={(value) => setFilter({ ...filter, unit: value })}
						/>
					),
				}}
			>
				{{
					Name: (u) => u.displayName,
					Email: (u) =>
						u.email ? (
							<CopyButton size="sm" variant="plain" value={u.email}>
								{u.email}
							</CopyButton>
						) : null,
					Role: (u) => u.claims.role,
					UID: (u) => (
						<CopyButton size="sm" variant="plain" value={u.uid}>
							{u.uid}
						</CopyButton>
					),
					Units: (u) => (
						<Box sx={{ overflow: 'hidden' }}>
							{u.assignments.map((unit) => (
								<Typography
									key={unit._id}
									sx={{
										overflow: 'ellipsis',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
									}}
								>
									{unit.name}
								</Typography>
							))}
						</Box>
					),
				}}
			</Table>
		</BasePage>
	);
};

export default AccessList;
