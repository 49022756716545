import BasePage from '@components/Layout/BasePage';
import { ResourceFilter } from '@schema/Resource/Resource.Filter';
import { Sort } from '@schema/Sort/Sort';
import React, { FC, useState } from 'react';
import ResourceForm from './components/ResourceForm';
import { Box, Button, Grid } from '@mui/joy';
import { MdAdd, MdSearch } from 'react-icons/md';
import schema from '@schema/index';
import Problem from '@scenes/Root/scenes/Problem';
import { List } from '@schema/List/List';
import { Resource } from '@schema/Resource/Resource';
import ResourceCard from './components/ResourceCard';
import DebounceInput from '@components/Input/DebounceInput';

const Resources: FC = () => {
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(100);
	const [filter, setFilter] = useState<ResourceFilter>({});
	const [sort, setSort] = useState<Sort>({
		key: 'name',
		direction: -1,
	});

	const { data, error, loading } = schema.resource.list({
		variables: {
			skip,
			take,
			filter,
			sort: [sort],
		},
	});

	const list: List<Resource> = data?.ResourceList ?? {
		hasMore: false,
		total: 0,
		records: [],
	};

	return (
		<ResourceForm>
			{({ onClick }) => (
				<BasePage
					loading={loading}
					title="Resources"
					action={
						<Button
							onClick={() => onClick()}
							color="success"
							endDecorator={<MdAdd />}
						>
							Add Resource
						</Button>
					}
				>
					{error ? (
						<Problem error={error} />
					) : (
						<Box>
							<DebounceInput
								startDecorator={<MdSearch />}
								placeholder="Search Resources..."
								size="lg"
								value={filter.phrase ?? ''}
								onChange={(val) => {
									setFilter({ ...filter, phrase: val });
								}}
							/>
							<Box p={2} />
							<Grid container spacing={4} alignItems="stretch">
								{list.records.map((resource) => (
									<Grid
										key={resource._id}
										xs={12}
										sm={6}
										md={4}
										lg={4}
										sx={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<ResourceCard
											resource={resource}
											edit={() => onClick(resource)}
										/>
									</Grid>
								))}
							</Grid>
						</Box>
					)}
				</BasePage>
			)}
		</ResourceForm>
	);
};

export default Resources;
