import React, { FC, useRef, useState, useTransition } from 'react';
import { DashboardScope } from '../../useDashboardScope';
import schema from '@schema/index';
import { EvaluationType } from '@schema/Evaluation/EvaluationType';
import { useError } from '@hooks/useError';
import { EvaluationProjectionClusterResponse } from '@schema/EvaluationProjectionCluster/EvaluationProjectionClusterResponse';
import {
	Box,
	Button,
	Dropdown,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	ToggleButtonGroup,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/joy';
import Cartesian from '@components/Display/Cartesian';
import Loader from '@components/Display/Loader';
import DebounceSlider from '@components/Input/DebounceSlider';
import { pointSlope } from '@utils/pointSlope';
import TopicTooltip from './components/TopicTooltip';
import { EvaluationFilter } from '@schema/Evaluation/EvaluationFilter';
import { PiDownloadSimpleBold } from 'react-icons/pi';
import { EvaluationProjectionCluster } from '@schema/EvaluationProjectionCluster/EvaluationProjectionCluster';
import TopicDetail from './components/TopicDetail';
import { useSvgExport } from '@utils/useSvgExport';

export interface TopicModelerProps {
	scope: DashboardScope;
	setScope: (scope: DashboardScope) => void;
	evaluationType: EvaluationType;
	setEvaluationType: (evaluationType: EvaluationType) => void;
}

const TopicModeler: FC<TopicModelerProps> = ({
	scope,
	setScope,
	evaluationType,
	setEvaluationType,
}) => {
	const onError = useError();
	const [pending, startTransition] = useTransition();
	const { palette } = useTheme();

	const chartRef = useRef<SVGSVGElement>(null);
	const { download, loading: downloadLoading } = useSvgExport(chartRef);

	const [granularityInput, setGranularityInput] = useState(200);

	const granularity = granularityInput / 10;

	const [focused, setFocused] = useState<EvaluationProjectionCluster | null>(
		null
	);

	const [{ clusters, min, max, minCount, maxCount }, setResponse] =
		useState<EvaluationProjectionClusterResponse>({
			clusters: [],
			min: { x: 0, y: 0 },
			max: { x: 0, y: 0 },
			minCount: 0,
			maxCount: 0,
		});

	const filter: EvaluationFilter = {
		attributes: scope.attributes,
		ancestry: scope.ancestry,
		type: evaluationType,
		anchorMin: scope.anchors.start,
		anchorMax: scope.anchors.end,
	};

	const { loading } = schema.evaluationProjectionCluster.get({
		variables: {
			filter,
			granularity,
			mode: 'blended',
			threshold: 0.05,
		},
		onError,
		onCompleted: (data) => {
			startTransition(() => {
				setResponse(data.EvaluationProjectionClusters);
			});
		},
	});

	const rBaseInput = pointSlope(
		{ x: 20, y: 8 },
		{ x: 1500, y: 3.5 },
		clusters.length
	);
	const rBase = Math.max(1.5, rBaseInput);

	return (
		<Box sx={{ position: 'relative', p: 2 }}>
			<Loader loading={loading} />
			<Box p={1} />
			<Typography sx={{ maxWidth: '500px' }}>
				The application is powered by a spatial sentiment map of qualitative
				data. The clusters within the map represent key topics and themes that
				emerge naturally from the data. The size of each cluster reflects the
				number of responses within a given topic.
			</Typography>
			<Box p={2} />
			<Box sx={{ border: `1px solid ${palette.divider}`, borderRadius: '8px' }}>
				<Box p={6} sx={{ position: 'relative' }}>
					<Cartesian
						ref={chartRef}
						size={[3, 2]}
						data={clusters}
						x={(c) => c.coordinate.x}
						y={(c) => c.coordinate.y}
						r={(c) => c.count}
						id={(c) => c.id}
						bounds={{
							x: [min.x, max.x],
							y: [min.y, max.y],
							r: [minCount, maxCount],
						}}
						rRange={[rBase, rBase * 2]}
						fill={(c) => {
							if (c.score < 0) return palette.danger[500];
							else if (c.score < 50) return palette.warning[400];
							else if (c.score < 80) return palette.success[400];
							else return palette.success[500];
						}}
						tooltip={(c) => (
							<TopicTooltip
								granularity={granularity}
								mode="blended"
								filter={filter}
							>
								{c}
							</TopicTooltip>
						)}
						onClick={setFocused}
					/>
					<Box
						sx={{
							position: 'absolute',
							bottom: 0,
							right: 0,
							p: 2,
						}}
					>
						<Dropdown>
							<Tooltip title="Download chart" arrow placement="top">
								<MenuButton
									slots={{ root: IconButton }}
									slotProps={{ root: { variant: 'soft', color: 'neutral' } }}
								>
									<PiDownloadSimpleBold />
								</MenuButton>
							</Tooltip>
							<Menu variant="soft" placement="bottom-end">
								<MenuItem onClick={() => download('svg')}>SVG</MenuItem>
								<MenuItem onClick={() => download('png')}>PNG</MenuItem>
							</Menu>
						</Dropdown>
					</Box>
				</Box>
			</Box>
			<Box p={2} />
			<Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 4 }}>
				<ToggleButtonGroup
					value={evaluationType}
					orientation="vertical"
					variant="soft"
					onChange={(e, v) => {
						if (v) setEvaluationType(v);
					}}
				>
					<Button value={EvaluationType.StudentCourseFeedback}>
						Student Course Feedback
					</Button>
					<Button value={EvaluationType.PeerReview}>Peer Reviews</Button>
					<Button value={EvaluationType.InstructorReflection}>
						Instructor Reflection
					</Button>
				</ToggleButtonGroup>
				<Box sx={{ width: '240px' }}>
					<DebounceSlider
						valueLabelDisplay="auto"
						marks
						sx={{
							paddingBottom: 0,
						}}
						min={20}
						max={200}
						step={10}
						value={granularityInput}
						onChange={(v) => {
							if (typeof v === 'number') setGranularityInput(v);
						}}
					/>
					<Typography>Sentiment Granularity</Typography>
				</Box>
			</Box>
			<Box p={10} />
			<TopicDetail
				cluster={focused}
				onClose={() => setFocused(null)}
				granularity={granularity}
				filter={filter}
				mode="blended"
			/>
		</Box>
	);
};

export default TopicModeler;
