export const frameworkReflection = `1. Foster Development
Do I foster student development in discipline-specific language and approaches?
Do I model and develop mindful, ethical, inclusive, and responsible behavior in instructional environments?
Do I recognize power differentials between myself, other instructors, graduate students, and students?
Do I help students to assess their learning and adjust their learning strategies accordingly?
Do I encourage the development of habits of professional responsibility among my students?
2. Promote Deep Engagement
Do I create learning objectives and experiences that are challenging yet attainable for my students?
Is the content I use rigorous and informed by theory, research, evidence, and context?
Do I provide materials, cases, or applications that include diverse experiences, perspectives, or populations?
Do I create an environment conducive to intellectual risk-taking?
Do I utilize relevant strategies and tools to give students access to course materials, grades, and other feedback?
3. Incorporate Promising Teaching Practices
Do I apply multiple techniques and strategies to reach all students in an inclusive, accessible, and culturally responsive way?
Do I manage teaching and learning effectively, including planning activities and managing time and student participation?
Do I use active learning strategies to promote the development of content mastery?
Do I foster the translation of learning and problem-solving skills to different and changing contexts?
Do I follow university policies and procedures regarding instructional practices and maintain course policies that are applied uniformly and fairly?
4. Utilize Assessment Practices
Do I use assessments at timely intervals throughout the course?
Do I provide specific, regular, and timely feedback tied to performance criteria?
Are my assessment processes transparent, with clear standards tied to learning objectives?
Do I demonstrate the effectiveness of my instruction through measures of student mastery of learning objectives?
5. Pursue Ongoing Instructional Improvement
Do I utilize feedback from a variety of sources to inform my teaching practices?
Do I reflect on my practices and experiences and integrate new knowledge effectively?
Do I seek out pedagogical approaches to improve my teaching practices?`;

export const frameworkReview = `1. Foster Development
Does the instructor foster student development in discipline-specific language and approaches?
Does the instructor model and develop mindful, ethical, inclusive, and responsible behavior in instructional environments?
Does the instructor recognize power differentials between professors, instructors, graduate students, and students?
Does the instructor foster students’ ability to assess their learning and adjust their learning strategies accordingly?
Does the instructor develop habits of professional responsibility among students?
2. Promote Deep Engagement
Does the instructor create learning objectives and experiences that are challenging yet attainable?
Is the content used by the instructor rigorous and informed by theory, research, evidence, and context?
Does the instructor provide materials, cases, or applications that include diverse experiences, perspectives, or populations?
Does the instructor create an environment conducive to intellectual risk-taking?
Does the instructor utilize relevant strategies and tools to provide students access to course materials, grades, and other feedback?
3. Incorporate Promising Teaching Practices
Does the instructor apply multiple techniques and strategies to reach all students in an inclusive, accessible, and culturally responsive way?
Does the instructor manage teaching and learning effectively, including planning activities and managing time and student participation?
Does the instructor use active learning strategies to promote the development of content mastery?
Does the instructor foster the translation of learning and problem-solving skills to different and changing contexts?
Does the instructor follow university policies and procedures regarding instructional practices and maintain course policies that are applied uniformly and fairly?
4. Utilize Assessment Practices
Does the instructor use assessments at timely intervals throughout the course?
Does the instructor provide specific, regular, and timely feedback tied to performance criteria?
Are the assessment processes used by the instructor transparent, with clear standards tied to learning objectives?
Does the instructor demonstrate the effectiveness of instruction through measures of student mastery of learning objectives?
5. Pursue Ongoing Instructional Improvement
Does the instructor utilize feedback from a variety of sources to inform teaching practices?
Does the instructor reflect on practices and experiences and integrate new knowledge effectively?
Does the instructor seek out pedagogical approaches to improve teaching practices?`;

export const frameworkJsonStructure = `{
  "FosterDevelopment_DisciplineSpecificLanguage": "string",
  "FosterDevelopment_MindfulEthicalInclusive": "string",
  "FosterDevelopment_RecognizePowerDifferentials": "string",
  "FosterDevelopment_AssessLearningStrategies": "string",
  "FosterDevelopment_ProfessionalResponsibility": "string",
  "FosterDevelopment_Explain": "string",
  "PromoteDeepEngagement_ChallengingObjectives": "string",
  "PromoteDeepEngagement_RigorousContent": "string",
  "PromoteDeepEngagement_DiverseMaterials": "string",
  "PromoteDeepEngagement_IntellectualRiskTaking": "string",
  "PromoteDeepEngagement_AccessToMaterials": "string",
  "PromoteDeepEngagement_Explain": "string",
  "IncorporateTeachingPractices_InclusiveStrategies": "string",
  "IncorporateTeachingPractices_ManageTeachingLearning": "string",
  "IncorporateTeachingPractices_ActiveLearningStrategies": "string",
  "IncorporateTeachingPractices_TranslateSkills": "string",
  "IncorporateTeachingPractices_FollowPolicies": "string",
  "IncorporateTeachingPractices_Explain": "string",
  "UtilizeAssessmentPractices_TimelyAssessments": "string",
  "UtilizeAssessmentPractices_TimelyFeedback": "string",
  "UtilizeAssessmentPractices_TransparentProcesses": "string",
  "UtilizeAssessmentPractices_DemonstrateEffectiveness": "string",
  "UtilizeAssessmentPractices_Explain": "string",
  "PursueInstructionalImprovement_FeedbackUtilization": "string",
  "PursueInstructionalImprovement_ReflectIntegrateKnowledge": "string",
  "PursueInstructionalImprovement_SeekPedagogicalImprovements": "string"
  "PursueInstructionalImprovement_Explain": "string"
}`;

export const frameworkKeys: Record<string, string[]> = {
	FosterDevelopment: [
		'DisciplineSpecificLanguage',
		'MindfulEthicalInclusive',
		'RecognizePowerDifferentials',
		'AssessLearningStrategies',
		'ProfessionalResponsibility',
	],
	PromoteDeepEngagement: [
		'ChallengingObjectives',
		'RigorousContent',
		'DiverseMaterials',
		'IntellectualRiskTaking',
		'AccessToMaterials',
	],
	IncorporateTeachingPractices: [
		'InclusiveStrategies',
		'ManageTeachingLearning',
		'ActiveLearningStrategies',
		'TranslateSkills',
		'FollowPolicies',
	],
	UtilizeAssessmentPractices: [
		'TimelyAssessments',
		'TimelyFeedback',
		'TransparentProcesses',
		'DemonstrateEffectiveness',
	],
	PursueInstructionalImprovement: [
		'FeedbackUtilization',
		'ReflectIntegrateKnowledge',
		'SeekPedagogicalImprovements',
	],
};
