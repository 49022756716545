import { useError } from '@hooks/useError';
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Drawer,
	Sheet,
	Stack,
	Textarea,
	Typography,
	useTheme,
} from '@mui/joy';
import { EvaluationFilter } from '@schema/Evaluation/EvaluationFilter';
import { Thread } from '@schema/Thread/Thread';
import schema from '@schema/index';
import React, { FC, useEffect, useRef, useState } from 'react';
import { MdOutlineSend } from 'react-icons/md';
import MessageRender from './components/MessageRender';
import { motion } from 'framer-motion';
import { ThreadChatQuery } from '@schema/Thread/Thread.Operations';
import { useLoading } from '@hooks/useLoading';
import Suggestions, { usePrompts } from './components/Suggestions';
import ExportButton from './components/ExportButton';
import Prompts from './components/Prompts';

export interface ChatProps {
	children: (provided: {
		onClick: (prompt?: string) => void;
		error?: Error | null;
	}) => React.ReactNode;
	filter: EvaluationFilter;
}

const Chat: FC<ChatProps> = ({ children, filter }) => {
	const onError = useError();

	const { palette } = useTheme();

	const [open, setOpen] = useState(false);

	const [showPrompts, setShowPrompts] = useState(false);

	const ref = useRef<HTMLDivElement>(null);
	const scrollRef = useRef<HTMLDivElement>(null);

	const [thread, setThread] = useState<Thread | null>(null);

	const [entry, setEntry] = useState('');

	const [handleChat, { loading: chatLoading }] = schema.thread.chat({
		onCompleted: (data) => {
			setThread(data.ThreadChat);
		},
		onError,
		fetchPolicy: 'network-only',
	});

	const [handleInitiate, { loading: initiateLoading, error }] =
		schema.thread.initiate({
			variables: {
				filter,
			},
			onCompleted: (data) => {
				setThread(data.ThreadInitiate);
			},
			onError,
			fetchPolicy: 'network-only',
		});

	const handleFocus = () => {
		// the ref is to a div, inside that div is a textarea
		// the ref is not to the textarea itself
		// so we need to find the textarea

		if (ref.current) {
			const textarea = ref.current.querySelector('textarea');
			if (textarea) {
				setTimeout(() => {
					textarea.focus();
				}, 250);
			}
		}
	};

	const handleScroll = () => {
		// scroll to the beginning of the last message, smoothly
		// the last message is a div inside the scrollRef
		if (scrollRef.current) {
			const lastMessage = scrollRef.current.lastElementChild;
			if (lastMessage) {
				lastMessage.scrollIntoView({ behavior: 'smooth' });
			}
		}
	};

	useEffect(() => {
		handleScroll();
	}, [thread]);

	const loading = chatLoading || initiateLoading;

	const defLoading = useLoading(loading, 1500);

	const onOpen = (message?: string) => {
		setOpen(true);
		handleInitiate({
			variables: {
				filter,
			},
			onCompleted: ({ ThreadInitiate }) => {
				setThread(ThreadInitiate);
				if (message) {
					setThread({
						...ThreadInitiate,
						messages: [
							...ThreadInitiate.messages,
							{
								_id: 'newUserMessage',
								role: 'user',
								content: message,
								time: Date.now(),
							},
							{
								_id: 'newAssistantMessage',
								role: 'assistant',
								content: 'Loading',
								time: Date.now(),
							},
						],
					});
					handleChat({
						variables: {
							filter,
							message: {
								role: 'user',
								content: message,
								time: Date.now(),
								thread: ThreadInitiate._id,
							},
							id: ThreadInitiate._id,
						},
					});
				}
			},
		});
		handleFocus();
	};

	const onClose = () => {
		setOpen(false);
		setEntry('');
		setThread(null);
	};

	const onSend = (override?: string) => {
		if (loading || !thread) return;
		else if (override || entry) {
			setThread({
				...thread,
				messages: [
					...thread.messages,
					{
						_id: 'newUserMessage',
						role: 'user',
						content: override || entry,
						time: Date.now(),
					},
					{
						_id: 'newAssistantMessage',
						role: 'assistant',
						content: 'Loading',
						time: Date.now(),
					},
				],
			});
			handleChat({
				variables: {
					filter,
					message: {
						role: 'user',
						content: override || entry,
						time: Date.now(),
						thread: thread._id,
					},
					id: thread._id,
				},
			});
			setEntry('');
		}
	};

	const [handleFeedback] = schema.thread.feedback({});

	const sendFeedback = (id: string, value: -1 | 1 | null) => {
		handleFeedback({
			onError,
			variables: {
				id,
				feedback: value,
			},
			refetchQueries: [ThreadChatQuery],
			onCompleted: (data) => {
				if (thread) {
					const messages = [...thread.messages];
					const index = messages.findIndex((m) => m._id === id);
					messages[index] = {
						...messages[index],
						feedback: value,
					};

					setThread({
						...thread,
						messages,
					});
				}
			},
		});
	};

	const ancestry = filter.ancestry ?? [];

	const { data } = schema.unit.list({
		variables: {
			filter: {
				ids: ancestry,
			},
		},
	});

	if (ancestry.length === 0) {
		throw new Error('Ancestry is empty');
	}

	const units = data
		? ancestry.map((id) => data.List.records.find((u) => u._id === id)!)
		: [];

	const HandlePromptButtonClick = () => {
		setShowPrompts(!showPrompts);
	};

	const prompts = usePrompts(units);

	return (
		<>
			{children({
				onClick: onOpen,
				error,
			})}
			<Drawer
				size="lg"
				variant="plain"
				slotProps={{
					content: {
						sx: {
							bgcolor: 'transparent',
							p: { md: 3, sm: 0 },
							boxShadow: 'none',
						},
					},
				}}
				anchor="right"
				open={open}
				onClose={onClose}
			>
				<Sheet
					sx={{
						borderRadius: 'xl',
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
						overflow: 'hidden',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							p: 2,
							paddingLeft: 3,
							justifyContent: 'space-between',
						}}
					>
						<Typography level="h4">Ask Questions</Typography>
						{thread &&
							thread.messages[1] &&
							thread.messages[1].content !== 'Loading' && (
								<Stack direction={'row'}>
									<Button variant="plain" onClick={HandlePromptButtonClick}>
										{!showPrompts ? 'Show Prompts' : 'Hide Prompts'}
									</Button>
									<ExportButton thread={thread?._id ?? null} />
								</Stack>
							)}
					</Box>
					<Divider />
					{showPrompts && (
						<Prompts
							prompts={prompts ?? []}
							onClick={(prompt) => onSend(prompt)}
							loading={loading}
						/>
					)}
					<Box sx={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
						{!thread && defLoading ? (
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									justifyContent: 'center',
									height: '100%',
									gap: 2,
								}}
							>
								<CircularProgress />
								<Typography>
									Hang tight, we're getting everything set up.
								</Typography>
							</Box>
						) : thread?.messages.length === 0 ? (
							<Suggestions
								onClick={(m) => {
									onSend(m);
								}}
								prompts={prompts ?? []}
							/>
						) : (
							<Box
								ref={scrollRef}
								sx={{
									display: 'flex',
									flexFlow: 'column',
									gap: 2,
									p: 2,
								}}
							>
								{thread?.messages.map((message) => (
									<motion.div
										key={`message-${message._id}`}
										initial={{ opacity: 0, y: -10, scale: 0.9 }}
										animate={{ opacity: 1, y: 0, scale: 1 }}
										transition={{
											duration: 0.25,
											delay: message.role === 'assistant' ? 0.2 : 0,
										}}
									>
										<MessageRender
											sendFeedback={(v) => sendFeedback(message._id, v)}
										>
											{message}
										</MessageRender>
									</motion.div>
								))}
							</Box>
						)}
					</Box>
					<Divider />
					<Box
						sx={{
							p: 2,
							display: 'flex',
							alignItems: 'flex-start',
							gap: 2,
						}}
					>
						<Textarea
							ref={ref}
							placeholder="Type your question here..."
							sx={{ flex: 1 }}
							maxRows={5}
							size="lg"
							color="primary"
							variant="soft"
							value={entry}
							onChange={(e) => setEntry(e.target.value)}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									// check if shift is held down

									if (e.shiftKey) {
										// if shift is held down, add a new line
										// and prevent the default action
										e.preventDefault();
										setEntry(entry + '\n');
									} else if (thread && !loading) {
										e.preventDefault();
										onSend();
									}
								}
							}}
						/>
						<Button
							onClick={() => onSend()}
							loading={loading}
							disabled={!entry || !thread || loading}
							sx={{
								width: '50px',
								height: '50px',
							}}
							size="lg"
							color="primary"
							variant="solid"
						>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<MdOutlineSend
									style={{
										fontSize: '1.5rem',
									}}
								/>
							</Box>
						</Button>
					</Box>
				</Sheet>
			</Drawer>
		</>
	);
};

export default Chat;
