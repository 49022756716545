import React, {
	PropsWithChildren,
	createContext,
	useContext,
	useEffect,
} from 'react';
import ToastContainer from './components/ToastContainer';
import Positioned from '@components/Layout/Positioned';

export interface Toast {
	timestamp: number;
	duration: number;
	message: string;
	variant: 'success' | 'error' | 'warning' | 'info';
}

const defaultSpan = 5000;

export interface ToastContext {
	queue: Toast[];
	enqueue: (
		message:
			| string
			| (Omit<Toast, 'timestamp' | 'duration'> & {
					timestamp?: number;
					duration?: number;
			  })
	) => void;
	drop: (id: number) => void;
}

const Context = createContext<ToastContext>({
	queue: [],
	enqueue: () => {},
	drop: () => {},
});

const PositionedContainer = Positioned(ToastContainer);

const threshold = 200;

const ToastProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [queue, setQueue] = React.useState<Toast[]>([]);
	const [lastDrop, setLastDrop] = React.useState(Date.now());

	useEffect(() => {
		if (queue.length > 0) {
			const now = Date.now();
			const expiration = queue[0].duration + queue[0].timestamp;
			let delay = expiration - now;

			const dropTime = now + delay;
			const isTooSoon = dropTime - lastDrop < threshold;

			if (isTooSoon) {
				delay = threshold;
			}

			const timer = setTimeout(() => {
				setLastDrop(Date.now());
				setQueue((prev) => prev.slice(1));
			}, delay);

			return () => clearTimeout(timer);
		}
	}, [queue]);

	return (
		<Context.Provider
			value={{
				drop: (timestamp) => {
					setQueue((prev) =>
						prev.filter((toast) => toast.timestamp !== timestamp)
					);
				},
				queue,
				enqueue: (message) => {
					if (typeof message === 'string') {
						const newToast: Toast = {
							timestamp: Date.now(),
							message,
							variant: 'info',
							duration: defaultSpan,
						};

						setQueue((prev) => [...prev, newToast]);
					} else {
						const toast: Toast = {
							timestamp: message.timestamp || Date.now(),
							duration: message.duration || defaultSpan,
							...message,
						};

						setQueue((prev) => [...prev, toast]);
					}
				},
			}}
		>
			{children}
			<PositionedContainer zIndex={9999} bottom={32} right={32}>
				{queue}
			</PositionedContainer>
		</Context.Provider>
	);
};

export default ToastProvider;

export const useToastContext = () => useContext(Context);
