import { useError } from '@hooks/useError';
import {
	Box,
	Button,
	Grid,
	Skeleton,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/joy';
import { EvaluationFilter } from '@schema/Evaluation/EvaluationFilter';
import schema from '@schema/index';
import React, { FC, useState } from 'react';
import { useSection } from '../Seciton';
import { Insight } from '@schema/Insight/Insight';
import Bullets from '@components/Display/Bullets';
import { useLoading } from '@hooks/useLoading';
import PerceptionDetail from './components/PerceptionDetail';
import NoData from '@components/Display/NoData';
import PerceptionItem from './components/PerceptionItem';
import PerceptionList from './components/PerceptionList';

export interface PerceptionsProps {
	filter: EvaluationFilter;
}

const Perceptions: FC<PerceptionsProps> = ({ filter }) => {
	const onError = useError();
	const { palette } = useTheme();

	const [focused, setFocused] = useState<Insight | null>(null);

	const [count, setCount] = useState(5);
	const [done, setDone] = useState(false);

	const { data, loading } = schema.evaluationSummary.get({
		variables: { filter, count },
		onError,
		onCompleted: ({ EvaluationSummary }) => {
			if (!EvaluationSummary) {
				setDone(true);
			} else {
				const { positive, negative } = EvaluationSummary;

				if (positive.length < count && negative.length < count) {
					setDone(true);
				}
			}
		},
	});

	const nulls = Array(count).fill(null);

	const positive: (Insight | null)[] =
		data?.EvaluationSummary?.positive ?? nulls;
	const negative: (Insight | null)[] =
		data?.EvaluationSummary?.negative ?? nulls;

	useSection(loading);

	const defLoading = useLoading(loading, 500);

	const elements = {
		positive: { insights: positive, color: palette.success[500] },
		negative: {
			insights: negative,
			color: palette.text.primary,
		},
	};

	const entries = Object.entries(elements);
	const filtered = entries.map(([title, { insights, color }]) => {
		return [title, insights.filter((i) => i?.value), color];
	});

	const isEmpty = !loading && filtered.every(([, i]) => i.length === 0);

	return (
		<Box sx={{ height: '100%' }}>
			{(data && data.EvaluationSummary === null) || isEmpty ? (
				<NoData sx={{ height: '360px' }} />
			) : (
				<Box>
					<Grid container spacing={6}>
						{Object.entries(elements).map(([title, { insights, color }], i) => {
							const filtered = defLoading
								? insights
								: insights.filter((i) => i?.value);
							return (
								<Grid key={`${title}-${i}`} xs={12} sm={12} md={6}>
									<PerceptionList
										mode={title as 'positive' | 'negative'}
										insights={filtered}
										filter={filter}
										loading={defLoading}
										setFocused={setFocused}
									></PerceptionList>
								</Grid>
							);
						})}
					</Grid>
					<Box p={2} />
					<Tooltip title={done ? 'No more detectible perceptions.' : ''} arrow>
						<Box>
							<Button
								loading={defLoading}
								variant="soft"
								size="sm"
								disabled={done}
								onClick={() => setCount(count + 5)}
							>
								Show More
							</Button>
						</Box>
					</Tooltip>
				</Box>
			)}
			<PerceptionDetail insight={focused} onClose={() => setFocused(null)} />
		</Box>
	);
};

export default Perceptions;
