import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Grid,
	Input,
	Textarea,
	Typography,
} from '@mui/joy';
import schema from '@schema/index';
import { Resource } from '@schema/Resource/Resource';
import React, { FC, useEffect, useRef, useState } from 'react';
import ImageSelect from './components/ImageSelect';
import { MdCheck, MdOutlineDeleteForever } from 'react-icons/md';

export interface ResourceFormInputProps {
	value: Resource;
	loading: boolean;
	onChange: (value: Resource) => void;
	onSubmit: () => void;
	onDelete?: () => void;
}

const ResourceFormInput: FC<ResourceFormInputProps> = ({
	value,
	loading,
	onChange,
	onSubmit,
	onDelete,
}) => {
	const [deleteConfirmation, setDeleteConfirmation] = useState(false);

	const deleteRef = useRef<HTMLAnchorElement>(null);

	useEffect(() => {
		const clickaway = (e: MouseEvent) => {
			if (deleteRef.current && !deleteRef.current.contains(e.target as Node)) {
				setDeleteConfirmation(false);
			}
		};

		document.addEventListener('click', clickaway);

		return () => {
			document.removeEventListener('click', clickaway);
		};
	}, []);

	const { data: imageData, loading: imageLoading } =
		schema.resource.imageOptions({
			variables: {
				url: value.url,
			},
		});

	const top3Images = imageData
		? imageData.ResourceImageOptions.slice(0, 3)
		: [null, null, null];

	return (
		<Box>
			<FormControl sx={{ width: '100%' }}>
				<FormLabel>URL</FormLabel>
				<Box sx={{ width: '100%' }}>
					<Typography level="body-lg">{value.url}</Typography>
				</Box>
			</FormControl>
			<Box p={1.5} />
			<FormControl>
				<FormLabel>Name</FormLabel>
				<Textarea
					value={value.name}
					onChange={(e) => onChange({ ...value, name: e.target.value })}
					autoFocus
					size="lg"
					placeholder="Resource Name..."
					variant="soft"
					minRows={1}
					maxRows={6}
				/>
			</FormControl>
			<Box p={1.5} />
			<FormControl>
				<FormLabel>Description</FormLabel>
				<Textarea
					value={value.description}
					onChange={(e) => onChange({ ...value, description: e.target.value })}
					size="lg"
					placeholder="Resource Description..."
					variant="soft"
					minRows={1}
					maxRows={6}
				/>
			</FormControl>
			<Box p={1.5} />
			{imageData && imageData.ResourceImageOptions.length > 0 && (
				<FormControl>
					<FormLabel>Image</FormLabel>
					<Grid container spacing={2}>
						{top3Images.map((image, index) => (
							<Grid xs={3} key={`img_${index}`}>
								<ImageSelect
									url={image}
									selected={value.image === image}
									onClick={() => onChange({ ...value, image })}
								/>
							</Grid>
						))}
						<Grid xs={3}>
							<ImageSelect
								url={null}
								selected={!value.image}
								onClick={() => onChange({ ...value, image: '' })}
							/>
						</Grid>
					</Grid>
				</FormControl>
			)}
			<Box p={2} />
			{onDelete && (
				<>
					<Button
						ref={deleteRef}
						onClick={() => {
							if (deleteConfirmation) {
								onDelete();
							} else {
								setDeleteConfirmation(true);
							}
						}}
						loading={loading}
						fullWidth
						size="lg"
						variant={deleteConfirmation ? 'solid' : 'soft'}
						color={deleteConfirmation ? 'danger' : 'neutral'}
						endDecorator={<MdOutlineDeleteForever />}
					>
						Delete
					</Button>
					<Box p={1} />
				</>
			)}
			<Button
				onClick={() => {
					onSubmit();
				}}
				loading={loading}
				fullWidth
				size="lg"
				endDecorator={<MdCheck />}
			>
				Save
			</Button>
		</Box>
	);
};

export default ResourceFormInput;
