import { useTheme } from '@mui/joy';
import { motion } from 'framer-motion';
import React, { FC } from 'react';

const Bar: FC = () => {
	const { palette } = useTheme();
	return (
		<motion.div
			initial={{ width: 24 }}
			whileInView={{ width: 220 }}
			transition={{ duration: 0.2, delay: 0.1 }}
			style={{
				background: palette.success[500],
				height: 4,
				borderRadius: 2,
				marginTop: 16,
				marginBottom: 24,
			}}
		/>
	);
};

export default Bar;
