import { getQueryHook } from "@hooks/graphql";
import * as Ops from "./CommentQuestions.Operation";

const CommentQuestionsResolver = {
  list: getQueryHook<
    Ops.CommentQuestionsQueryResponse,
    Ops.CommentQuestionsQueryVariables
  >(Ops.CommentQuestionsQuery),
};

export default CommentQuestionsResolver;
