import { getQueryHook } from '@hooks/graphql';
import * as Ops from './SimpleSummary.Operations';

const simpleSummaryResolver = {
	get: getQueryHook<Ops.SimpleSummaryRes, Ops.SimpleSummaryArgs>(
		Ops.SimpleSummaryQuery
	),
};

export default simpleSummaryResolver;
