import { gql } from '@apollo/client';
import { EvaluationStats, EvaluationStatsFragment } from './EvaluationStats';
import { EvaluationFilter } from '@schema/Evaluation/EvaluationFilter';

export const EvaluationStatsQuery = gql`
	${EvaluationStatsFragment}
	query EvaluationStats($filter: EvaluationFilter!) {
		EvaluationStats(filter: $filter) {
			...EvaluationStatsFragment
		}
	}
`;

export type EvaluationStatsQueryRes = {
	EvaluationStats: EvaluationStats;
};

export type EvaluationStatsArgs = {
	filter: EvaluationFilter;
};
