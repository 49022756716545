import { Box, useTheme } from '@mui/joy';
import { useSize } from '@utils/useSize';
import { motion } from 'framer-motion';
import React, { FC, useRef } from 'react';

type DeviceAnimation = 'pop' | 'fade' | 'left' | 'right' | 'none';

export interface DeviceProps {
	src: string;
	alt: string;
	animation?: DeviceAnimation;
}

const deviceVariants: Record<DeviceAnimation, any> = {
	pop: {
		initial: { scale: 0.95 },
		whileInView: { scale: 1 },
		transition: { duration: 0.3, delay: 0.1 },
		exit: { scale: 0.95 },
	},
	fade: {
		initial: { opacity: 0 },
		whileInView: { opacity: 1 },
		transition: { duration: 0.3, delay: 0.1 },
		exit: { opacity: 0 },
	},
	left: {
		initial: { x: -30, opacity: 0 },
		whileInView: { x: 0, opacity: 1 },
		transition: { duration: 0.3, delay: 0.1 },
		exit: { x: -30, opacity: 0 },
	},
	right: {
		initial: { x: 30, opacity: 0 },
		whileInView: { x: 0, opacity: 1 },
		transition: { duration: 0.3, delay: 0.1 },
		exit: { x: 30, opacity: 0 },
	},
	none: {},
};

const Device: FC<DeviceProps> = ({ src, alt, animation = 'pop' }) => {
	const { palette, shadow } = useTheme();

	const ref = useRef<HTMLDivElement>(null);

	const { width } = useSize(ref);

	const ratio = 1024 / 1366;

	const thk = 8;

	return (
		<motion.div
			initial={deviceVariants[animation].initial}
			whileInView={deviceVariants[animation].whileInView}
			transition={deviceVariants[animation].transition}
			exit={deviceVariants[animation].exit}
		>
			<Box ref={ref}>
				<Box
					sx={{
						width: `${width}px`,
						height: `${width * ratio}px`,
						// overflow: 'hidden',
						display: 'flex',
						flexFlow: 'column',
					}}
				>
					<Box
						sx={{
							flex: 1,
							background: palette.background.body,
							position: 'relative',
							border: `${thk}px solid ${palette.background.level3}`,
							borderRadius: `${thk * 2}px`,
							boxShadow: shadow.xl,
						}}
					>
						<img
							src={src}
							alt={alt}
							style={{
								position: 'absolute',
								width: '100%',
								height: '100%',
								objectFit: 'cover',
								borderRadius: `${thk}px`,
							}}
						/>
					</Box>
				</Box>
			</Box>
		</motion.div>
	);
};

export default Device;
