import { Box, Button, FormControl, FormLabel, Input } from '@mui/joy';
import React, { FC } from 'react';

export interface UrlFormProps {
	value: string;
	loading: boolean;
	onChange: (value: string) => void;
	onSubmit: () => void;
}

const UrlForm: FC<UrlFormProps> = ({ value, loading, onChange, onSubmit }) => {
	return (
		<Box>
			<FormControl>
				<FormLabel>URL</FormLabel>
				<Input
					value={value}
					onChange={(e) => onChange(e.target.value.replace(/\s/g, ''))}
					autoFocus
					size="lg"
					placeholder="Resource URL..."
					variant="soft"
				/>
			</FormControl>
			<Box p={2} />
			<Button
				onClick={onSubmit}
				disabled={!value}
				loading={loading}
				size="lg"
				fullWidth
			>
				Next
			</Button>
		</Box>
	);
};

export default UrlForm;
