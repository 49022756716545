import React, {
	FC,
	PropsWithChildren,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import config from './config';
import { initializeApp } from 'firebase/app';
import { User, getAuth } from 'firebase/auth';

const app = initializeApp(config);
export const auth = getAuth(app);

export interface AuthenticationContext {
	user: User | null;
	loading: boolean;
	error: Error | null;
}

const Context = createContext<AuthenticationContext>({
	user: null,
	loading: false,
	error: null,
});

const AuthenticationProvider: FC<PropsWithChildren> = ({ children }) => {
	const [user, setUser] = useState<User | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<Error | null>(null);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(
			(user) => {
				setUser(user);
				setLoading(false);
			},
			(error) => {
				setError(error);
				setLoading(false);
			}
		);

		return () => unsubscribe();
	}, []);

	return (
		<Context.Provider
			value={{
				user,
				loading,
				error,
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default AuthenticationProvider;

export const useAuthentication = () => useContext(Context);
