import React, { FC } from 'react';
import { Toast } from '../..';
import { Card, Typography, useTheme } from '@mui/joy';
import { MdCheck, MdWarning, MdInfo, MdError } from 'react-icons/md';

export interface ToastBarProps {
	children: Toast;
}

const ToastBar: FC<ToastBarProps> = ({ children: toast }) => {
	const { palette } = useTheme();

	const icons: Record<Toast['variant'], React.ReactNode> = {
		success: (
			<MdCheck
				style={{
					fontSize: '1.5rem',
					color: palette.success[500],
				}}
			/>
		),
		error: (
			<MdError
				style={{
					fontSize: '1.5rem',
					color: palette.danger[500],
				}}
			/>
		),
		warning: (
			<MdWarning
				style={{
					fontSize: '1.5rem',
					color: palette.warning[500],
				}}
			/>
		),
		info: (
			<MdInfo
				style={{
					fontSize: '1.5rem',
					color: palette.primary[500],
				}}
			/>
		),
	};

	return (
		<Card
			variant="soft"
			sx={{
				padding: 2,
				display: 'flex',
				flexFlow: 'row',
				gap: 1,
			}}
		>
			{icons[toast.variant]}
			<Typography level="title-md">{toast.message}</Typography>
		</Card>
	);
};

export default ToastBar;
