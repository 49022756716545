import { Role } from '@auth/Role';
import { Box, BoxProps, Typography, useTheme } from '@mui/joy';
import { useClaimsContext } from '@providers/ClaimsProvider';
import { Unit } from '@schema/Unit/Unit';
import React, { FC } from 'react';
import { MdArrowForward } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

export interface BreadcrumbsProps {
	units: Unit[];
	onClick: (unit: Unit) => void;
	sx?: BoxProps['sx'];
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ units, onClick, sx }) => {
	const { palette } = useTheme();
	const { claims } = useClaimsContext();
	const nav = useNavigate();
	return (
		<Box
			sx={{
				display: 'flex',
				gap: 1,
				...sx,
			}}
		>
			{claims && claims.role === Role.User && (
				<Box
					onClick={() => {
						nav('/app/dashboard/StudentFeedback');
					}}
					sx={{
						borderRadius: '4px',
						cursor: 'pointer',
						':hover': {
							background: palette.background.level1,
						},
						':active': {
							background: palette.background.level2,
						},
						paddingLeft: 1,
						paddingRight: 1,
					}}
				>
					<Typography level="body-sm" endDecorator={<MdArrowForward />}>
						Home
					</Typography>
				</Box>
			)}
			{units.map((unit, i) => {
				return (
					<Box
						onClick={() => onClick(unit)}
						sx={{
							borderRadius: '4px',
							cursor: 'pointer',
							':hover': {
								background: palette.background.level1,
							},
							':active': {
								background: palette.background.level2,
							},
							paddingLeft: 1,
							paddingRight: 1,
						}}
						key={`bc_${unit._id}`}
					>
						<Typography
							level="body-sm"
							endDecorator={
								i !== units.length - 1 ? <MdArrowForward /> : undefined
							}
						>
							{unit.name}
						</Typography>
					</Box>
				);
			})}
		</Box>
	);
};

export default Breadcrumbs;
