import { gql } from '@apollo/client';
import { Identified } from '@schema/Identified/Identified';
import { Message } from '@schema/Message/Message';

export class Thread extends Identified {
	uid!: string;
	tokenUsage!: number;
	tokenLimit!: number;
	messages!: Message[];
}

export const ThreadFragment = gql`
	fragment ThreadFragment on Thread {
		_id
		uid
		tokenUsage
		tokenLimit
		messages {
			...MessageFragment
		}
	}
`;
