import { gql } from '@apollo/client';
import { Unit, UnitFragment } from './Unit';
import { List } from '@schema/List/List';
import { UnitFilter } from './Unit.Filter';
import { Sort } from '@schema/Sort/Sort';

// UnitNullableQuery
// --------------------------------------------------------

export const UnitNullableQuery = gql`
	${UnitFragment}
	query UnitNullableQuery($id: ID) {
		Unit: UnitNullable(id: $id) {
			...UnitFragment
		}
	}
`;

export type UnitNullableQueryResult = {
	Unit: Unit | null;
};

export type UnitNullableQueryVars = {
	id?: string | null;
};

// UnitQuery
// --------------------------------------------------------

export const UnitQuery = gql`
	${UnitFragment}
	query UnitQuery($id: ID!) {
		Unit(id: $id) {
			...UnitFragment
		}
	}
`;

export type UnitQueryResult = {
	Unit: Unit;
};

export type UnitQueryVars = {
	id: string;
};

// UnitListQuery
// --------------------------------------------------------

export const UnitListQuery = gql`
	${UnitFragment}
	query UnitList($filter: UnitFilter!, $skip: Int, $take: Int, $sort: [Sort!]) {
		List: UnitList(filter: $filter, skip: $skip, take: $take, sort: $sort) {
			total
			hasMore
			records {
				...UnitFragment
			}
		}
	}
`;

export type UnitListQueryResult = {
	List: List<Unit>;
};

export type UnitListQueryVars = {
	filter: UnitFilter;
	skip?: number | null;
	take?: number | null;
	sort?: Sort[] | null;
};

// UnitRootsQuery
// --------------------------------------------------------

export const UnitRootsQuery = gql`
	${UnitFragment}
	query UnitRootsQuery {
		Units: UnitRoots {
			...UnitFragment
		}
	}
`;

export type UnitRootsQueryResult = {
	Units: Unit[];
};

export type UnitRootsQueryVars = {};
