import { useToastContext } from '@providers/ToastProvider';

export const useError = (): ((error: Error) => void) => {
	const { enqueue } = useToastContext();

	return (error: Error) => {
		enqueue({
			variant: 'error',
			message: error.message || error.name || 'Unknown error',
		});
	};
};
