import { Box, IconButton, Select, Option, Tooltip } from '@mui/joy';
import { Unit } from '@schema/Unit/Unit';
import React, { FC, useEffect, useState } from 'react';
import { MdClear } from 'react-icons/md';

export interface AssignmentFormProps {
	value: { _id: string; name: string; type: string };
	onChange: (value: { _id: string; name: string; type: string } | null) => void;
	units: Unit[];
	removable?: boolean;
	taken: string[];
}

const AssignmentForm: FC<AssignmentFormProps> = ({
	value,
	onChange,
	units,
	removable = true,
	taken,
}) => {
	const unit = units.find((unit) => unit._id === value._id) ?? null;

	const typedUnits = units.filter((unit) => unit.type === value.type);

	return (
		<Box sx={{ display: 'flex', gap: 2 }}>
			<Select
				sx={{ width: '180px' }}
				size="lg"
				placeholder="Type"
				value={value.type}
				onChange={(e, v) => {
					if (v) {
						const match = units.find(
							(unit) => unit.type === v && !taken.includes(unit._id)
						);
						onChange({
							_id: match?._id ?? '',
							name: match?.name ?? '',
							type: v,
						});
					}
				}}
			>
				<Option value="College">College</Option>
				<Option value="Department">Department</Option>
			</Select>
			<Box sx={{ flex: 1 }}>
				<Select
					value={unit}
					size="lg"
					placeholder={`Select a ${value.type}`}
					onChange={(e, v) => {
						if (v) {
							onChange({
								...value,
								name: v.name,
								type: value.type,
								_id: v._id,
							});
						}
					}}
				>
					{typedUnits.map((unit) => (
						<Option key={unit._id} value={unit}>
							{unit.name}
						</Option>
					))}
				</Select>
			</Box>
			<Tooltip
				title={
					removable
						? 'Remove assignment'
						: 'User role must have at least one assignment'
				}
			>
				<Box>
					<IconButton
						disabled={!removable}
						variant="soft"
						size="lg"
						onClick={() => onChange(null)}
					>
						<MdClear />
					</IconButton>
				</Box>
			</Tooltip>
		</Box>
	);
};

export default AssignmentForm;
