import { Box, Skeleton, Typography } from '@mui/joy';
import { View } from '@scenes/Root/scenes/App/navigation/Nav';
import { Unit } from '@schema/Unit/Unit';
import { splitByCapital } from '@utils/splitByCapital';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

export interface TitleProps {
	unit: Unit | null;
}

const Title: FC<TitleProps> = ({ unit }) => {
	const { view } = useParams();
	return (
		<Box>
			<Typography color="success" sx={{ fontWeight: 'bold' }}>
				{splitByCapital(view ?? '')}
			</Typography>
			<Typography level="h3">
				<Skeleton loading={!unit}>{unit?.name ?? 'Title title title'}</Skeleton>
			</Typography>
		</Box>
	);
};

export default Title;
