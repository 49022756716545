import ResponsiveDialog from '@components/Layout/ResponsiveDialog';
import { useError } from '@hooks/useError';
import { useSuccess } from '@hooks/useSuccess';
import {
	Box,
	Button,
	FormControl,
	Grid,
	IconButton,
	Typography,
} from '@mui/joy';
import { UserQuery, UsersQuery } from '@schema/User/User.Operations';
import schema from '@schema/index';
import React, { FC, ReactNode, useState } from 'react';
import { MdClear } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';

export interface DeleteFormProps {
	children: (provided: { onClick: () => void }) => ReactNode;
}

const DeleteForm: FC<DeleteFormProps> = ({ children }) => {
	const onError = useError();
	const onSuccess = useSuccess();
	const nav = useNavigate();

	const { uid } = useParams();

	const { data } = schema.user.get({
		variables: { uid: uid! },
	});

	const user = data?.User ?? null;

	const [open, setOpen] = useState(false);

	const onClose = () => {
		setOpen(false);
	};

	const [handleDelete, { loading }] = schema.user.delete({
		variables: { uid: uid! },
		onError,
		onCompleted: () => {
			onSuccess('Account deleted');
			onClose();
			nav('/app/admin/access');
		},
		refetchQueries: [UserQuery, UsersQuery],
	});

	return (
		<>
			{children({ onClick: () => setOpen(true) })}
			<ResponsiveDialog open={open} onClose={onClose}>
				<Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							gap: 6,
						}}
					>
						<Typography
							level="h4"
							sx={{
								maxWidth: '360px',
							}}
						>
							{`Are you sure you want to delete ${
								user?.displayName ?? ''
							}'s account`}
						</Typography>
						<IconButton
							disabled={loading}
							onClick={() => {
								onClose();
							}}
						>
							<MdClear />
						</IconButton>
					</Box>
					<Box p={1} />
					<Grid container spacing={2}>
						<Grid xs={12} sm={12} md={6}>
							<Button
								onClick={onClose}
								disabled={loading}
								size="lg"
								variant="solid"
								fullWidth
								color="neutral"
							>
								No, go back
							</Button>
						</Grid>
						<Grid xs={12} sm={12} md={6}>
							<Button
								onClick={() => handleDelete()}
								loading={loading}
								size="lg"
								variant="solid"
								fullWidth
								color="danger"
							>
								Yes, delete
							</Button>
						</Grid>
					</Grid>
				</Box>
			</ResponsiveDialog>
		</>
	);
};

export default DeleteForm;
