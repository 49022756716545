import CopyButton from '@components/Input/CopyButton';
import { Box, Button, Card, IconButton, Typography } from '@mui/joy';
import schema from '@schema/index';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, useEffect, useRef, useState } from 'react';
import { IoShareOutline } from 'react-icons/io5';

export interface ExportButtonProps {
	thread: string | null;
}

const ExportButton: FC<ExportButtonProps> = ({ thread }) => {
	const [link, setLink] = useState<string | null>(null);

	const [getLink, { loading }] = schema.thread.share({
		variables: {
			thread: thread!,
		},
		onCompleted: ({ ThreadShare }) => {
			setLink(ThreadShare);
		},
	});

	const onClose = () => {
		setLink(null);
	};

	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClick = (e: MouseEvent) => {
			if (ref.current && !ref.current.contains(e.target as Node)) {
				onClose();
			}
		};

		document.addEventListener('click', handleClick);

		return () => {
			document.removeEventListener('click', handleClick);
		};
	}, []);

	return (
		<Box ref={ref} sx={{ position: 'relative' }}>
			<Button
				variant="plain"
				onClick={() => {
					getLink();
				}}
				loading={loading}
				sx={{
					height: '44px',
					width: '44px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					padding: 0,
				}}
				size="lg"
			>
				<IoShareOutline />
			</Button>
			<AnimatePresence>
				{link && (
					<motion.div
						style={{
							position: 'absolute',
							top: 'calc(100% + 8px)',
							right: 0,
							zIndex: 1,
						}}
						initial={{ opacity: 0, y: 10 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: 10 }}
					>
						<Card sx={{ width: '320px' }}>
							<CopyButton
								color="success"
								size="lg"
								value={`https://teachingframework.utah.edu/chat/${link}`}
							>
								Copy link to clipboard
							</CopyButton>
							<Typography level="body-xs">
								Messages you send after creating your link won't be shared.
								Anyone with the URL will be able to view the shared chat.
							</Typography>
						</Card>
					</motion.div>
				)}
			</AnimatePresence>
		</Box>
	);
};

export default ExportButton;
