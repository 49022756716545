import { gql } from '@apollo/client';
import { Identified } from '@schema/Identified/Identified';
import { IoDocumentAttach } from 'react-icons/io5';

export const ResourceIcon = IoDocumentAttach;

export class Resource extends Identified {
	unit!: {
		_id: string;
		name: string;
		type: string;
	};
	unitType!: string;
	name!: string;
	description!: string;
	metadata?: string | null;
	type!: string;
	url!: string;
	image?: string | null;
	score?: number | null;
}

export const ResourceFragment = gql`
	fragment ResourceFragment on Resource {
		_id
		unit {
			_id
			name
			type
		}
		name
		description
		metadata
		type
		url
		image
		score
	}
`;
