import React, { FC, useEffect, useRef, useState } from 'react';
import Chat from '../../../Chat';
import { EvaluationFilter } from '@schema/Evaluation/EvaluationFilter';
import Pop from '@components/Animation/Pop';
import {
	Box,
	Card,
	List,
	ListItem,
	ListItemButton,
	Skeleton,
	Typography,
	useTheme,
} from '@mui/joy';
import { Insight } from '@schema/Insight/Insight';
import { AnimatePresence, motion } from 'framer-motion';
import { BiSolidQuoteRight } from 'react-icons/bi';
import { FaMagic } from 'react-icons/fa';
import ResourcePanel from '../ResourcePanel';
import { ResourceIcon } from '@schema/Resource/Resource';

export interface PerceptionItemProps {
	filter: EvaluationFilter;
	setFocused: (insight: Insight | null) => void;
	insight: Insight | null;
	defLoading: boolean;
}

const PerceptionItem: FC<PerceptionItemProps> = ({
	filter,
	insight,
	setFocused,
	defLoading,
}) => {
	const { palette, shadow } = useTheme();

	const [anchor, setAnchor] = useState<{ x: number; y: number } | null>(null);
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClick = (e: MouseEvent) => {
			if (ref.current && !ref.current.contains(e.target as Node)) {
				setAnchor(null);
			}
		};
		document.addEventListener('click', handleClick);
		return () => {
			document.removeEventListener('click', handleClick);
		};
	}, []);

	return (
		<Box ref={ref} sx={{ position: 'relative' }}>
			<ResourcePanel>
				{({ onClick: resourceClick }) => (
					<Chat filter={filter}>
						{({ onClick, error }) => (
							<>
								<Pop scale={1.01}>
									<Box
										onClick={(e) => {
											// set x and y relative to the parent Box
											const x =
												e.clientX - ref.current!.getBoundingClientRect().left;
											const y =
												e.clientY - ref.current!.getBoundingClientRect().top;

											// child is 160px wide
											// make sure the anchor is within the parent Box - 160px
											const clampedX = Math.min(
												x,
												ref.current!.getBoundingClientRect().width - 160
											);

											setAnchor({ x: clampedX, y });
										}}
										sx={{
											p: 0.5,
											paddingLeft: 1,
											paddingRight: 1,
											cursor: 'pointer',
											':hover': {
												background: palette.background.level1,
											},
											':active': {
												background: palette.background.level2,
											},
											borderRadius: '8px',
											zIndex: 1,
											background: anchor
												? palette.background.level1
												: 'transparent',
											border: `1px solid ${
												anchor ? palette.neutral[300] : 'transparent'
											}`,
										}}
									>
										<Typography>
											<Skeleton loading={!insight?.value || defLoading}>
												{insight?.value ??
													"Instructors who are perceived as kind significantly enhance students' learning experiences and overall satisfaction with the course"}
											</Skeleton>
										</Typography>
									</Box>
								</Pop>
								<AnimatePresence>
									{anchor && (
										<motion.div
											style={{
												position: 'absolute',
												top: anchor.y,
												left: anchor.x,
												zIndex: 10,
											}}
											initial={{ opacity: 0 }}
											animate={{ opacity: 1 }}
											exit={{ opacity: 0 }}
										>
											<Card
												variant="outlined"
												sx={{ boxShadow: shadow.xl, p: 0, width: '160px' }}
											>
												<List>
													<ListItem>
														<ListItemButton
															onClick={() => {
																if (insight?.value) {
																	setFocused(insight);
																	setAnchor(null);
																}
															}}
														>
															<Box
																sx={{
																	display: 'flex',
																	alignItems: 'center',
																	whiteSpace: 'nowrap',
																	gap: 1,
																}}
															>
																<Box
																	sx={{
																		display: 'flex',
																		alignItems: 'center',
																		justifyContent: 'center',
																	}}
																>
																	<BiSolidQuoteRight />
																</Box>
																Show quotes
															</Box>
														</ListItemButton>
													</ListItem>
													<ListItem>
														<ListItemButton
															onClick={() => {
																if (insight?.value) {
																	onClick(
																		`Tell me more about this insight:
																
																${insight?.value}
																`.replace(/\n/g, ' ')
																	);

																	setAnchor(null);
																}
															}}
														>
															<Box
																sx={{
																	display: 'flex',
																	alignItems: 'center',
																	whiteSpace: 'nowrap',
																	gap: 1,
																}}
															>
																<Box
																	sx={{
																		display: 'flex',
																		alignItems: 'center',
																		justifyContent: 'center',
																	}}
																>
																	<FaMagic />
																</Box>
																Ask questions
															</Box>
														</ListItemButton>
													</ListItem>
													<ListItem>
														<ListItemButton
															onClick={() => {
																if (insight?.value) {
																	resourceClick(
																		insight.value,
																		filter.ancestry ?? []
																	);
																	setAnchor(null);
																}
															}}
														>
															<Box
																sx={{
																	display: 'flex',
																	alignItems: 'center',
																	whiteSpace: 'nowrap',
																	gap: 1,
																}}
															>
																<Box
																	sx={{
																		display: 'flex',
																		alignItems: 'center',
																		justifyContent: 'center',
																	}}
																>
																	<ResourceIcon />
																</Box>
																Find Resources
															</Box>
														</ListItemButton>
													</ListItem>
												</List>
											</Card>
										</motion.div>
									)}
								</AnimatePresence>
							</>
						)}
					</Chat>
				)}
			</ResourcePanel>
		</Box>
	);
};

export default PerceptionItem;
