import { useTheme } from '@mui/joy';
import { ReactComponent as SVG } from './SVG/Logo.svg';
import React from 'react';
import { extractHex } from '@utils/extractHex';

const Logo: React.FC<
	React.SVGProps<SVGSVGElement> & { title?: string; bright?: boolean }
> = ({ bright = true, ...props }) => {
	const { palette } = useTheme();
	const hex = extractHex(palette.text.primary);
	return <SVG fill={bright ? '#F50000' : hex || undefined} {...props} />;
};

export default Logo;
