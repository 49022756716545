import Bullets from '@components/Display/Bullets';
import { Box, Typography, useTheme } from '@mui/joy';
import { Insight } from '@schema/Insight/Insight';
import React, { FC } from 'react';
import PerceptionItem from '../PerceptionItem';
import { EvaluationFilter } from '@schema/Evaluation/EvaluationFilter';

export interface PerceptionListProps {
	mode: 'positive' | 'negative';
	insights: (Insight | null)[];
	filter: EvaluationFilter;
	loading: boolean;
	setFocused: (insight: Insight | null) => void;
}

const PerceptionList: FC<PerceptionListProps> = ({
	mode,
	insights,
	filter,
	loading,
	setFocused,
}) => {
	const { palette } = useTheme();

	const color =
		mode === 'negative' ? palette.text.primary : palette.success[500];

	const title =
		mode === 'negative' ? 'Could use improvement' : "What's working well";

	return (
		<Box>
			<Typography sx={{ color, fontWeight: 'bold', fontStyle: 'italic' }}>
				{title}
			</Typography>
			<Box p={0.5} />
			<Bullets>
				{insights.map((insight, insightIndex) => (
					<PerceptionItem
						key={`${title}-${mode}-${insightIndex}`}
						filter={filter}
						setFocused={setFocused}
						insight={insight}
						defLoading={loading}
					/>
				))}
			</Bullets>
		</Box>
	);
};

export default PerceptionList;
