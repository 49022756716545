import { Box, Grid, Skeleton, Typography, useTheme } from "@mui/joy";
import { Unit } from "@schema/Unit/Unit";
import schema from "@schema/index";
import { motion } from "framer-motion";
import React, { FC } from "react";

export interface SuggestionsProps {
  prompts: string[];
  onClick: (message: string) => void;
}

const Suggestions: FC<SuggestionsProps> = ({ prompts, onClick }) => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        p: 8,
      }}
    >
      <Grid container spacing={4}>
        {(prompts ?? [null, null, null, null, null, null]).map(
          (prompt, index) => (
            <Grid key={`prompt-${index}`} xs={12} sm={6} md={6} lg={6} xl={6}>
              <Box onClick={() => prompt && onClick(prompt)}>
                {prompt ? (
                  <motion.div
                    custom={Math.floor(index / 2)}
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    transition={{ duration: 0.15 }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "120px",
                        background: palette.background.level1,
                        borderRadius: "8px",
                        ":hover": {
                          background: palette.background.level2,
                        },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Box p={1.5}>
                        <Typography>{prompt}</Typography>
                      </Box>
                    </Box>
                  </motion.div>
                ) : (
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={120}
                  />
                )}
              </Box>
            </Grid>
          )
        )}
      </Grid>
    </Box>
  );
};

export default Suggestions;

const variants = {
  hidden: { opacity: 0, y: -10, scale: 0.9 },
  visible: (i: number) => ({
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      delay: i * 0.05,
    },
  }),
};

export const usePrompts = (units: Unit[]) => {
  const lastUnit = units[units.length - 1];

  if (!lastUnit) {
    return null;
  }

  switch (lastUnit.type) {
    case "University": {
      return [
        "Compare and contrast the feedback from the College of Science and the College of Humanities.",
        "Which colleges appear to be doing well? Which one's need attention?",
        "What are some common themes across the colleges?",
        "What are some unique feedback points from each college?",
        "What are some common areas for improvement?",
        "Make suggestions for curriculum changes based on the feedback.",
      ];
    }
    case "College": {
      return [
        "How do the departments in the college compare to each other?",
        `What are some common themes across the departments in the ${lastUnit.name}?`,
        `What are some unique feedback points from each department in the ${lastUnit.name}?`,
        `What are some common areas for improvement in the ${lastUnit.name}?`,
        "Highlight 5 instructors who have received the most positive feedback in the college.",
        "Help me understand the way students feel in the classroom",
      ];
    }
    default: {
      return [
        "Can you give me a summary of the main feedback points from the latest course evaluations?",
        "What is working really well in my courses?",
        "How is the feedback distributed across categories like course content, teaching methods, resources, and assessments?",
        "How has the feedback evolved over the past three semesters?",
        "Based on the feedback trends, what are some suggested areas for improvement?",
        "Were there any outlier comments or feedback significantly differing from the majority?",
      ];
    }
  }
};
