import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Account from './scenes/Account';
import Admin from './scenes/Admin';
import Protected from '@auth/components/Protected';
import { Role } from '@auth/Role';
import { Box } from '@mui/joy';
import { useScreenSize } from '@hooks/useScreenSize';
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import NotFound from '../NotFound';
import DashboardWrap from './components/DashboardWrap';
import { useClaimsContext } from '@providers/ClaimsProvider';
import { View } from './navigation/Nav';
import Resources from './scenes/Resources';

const App: FC = () => {
	const { down } = useScreenSize();
	const small = down('md');
	const { claims } = useClaimsContext();

	const redirect = `dashboard/${View.StudentFeedback}`;

	return (
		<Box
			sx={{
				height: '100%',
				display: 'flex',
				alignItems: 'stretch',
				overflow: 'hidden',
			}}
		>
			{!small && <Sidebar />}
			<Box
				sx={{
					flex: 1,
					display: 'flex',
					flexFlow: 'column',
					overflow: 'hidden',
				}}
			>
				{small && <Topbar />}
				<Box sx={{ flex: 1, overflow: 'hidden' }}>
					<Routes>
						<Route index element={<Navigate to={redirect} replace={true} />} />
						<Route
							path="dashboard"
							element={
								<Navigate
									to={redirect.replace('dashboard/', '')}
									replace={true}
								/>
							}
						/>
						<Route path="dashboard/:view/*" element={<DashboardWrap />} />
						<Route path="resources" element={<Resources />} />
						<Route path="account" element={<Account />} />
						<Route
							path="admin/*"
							element={
								<Protected role={Role.Owner}>
									<Admin />
								</Protected>
							}
						/>

						<Route path="*" element={<NotFound />} />
					</Routes>
				</Box>
			</Box>
		</Box>
	);
};

export default App;
