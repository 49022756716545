import { getMutationHook, getQueryHook } from '@hooks/graphql';
import * as Ops from './Admin.Operations';

const adminResolver = {
	get: getQueryHook<Ops.AdminQueryRes, Ops.AdminQueryVars>(Ops.AdminQuery),
	clearCache: getMutationHook<
		Ops.AdminCacheClearMutationRes,
		Ops.AdminCacheClearMutationVars
	>(Ops.AdminCacheClear),
};

export default adminResolver;
