import { RefObject, useEffect, useState } from 'react';

export const useScrollPosition = (ref: RefObject<HTMLDivElement>) => {
	const [scroll, setScroll] = useState(0);

	useEffect(() => {
		const handleScroll = () => {
			if (ref.current) {
				setScroll(ref.current.scrollTop);
			}
		};

		if (ref.current) {
			ref.current.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (ref.current) {
				ref.current.removeEventListener('scroll', handleScroll);
			}
		};
	}, [ref]);

	return scroll;
};
