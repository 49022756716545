import { gql } from '@apollo/client';
import { Identified } from '@schema/Identified/Identified';

export class Message extends Identified {
	time!: number;
	role!: string;
	content!: string;
	feedback?: -1 | 1 | null;
}

export const MessageFragment = gql`
	fragment MessageFragment on Message {
		_id
		time
		role
		content
		feedback
	}
`;
