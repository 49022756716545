import React, { FC, useEffect, useRef, useState } from 'react';
import { DashboardScope, useDashboardScope } from './useDashboardScope';
import Problem from '@scenes/Root/scenes/Problem';
import { View } from '../../navigation/Nav';
import Overview from './views/Overview';
import TopicModeler from './views/TopicModeler';
import Scoped from './views/Scoped';
import { Box, useColorScheme, useTheme } from '@mui/joy';
import CenterFlex from '@components/Layout/CenterFlex';
import Header from './components/Header';
import Footer from './components/Footer';
import schema from '@schema/index';
import { Unit } from '@schema/Unit/Unit';
import { useError } from '@hooks/useError';
import { useStoredState } from '@utils/useStoredState';
import useScrollDirection from '@hooks/useScrollDirection';
import { EvaluationType } from '@schema/Evaluation/EvaluationType';

type ViewProps = {
	scope: DashboardScope;
	setScope: (scope: DashboardScope) => void;
	type: string;
	setType: (type: string) => void;
	options: string[];
	evaluationType: EvaluationType;
	setEvaluationType: (evaluationType: EvaluationType) => void;
};

const viewMap: Record<View, FC<ViewProps>> = {
	Overview,
	StudentFeedback: Scoped,
	InstructorReflection: Scoped,
	PeerReviews: Scoped,
	SentimentMap: TopicModeler,
};

const maxWidth = 1100;

const Home: FC = () => {
	const onError = useError();

	const { palette } = useTheme();

	const { colorScheme } = useColorScheme();
	const ref = useRef<HTMLDivElement>(null);

	const scrollDirection = useScrollDirection(ref);

	const [{ data: scope, error }, setScope] = useDashboardScope();

	const [units, setUnits] = useState<Unit[]>([]);

	const typeOptions = useTypeOptions(units);

	const [type, setType] = useStoredState('dashboardType', typeOptions[0] ?? '');

	const [evaluationType, setEvaluationType] = useStoredState(
		'evaluationType',
		EvaluationType.StudentCourseFeedback
	);

	const [perception, setPerception] = useStoredState('perception', '');

	useEffect(() => {
		setType(typeOptions[0] ?? '');
	}, [typeOptions]);

	const { loading: unitLoading } = schema.unit.list({
		variables: {
			filter: {
				ids: scope.ancestry,
			},
		},
		skip: !scope.ancestry,
		onCompleted: (data) => {
			const inputs = scope.ancestry.map(
				(id) => data.List.records.find((unit) => unit._id === id) ?? null
			);

			const newUnits = inputs.filter((input) => input !== null) as Unit[];

			if (newUnits.length !== units.length) setUnits(newUnits);
			else if (!newUnits.every((newUnit, i) => newUnit._id === units[i]._id))
				setUnits(newUnits);
		},
		onError,
	});

	if (error) return <Problem error={error} />;

	const ViewComponent = viewMap[scope.view];

	return (
		<Box
			sx={{
				height: '100%',
				display: 'flex',
				flexFlow: 'column',
				overflow: 'hidden',
			}}
		>
			<Box sx={{ height: '8px' }} />
			<Box
				sx={{
					flex: 1,
					display: 'flex',
					flexFlow: 'column',
					overflow: 'hidden',
					background: palette.background.surface,
					borderRadius: '8px',
					marginRight: '8px',
					marginLeft: '8px',
					border:
						colorScheme === 'light' ? `1px solid ${palette.divider}` : 'none',
				}}
			>
				<CenterFlex maxWidth={maxWidth}>
					<Header
						evaluationType={
							scope.view === View.StudentFeedback
								? EvaluationType.StudentCourseFeedback
								: scope.view === View.InstructorReflection
								? EvaluationType.InstructorReflection
								: scope.view === View.PeerReviews
								? EvaluationType.PeerReview
								: evaluationType
						}
						open={scrollDirection !== 'down'}
						type={type}
						setType={setType}
						options={typeOptions}
						scope={scope}
						setScope={setScope}
						units={units}
						onNav={(unit) => {
							setScope({
								...scope,
								ancestry: [...scope.ancestry, unit._id],
							});
							setUnits([...units, unit]);
						}}
					/>
				</CenterFlex>
				<Box ref={ref} sx={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
					<CenterFlex maxWidth={maxWidth}>
						<ViewComponent
							evaluationType={evaluationType}
							setEvaluationType={setEvaluationType}
							scope={scope}
							setScope={setScope}
							type={type}
							setType={setType}
							options={typeOptions}
						/>
					</CenterFlex>
				</Box>
			</Box>
			<Box sx={{ height: '8px' }} />
			<Footer scope={scope} setScope={setScope} />
		</Box>
	);
};

export default Home;

const useTypeOptions = (units: Unit[]) => {
	const getTypeOptions = (units: Unit[]) => {
		const hasUniversity = units.some((unit) => unit.type === 'University');
		const hasCollege = units.some((unit) => unit.type === 'College');
		const hasDepartment = units.some((unit) => unit.type === 'Department');
		const hasInstructor = units.some((unit) => unit.type === 'Instructor');
		const hasCourse = units.some((unit) => unit.type === 'Course');
		const hasListing = units.some((unit) => unit.type === 'Listing');

		if (hasListing) return [];
		else if (hasCourse && hasInstructor) return ['Listing'];
		else if (hasCourse) return ['Instructor'];
		else if (hasInstructor) return ['Course'];
		else if (hasDepartment) return ['Instructor', 'Course'];
		else if (hasCollege) return ['Department', 'Course', 'Instructor'];
		else if (hasUniversity)
			return ['College', 'Department', 'Course', 'Instructor'];
		else
			return [
				'University',
				'College',
				'Department',
				'Course',
				'Instructor',
				'Listing',
			];
	};

	const inputs = getTypeOptions(units);

	const [options, setOptions] = useState(inputs);

	useEffect(() => {
		if (inputs.length !== options.length) setOptions(inputs);
		else if (!inputs.every((input, i) => input === options[i]))
			setOptions(inputs);
	}, [inputs]);

	return options;
};
