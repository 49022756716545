import React, { FC, PropsWithChildren, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './index.css';
import { Box } from '@mui/joy';

export interface RepsonsiveDialogProps {
	open: boolean;
	onClose: () => void;
}

const RepsonsiveDialog: FC<PropsWithChildren<RepsonsiveDialogProps>> = ({
	open,
	onClose,
	children,
}) => {
	useEffect(() => {
		if (open) {
			document.body.classList.add('no-scroll');
		} else {
			document.body.classList.remove('no-scroll');
		}

		return () => {
			document.body.classList.remove('no-scroll');
		};
	}, [open]);

	return (
		<AnimatePresence>
			{open && (
				<motion.div
					className="dialog-backdrop"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					onClick={onClose}
				>
					<motion.div
						onClick={(e) => e.stopPropagation()}
						variants={{
							open: { y: 0, opacity: 1 },
							closed: { y: '100%', opacity: 0.7 },
						}}
						initial="closed"
						animate="open"
						exit="closed"
						transition={{ duration: 0.35, ease: 'easeInOut' }}
					>
						<Box>{children}</Box>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default RepsonsiveDialog;
