import { Box, useTheme } from '@mui/joy';
import React, { FC } from 'react';

export interface ImageSelectProps {
	url: string | null;
	selected: boolean;
	onClick: () => void;
}

const ImageSelect: FC<ImageSelectProps> = ({ url, selected, onClick }) => {
	const { palette } = useTheme();
	return (
		<Box
			onClick={() => {
				onClick();
			}}
		>
			{url ? (
				<img
					style={{
						cursor: 'pointer',
						width: '100%',
						height: '72px',
						objectFit: 'contain',
						borderRadius: '8px',
						border: `3px solid ${
							selected ? palette.primary[500] : palette.neutral[300]
						}`,
					}}
					src={url ?? ''}
					alt="Source Selection"
				/>
			) : (
				<Box
					sx={{
						width: '100%',
						height: '72px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: '8px',
						border: `3px solid ${
							selected ? palette.primary[500] : palette.neutral[300]
						}`,
						background: palette.neutral[200],
						color: palette.neutral[500],
					}}
				>
					None
				</Box>
			)}
		</Box>
	);
};

export default ImageSelect;
