import { Box, Stack, useColorScheme, useTheme } from '@mui/joy';
import { useStoredState } from '@utils/useStoredState';
import React, { FC, useRef, useState } from 'react';
import { nav } from '../../navigation/Nav';
import SideSection from './components/SideSection';
import SideButton from './components/SideButton';
import {
	MdChevronRight,
	MdOutlineDarkMode,
	MdOutlineExitToApp,
	MdOutlineLightMode,
} from 'react-icons/md';
import { motion } from 'framer-motion';
import Pop from '@components/Animation/Pop';
import Logo from '@components/Display/Logo';
import { useNavigate } from 'react-router-dom';

const Sidebar: FC = () => {
	const navigate = useNavigate();
	const { palette } = useTheme();
	const { mode, systemMode, setMode, colorScheme } = useColorScheme();
	const [keepOpen, setKeepOpen] = useStoredState<boolean>('sidebar', true);
	const [focused, setFocused] = useStoredState<string>('focused', '');
	const [hovered, setHovered] = useState(false);

	const open = keepOpen || hovered;

	const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);

	const handleMouseEnter = () => {
		if (hoverTimeoutRef.current) {
			clearTimeout(hoverTimeoutRef.current);
		}
		hoverTimeoutRef.current = setTimeout(() => {
			setHovered(true);
		}, 100);
	};

	const handleMouseLeave = () => {
		if (hoverTimeoutRef.current) {
			clearTimeout(hoverTimeoutRef.current);
		}
		setHovered(false);
	};

	const isDarkMode =
		mode === 'system' ? systemMode === 'dark' : mode === 'dark';

	return (
		<Box
			sx={{
				display: 'flex',
				flexFlow: 'column',
				p: 1,
				paddingRight: 0,
			}}
		>
			<Box
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				sx={{
					flex: 1,
					display: 'flex',
					flexFlow: 'column',
					p: 1.5,
					borderRadius: '8px',
					border:
						colorScheme === 'dark' ? 'none' : `1px solid ${palette.divider}`,
					background: palette.background.surface,
				}}
			>
				<Box
					sx={{
						display: 'flex',
					}}
				>
					<Pop>
						<Box
							onClick={() => {
								navigate('/');
							}}
							sx={{
								p: 1,
								paddingBottom: 0.25,
								paddingTop: 1.25,
								':hover': {
									background: palette.background.level2,
								},
								':active': {
									background: palette.background.level3,
								},
								cursor: 'pointer',
								borderRadius: '8px',
							}}
						>
							<Logo height={22} />
						</Box>
					</Pop>
				</Box>
				<Box p={1} />
				<Stack direction="column" gap={1}>
					{Object.entries(nav).map(([id, val]) => (
						<SideSection
							key={`nav_${id}`}
							nav={val}
							open={open}
							focused={focused}
							setFocused={setFocused}
							id={id}
						/>
					))}
				</Stack>
				<Box sx={{ flex: 1 }} />
				<Box
					sx={{
						display: 'flex',
						flexFlow: 'column',
						gap: 1,
					}}
				>
					<SideButton
						icon={isDarkMode ? <MdOutlineLightMode /> : <MdOutlineDarkMode />}
						label={isDarkMode ? 'Light Mode' : 'Dark Mode'}
						onClick={() => {
							setMode(isDarkMode ? 'light' : 'dark');
						}}
						open={open}
					/>
					<SideButton
						onClick={() => {
							setKeepOpen(!keepOpen);
						}}
						icon={
							<motion.div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
								variants={{
									closed: { rotate: 0 },
									open: { rotate: 180 },
								}}
								initial={keepOpen ? 'open' : 'closed'}
								animate={keepOpen ? 'open' : 'closed'}
							>
								<MdChevronRight />
							</motion.div>
						}
						label={keepOpen ? 'Collapse' : 'Keep Open'}
						open={open}
					/>
					<SideButton
						onClick={() => {
							navigate('/signout');
						}}
						icon={<MdOutlineExitToApp />}
						label="Sign Out"
						open={open}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default Sidebar;
