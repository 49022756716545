import { getQueryHook } from '@hooks/graphql';
import * as Ops from './EvaluationSummary.Operations';

const evaluationSummaryResolver = {
	get: getQueryHook<
		Ops.EvaluationSummaryQueryRes,
		Ops.EvaluationSummaryQueryVars
	>(Ops.EvaluationSummaryQuery),
	extractQuotes: getQueryHook<
		Ops.ExtractQuotesQueryRes,
		Ops.ExtractQuotesQueryVars
	>(Ops.ExtractQuotesQuery),
};

export default evaluationSummaryResolver;
