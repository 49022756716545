import CopyButton from '@components/Input/CopyButton';
import ResponsiveDialog from '@components/Layout/ResponsiveDialog';
import { useError } from '@hooks/useError';
import { useSuccess } from '@hooks/useSuccess';
import {
	Box,
	Button,
	ButtonGroup,
	FormControl,
	FormLabel,
	Input,
	Tooltip,
	Typography,
} from '@mui/joy';
import { UserQuery, UsersQuery } from '@schema/User/User.Operations';
import schema from '@schema/index';
import { generatePassword } from '@utils/generatePassword';
import React, { FC, useState } from 'react';

export interface PasswordFormProps {
	children: (provided: { onClick: () => void }) => React.ReactNode;
}

const PasswordForm: FC<PasswordFormProps> = ({ children }) => {
	const [open, setOpen] = useState(false);
	const onError = useError();
	const onSuccess = useSuccess();

	const [password, setPassword] = useState('');

	const onClose = () => {
		setPassword('');
		setOpen(false);
	};

	const [handleUpdate, { loading }] = schema.user.updateSelf({
		variables: {
			data: {
				password,
			},
		},
		onError,
		onCompleted: () => {
			onClose();
			onSuccess('Password updated');
		},
		refetchQueries: [UsersQuery, UserQuery],
	});

	const getHoldup = () => {
		if (!password) return 'Please enter a password';
		else if (password.length < 8)
			return 'Password must be at least 8 characters';
		else return null;
	};

	const holdup = getHoldup();
	const isHeldup = !!holdup;

	return (
		<>
			{children({
				onClick: () => setOpen(true),
			})}
			<ResponsiveDialog open={open} onClose={onClose}>
				<Box
					sx={{
						display: 'flex',
						flexFlow: 'column',
						maxWidth: '420px',
					}}
				>
					<Typography level="h4">{`Reset Password`}</Typography>
					<Box p={1.5} />
					<FormControl>
						<FormLabel>New Password</FormLabel>
						<Input
							placeholder="Password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							size="lg"
						/>
					</FormControl>
					<Box p={1.5} />
					<Tooltip arrow title={holdup || ''}>
						<Box>
							<Button
								variant="solid"
								color="primary"
								size="lg"
								disabled={isHeldup}
								onClick={() => handleUpdate()}
								loading={loading}
								fullWidth
							>
								Reset Password
							</Button>
						</Box>
					</Tooltip>
				</Box>
			</ResponsiveDialog>
		</>
	);
};

export default PasswordForm;
