import Loader from '@components/Display/Loader';
import { useError } from '@hooks/useError';
import {
	Box,
	Divider,
	Drawer,
	Skeleton,
	Stack,
	Typography,
	useTheme,
} from '@mui/joy';
import { EvaluationFilter } from '@schema/Evaluation/EvaluationFilter';
import {
	EvaluationProjectionCluster,
	EvaluationProjectionClusterTagged,
} from '@schema/EvaluationProjectionCluster/EvaluationProjectionCluster';
import schema from '@schema/index';
import React, { FC, useEffect, useState } from 'react';
import { BiSolidQuoteRight } from 'react-icons/bi';

export interface TopicDetailProps {
	cluster: EvaluationProjectionCluster | null;
	onClose: () => void;
	granularity: number;
	filter: EvaluationFilter;
	mode: string;
}

const TopicDetail: FC<TopicDetailProps> = ({
	cluster,
	onClose,
	granularity,
	filter,
	mode,
}) => {
	const onError = useError();
	const { palette } = useTheme();

	const [tagged, setTagged] =
		useState<EvaluationProjectionClusterTagged | null>(null);

	const { data, loading } = schema.evaluationSummary.extractQuotes({
		variables: {
			filter: {
				ids: tagged?.ids ?? [],
			},
			sentiment: tagged?.tag ?? '',
		},
		skip: !tagged,
	});

	const [getTag, { loading: tagLoading }] =
		schema.evaluationProjectionCluster.tagged({
			variables: !cluster
				? undefined
				: {
						x: cluster.coordinate.x,
						y: cluster.coordinate.y,
						granularity,
						filter,
						mode,
				  },
			onCompleted: (data) => {
				setTagged(data.EvaluationProjectionCluster);
			},
			onError,
		});

	useEffect(() => {
		if (cluster) {
			getTag({
				variables: {
					x: cluster.coordinate.x,
					y: cluster.coordinate.y,
					granularity,
					filter,
					mode,
				},
			});
		}
	}, [cluster]);

	const onCloseDrawer = () => {
		setTagged(null);
		onClose();
	};

	return (
		<Drawer anchor="right" open={Boolean(cluster)} onClose={onCloseDrawer}>
			<Stack direction="column" sx={{ height: '100%' }}>
				<Box sx={{ p: 2, background: palette.background.level1 }}>
					<Typography
						color={(cluster?.score ?? 0) > 50 ? 'success' : undefined}
						sx={{
							fontWeight: 'bold',
							fontStyle: 'italic',
						}}
					>
						Insight
					</Typography>
					<Box p={0.5} />
					<Typography level="h4">{tagged?.tag}</Typography>
				</Box>
				<Divider />
				<Box
					sx={{
						flex: 1,
						overflowY: 'auto',
						p: 2,
						position: 'relative',
					}}
				>
					<Loader loading={tagLoading || loading} />
					<Typography
						sx={{
							fontWeight: 'bold',
							fontStyle: 'italic',
						}}
					>
						Examples of this insight
					</Typography>
					{loading ? (
						<Box
							sx={{
								display: 'flex',
								flexFlow: 'column',
								gap: 4,
								paddingTop: 4,
							}}
						>
							{Array.from({ length: 5 }).map((_, i) => (
								<Typography key={`insightLoader_${i}`}>
									<Skeleton>
										<Typography>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit.
											Nulla nec dui nec nunc aliquam tincidunt nec nec nunc.
											Nulla nec dui nec nunc aliquam tincidunt nec nec nunc.
										</Typography>
									</Skeleton>
								</Typography>
							))}
						</Box>
					) : (
						<Box
							sx={{
								display: 'flex',
								flexFlow: 'column',
								gap: 4,
								paddingTop: 4,
							}}
						>
							{data?.ExtractQuotes.map((quote, i) => (
								<Box key={`quote_${i}`} sx={{ display: 'flex', gap: 2 }}>
									<Box>
										<BiSolidQuoteRight
											style={{
												fontSize: '1.5rem',
												color: palette.background.level2,
											}}
										/>
									</Box>
									<Typography>{quote}</Typography>
								</Box>
							))}
						</Box>
					)}
				</Box>
			</Stack>
		</Drawer>
	);
};

export default TopicDetail;
