import useDebouncedState from '@hooks/useDebounce';
import { Slider, SliderProps } from '@mui/joy';
import React, { FC, useEffect, useState } from 'react';

export interface DebounceSliderProps
	extends Omit<SliderProps, 'value' | 'onChange'> {
	value: SliderProps['value'];
	onChange: (value: SliderProps['value']) => void;
}

const threshold = 300;

const DebounceSlider: FC<DebounceSliderProps> = ({
	value,
	onChange,
	...props
}) => {
	const [inputState, setInputState] = useState(value);

	useEffect(() => {
		const timer = setTimeout(() => {
			onChange(inputState);
		}, threshold);

		return () => clearTimeout(timer);
	}, [inputState]);

	return (
		<Slider
			value={inputState}
			onChange={(e, v) => setInputState(v)}
			{...props}
		/>
	);
};

export default DebounceSlider;
