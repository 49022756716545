import { useAuthentication } from '@providers/AuthenticationProvider';
import Loading from '@scenes/Root/scenes/Loading';
import Problem from '@scenes/Root/scenes/Problem';
import React, { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

const Unauthenticated: FC<PropsWithChildren> = ({ children }) => {
	const { user, loading, error } = useAuthentication();
	if (loading) return <Loading />;
	else if (error) return <Problem error={error} />;
	else if (user) return <Navigate to="/app" />;
	return <>{children}</>;
};

export default Unauthenticated;
