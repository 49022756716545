import { useAuthentication } from '@providers/AuthenticationProvider';
import { useClaimsContext } from '@providers/ClaimsProvider';
import Loading from '@scenes/Root/scenes/Loading';
import Problem from '@scenes/Root/scenes/Problem';
import React, { FC, PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const Authenticated: FC<PropsWithChildren> = ({ children }) => {
	const { pathname } = useLocation();
	const { user, loading, error } = useAuthentication();
	const claims = useClaimsContext();
	if (loading) return <Loading />;
	else if (error) return <Problem error={error} />;
	else if (!user) return <Navigate to="/signin" state={{ from: pathname }} />;
	else if (claims.loading) return <Loading />;
	else if (claims.error) return <Problem error={claims.error} />;
	else if (!claims.claims) return <Problem error="No claims" />;
	else return <>{children}</>;
};

export default Authenticated;
