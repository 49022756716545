import { gql } from '@apollo/client';
import { Admin } from './Admin';

export const AdminQuery = gql`
	query AdminQuery {
		Admin {
			cache {
				attributes {
					key
					value
				}
			}
			database {
				attributes {
					key
					value
				}
			}
		}
	}
`;

export type AdminQueryRes = {
	Admin: Admin;
};

export type AdminQueryVars = {};

export const AdminCacheClear = gql`
	mutation AdminCacheClear {
		AdminCacheClear
	}
`;

export type AdminCacheClearMutationRes = {
	AdminCacheClear: boolean;
};

export type AdminCacheClearMutationVars = {};
