import { gql } from '@apollo/client';
import { Insight } from '@schema/Insight/Insight';

export class EvaluationSummary {
	positive!: Insight[];
	negative!: Insight[];
}

export const EvaluationSummaryFragment = gql`
	fragment EvaluationSummaryFragment on EvaluationSummary {
		positive {
			...InsightFragment
		}
		negative {
			...InsightFragment
		}
	}
`;
