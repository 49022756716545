import { Claims } from '@auth/Claims';
import { Role } from '@auth/Role';
import { useAuthentication } from '@providers/AuthenticationProvider';
import React, {
	FC,
	PropsWithChildren,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';

export interface ClaimsContext {
	claims: Claims | null;
	loading: boolean;
	error?: Error | null;
}

const Context = createContext<ClaimsContext>({
	claims: null,
	loading: false,
	error: null,
});

const ClaimsProvider: FC<PropsWithChildren> = ({ children }) => {
	const { user } = useAuthentication();

	const [claims, setClaims] = useState<Claims | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<Error | null>(null);

	const [defLoading, setDefLoading] = useState<boolean>(true);

	// lag the loading state to prevent flickering
	useEffect(() => {
		if (defLoading && !loading) {
			const timeout = setTimeout(() => {
				setDefLoading(false);
			}, 500);

			return () => clearTimeout(timeout);
		} else if (loading && !defLoading) {
			setDefLoading(true);
		}
	}, [loading, defLoading]);

	useEffect(() => {
		if (user) {
			user
				.getIdTokenResult()
				.then((result) => {
					const defaultClaims: Claims = {
						role: Role.User,
					};

					const fromToken = result.claims;

					Object.assign(defaultClaims, fromToken);

					setClaims(defaultClaims);
					setLoading(false);
					setError(null);
				})
				.catch((error) => {
					setClaims(null);
					setLoading(false);
					setError(error);
				});
		} else {
			setClaims(null);
			setLoading(false);
		}
	}, [user]);

	return (
		<Context.Provider value={{ claims, loading: defLoading, error }}>
			{children}
		</Context.Provider>
	);
};

export default ClaimsProvider;

export const useClaimsContext = () => useContext(Context);
