import {
	useQuery,
	OperationVariables,
	QueryHookOptions,
	LazyQueryHookOptions,
	MutationHookOptions,
	useMutation,
	useLazyQuery,
} from '@apollo/client';
import { DocumentNode } from 'graphql';

export const getQueryHook =
	<Result, Variables extends OperationVariables = {}>(query: DocumentNode) =>
	(options?: QueryHookOptions<Result, Variables>) => {
		return useQuery<Result, Variables>(query, options);
	};

export const getLazyQueryHook =
	<Result, Variables extends OperationVariables = {}>(query: DocumentNode) =>
	(options?: LazyQueryHookOptions<Result, Variables>) => {
		return useLazyQuery<Result, Variables>(query, options);
	};

export const getMutationHook =
	<Result, Variables extends OperationVariables = {}>(mutation: DocumentNode) =>
	(options?: MutationHookOptions<Result, Variables>) => {
		return useMutation<Result, Variables>(mutation, options);
	};
