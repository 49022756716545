import { EvaluationFilter } from "@schema/Evaluation/EvaluationFilter";
import schema from "@schema/index";
import { FC } from "react";
import { Box, CircularProgress } from "@mui/joy";
import QuestionCard from "../QuestionCard";
import { useError } from "@hooks/useError";
import NoData from "@components/Display/NoData";

export interface QuestionsProps {
  filter: EvaluationFilter;
}

const Questions: FC<QuestionsProps> = ({ filter }) => {
  const onError = useError();
  const { data, error, loading } = schema.commentQuestions.list({
    variables: { filter },
    onError,
  });

  if (loading) return <CircularProgress />;

  return (
    <Box>
      {data?.CommentQuestions.responses.length === 0 ? (
        <NoData />
      ) : (
        data?.CommentQuestions.responses.map((response, index) => (
          <QuestionCard key={index} filter={filter} response={response} />
        ))
      )}
    </Box>
  );
};

export default Questions;
