import { hasRole } from '@auth/hasRole';
import { useClaimsContext } from '@providers/ClaimsProvider';
import { Nav } from '@scenes/Root/scenes/App/navigation/Nav';
import React, { FC } from 'react';
import SideButton from '../SideButton';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Stack, useTheme } from '@mui/joy';
import { MdExpandMore } from 'react-icons/md';
import { motion } from 'framer-motion';

export interface SideSectionProps {
	nav: Nav;
	open: boolean;
	focused: string;
	id: string;
	setFocused: (active: string) => void;
}

const useNav = (nav: Nav): Nav | null => {
	const { claims } = useClaimsContext();

	if (!claims) return null;
	else {
		if (nav.role) {
			const isValid = hasRole(nav.role, claims);
			if (!isValid) return null;
			else {
				// the role is valid
				if (typeof nav.element === 'string') return nav;
				else {
					const children = [];
					for (const element of nav.element) {
						if (element.role) {
							const isValid = hasRole(element.role, claims);
							if (isValid) children.push(element);
						} else {
							children.push(element);
						}
					}
					if (children.length === 0) return null;
					else if (children.length === 1)
						return {
							...nav,
							element: children[0].path,
							label: children[0].label,
						};
					else return { ...nav, element: children };
				}
			}
		} else {
			if (typeof nav.element === 'string') return nav;
			else {
				const children = [];
				for (const element of nav.element) {
					if (element.role) {
						const isValid = hasRole(element.role, claims);
						if (isValid) children.push(element);
					} else {
						children.push(element);
					}
				}
				if (children.length === 0) return null;
				else if (children.length === 1)
					return {
						...nav,
						element: children[0].path,
						label: children[0].label,
					};
				else return { ...nav, element: children };
			}
		}
	}
};

const SideSection: FC<SideSectionProps> = ({
	nav: input,
	open,
	focused,
	id,
	setFocused,
}) => {
	const nav = useNav(input);
	const handleNavigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const { pathname } = useLocation();
	const { palette } = useTheme();

	if (!nav) return <></>;

	const navigate = (path: string) => {
		// if the path includes "dashboard", keep the search params
		if (path.includes('dashboard')) {
			if (pathname.includes('dashboard')) {
				const newView = path.split('/')[3];
				const search = searchParams.toString();
				const curentParts = pathname.split('/');
				// /app/dashboard/:view/:anchorStart/:anchorEnd/:u1/:u2/:u3/:u4/:u5/:u6/:u7/:u8/:u9/:u10?search
				const view = curentParts[3];
				const [anchorStart, anchorEnd] = curentParts.slice(4, 6);
				const units = curentParts.slice(6);

				const newPath = `/app/dashboard/${newView}/${anchorStart}/${anchorEnd}/${units.join(
					'/'
				)}`;
				handleNavigate({ pathname: newPath, search });
			} else {
				const search = searchParams.toString();
				handleNavigate({ pathname: path, search });
			}
		} else {
			handleNavigate(path);
		}
	};

	if (typeof nav.element === 'string')
		return (
			<SideButton
				active={
					nav.element.includes(pathname) || pathname.includes(nav.element)
				}
				onClick={() => {
					navigate(nav.element as string);
					setFocused('');
				}}
				open={open}
				label={nav.label}
				icon={nav.icon}
			/>
		);

	return (
		<Box>
			<SideButton
				active={nav.element.some((element) => pathname.includes(element.path))}
				onClick={() => {
					if (focused === id) setFocused('');
					else setFocused(id);
				}}
				open={open}
				label={nav.label}
				icon={nav.icon}
				endDecorator={
					<motion.div
						variants={decoratorVariants}
						initial={focused === id ? 'open' : 'closed'}
						animate={focused === id ? 'open' : 'closed'}
						transition={{ duration: 0.2 }}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<MdExpandMore />
					</motion.div>
				}
			/>

			<motion.div
				variants={collapseVariants}
				initial={focused === id && open ? 'open' : 'closed'}
				animate={focused === id && open ? 'open' : 'closed'}
				transition={{ duration: 0.2 }}
				style={{
					overflow: 'hidden',
					display: 'flex',
					alignItems: 'stretch',
				}}
			>
				<Box
					sx={{
						flex: 1,
						display: 'flex',
						p: 1,
						paddingRight: 0,
					}}
				>
					<Box
						sx={{
							flex: 1,
							maxWidth: '32px',
							display: 'flex',
							flexFlow: 'column',
						}}
					>
						<Box sx={{ flex: 1, display: 'flex', flexFlow: 'column' }}>
							<Box
								sx={{
									flex: 1,
									width: '3px',
									borderRadius: '1.5px',
									backgroundColor: palette.background.level2,
									marginLeft: '8px',
								}}
							/>
						</Box>
					</Box>
					<Box sx={{ flex: 1 }}>
						<Stack direction="column" gap={1}>
							{nav.element.map((element, index) => {
								return (
									<SideButton
										key={`nav_${index}`}
										active={pathname.includes(element.path)}
										onClick={() => {
											navigate(element.path);
										}}
										open={open}
										label={element.label}
									/>
								);
							})}
						</Stack>
					</Box>
				</Box>
			</motion.div>
		</Box>
	);
};

export default SideSection;

const decoratorVariants = {
	open: { rotate: 0 },
	closed: { rotate: 180 },
};

const collapseVariants = {
	open: { height: 'auto' },
	closed: { height: 0 },
};
