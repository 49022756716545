import { EvaluationFilter } from '@schema/Evaluation/EvaluationFilter';
import React, { FC, ReactNode, useState } from 'react';
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	PDFViewer,
} from '@react-pdf/renderer';
import RepsonsiveDialog from '@components/Layout/ResponsiveDialog';
import { Box, useTheme } from '@mui/joy';
import schema from '@schema/index';
import { useError } from '@hooks/useError';
import { useNetScores } from '../NetScores';
import { MdArrowForward } from 'react-icons/md';
import { useAnchorContext } from '@providers/AnchorProvider';

// import { Font } from '@react-pdf/renderer';

// Font.register({
// 	family: 'Montserrat',
// 	src: 'https://fonts.googleapis.com/css?family=Montserrat',
// 	fontStyle: 'normal',
// 	fontWeight: 'normal',
// 	fonts: [],
// });

export interface EvaluationReportProps {
	filter: EvaluationFilter;
	children: (provided: {
		onClick: () => void;
		loading: boolean;
		error: Error | undefined | null;
	}) => ReactNode;
}

const EvaluationReport: FC<EvaluationReportProps> = ({ children, filter }) => {
	const [open, setOpen] = useState(false);
	const onError = useError();
	const { palette, typography } = useTheme();

	const { value: anchors } = useAnchorContext();

	const getAnchorLabel = () => {
		const parse = (val: number) => {
			const year = Math.floor(val / 10);
			const semesterIndex = val % 10;
			const semesters = ['Spring', 'Summer', 'Fall'];
			const res = `${semesters[semesterIndex]} ${year}`;
			return res;
		};

		const start = parse(anchors.min);
		const end = parse(anchors.max);

		if (start === end) return start;
		else return `${start} - ${end}`;
	};

	const anchorLabel = getAnchorLabel();

	// display: 'Montserrat, sans-serif',
	// body: 'Source Sans, sans-serif',

	// Create styles
	const styles = StyleSheet.create({
		page: {
			flexDirection: 'row',
			backgroundColor: '#E4E4E4',
		},
		section: {
			margin: 10,
			padding: 20,
			flexGrow: 1,
		},
		typography_h1: {
			fontSize: '32px',
			// fontFamily: 'Montserrat',
		},
		typography_h2: {
			fontSize: '24px',
			// fontFamily: 'Montserrat',
		},
		typography_h3: {
			fontSize: '18px',
			// fontFamily: 'Montserrat',
		},
		green: {
			color: palette.success[500],
		},
		typography_body: {
			fontSize: 12,
			lineHeight: 1,
		},
		bullet: {},
		break: {
			borderBottom: `1px solid ${palette.neutral[400]}`,
		},
		space: {
			height: 10,
		},
	});

	const onClose = () => {
		setOpen(false);
	};

	const {
		data: unitData,
		loading: unitLoadinng,
		error: unitError,
	} = schema.unit.list({
		variables: {
			filter: {
				ids: filter.ancestry,
			},
		},
	});

	const {
		data: summaryData,
		loading: summaryLoading,
		error: summaryError,
	} = schema.evaluationSummary.get({
		variables: { filter },
	});

	const {
		data: unitScoreData,
		error: unitScoreError,
		loading: unitScoreLoading,
	} = useNetScores(filter);

	const {
		data: parentScoreData,
		error: parentScoreError,
		loading: parentScoreLoading,
	} = useNetScores({
		...filter,
		ancestry: filter.ancestry?.slice(0, -1),
	});

	const loading =
		unitLoadinng || summaryLoading || unitScoreLoading || parentScoreLoading;

	const error = unitScoreError || parentScoreError || unitError || summaryError;

	const focusedUnit = filter.ancestry
		? unitData?.List.records.find(
				(u) => u._id === filter.ancestry![filter.ancestry!.length - 1]
		  )
		: null;

	return (
		<>
			{children({ onClick: () => setOpen(!open), loading, error })}

			<RepsonsiveDialog open={open} onClose={onClose}>
				<Box
					sx={{
						height: ' 90vh',
						width: `${90 * (8.5 / 11)}vh`,
						border: '1px solid green',
					}}
				>
					<PDFViewer width="100%" height="100%">
						<Document
						//  style?: Style | Style[];
						//  title?: string;
						//  author?: string;
						//  subject?: string;
						//  creator?: string;
						//  keywords?: string;
						//  producer?: string;
						//  language?: string;
						//  creationDate?: Date;
						//  modificationDate?: Date;
						//  pdfVersion?: PDFVersion;
						//  pageMode?: PageMode;
						//  pageLayout?: PageLayout;
						//  onRender?: (props: OnRenderProps) => any;
						>
							<Page size="LETTER" style={styles.page}>
								<View style={styles.section}>
									{focusedUnit && (
										<Text style={styles.typography_h1}>{focusedUnit.name}</Text>
									)}
									<View style={styles.space} />
									<Text style={{ ...styles.typography_body }}>
										{anchorLabel}
									</Text>
									<View style={styles.space} />
									<View style={styles.break} />
									<View style={styles.space} />
									<Text style={{ ...styles.typography_h3, ...styles.green }}>
										What's working well
									</Text>
									<View style={styles.space} />
									{summaryData?.EvaluationSummary?.positive.map(
										(insight, i) => (
											<View key={`positive_${i}`} style={styles.bullet}>
												<Text style={styles.typography_body}>
													{insight.value}
												</Text>
												<View style={styles.space} />
											</View>
										)
									)}
									<View style={styles.space} />
									<View style={styles.space} />
									<Text style={styles.typography_h3}>
										Could use improvement
									</Text>
									<View style={styles.space} />
									{summaryData?.EvaluationSummary?.negative.map(
										(insight, i) => (
											<View key={`negative_${i}`} style={styles.bullet}>
												<Text style={styles.typography_body}>
													{insight.value}
												</Text>
												<View style={styles.space} />
											</View>
										)
									)}
								</View>
							</Page>
						</Document>
					</PDFViewer>
				</Box>
			</RepsonsiveDialog>
		</>
	);
};

export default EvaluationReport;
