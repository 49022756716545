import { Card, CardProps, Typography, useTheme } from '@mui/joy';
import React, { FC, PropsWithChildren } from 'react';
import { SlLayers } from 'react-icons/sl';

export interface NoDataProps {
	sx?: CardProps['sx'];
}

const NoData: FC<PropsWithChildren<NoDataProps>> = ({ children, sx = {} }) => {
	const { palette } = useTheme();
	return (
		<Card
			variant="soft"
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				p: 6,
				...sx,
			}}
		>
			<SlLayers style={{ fontSize: '1.5rem', color: palette.text.icon }} />
			{children || (
				<Typography
					sx={{
						color: palette.text.icon,
					}}
				>
					No Data
				</Typography>
			)}
		</Card>
	);
};

export default NoData;
