import Pop from '@components/Animation/Pop';
import CenterFlex from '@components/Layout/CenterFlex';
import { Box, Button, Typography } from '@mui/joy';
import Loading from '@scenes/Root/scenes/Loading';
import Problem from '@scenes/Root/scenes/Problem';
import schema from '@schema/index';
import React, { FC } from 'react';
import { MdArrowForward } from 'react-icons/md';
import { Navigate, useNavigate } from 'react-router-dom';

const DashboardRoot: FC = () => {
	const nav = useNavigate();

	const { data, loading } = schema.unit.roots({});

	const roots = data?.Units ?? [];

	if (loading) return <Loading />;
	else if (roots.length === 0)
		return <Problem error="You do not have access to any academic units." />;
	else if (roots.length === 1)
		return <Navigate to={roots[0]._id} replace={true} />;
	else
		return (
			<CenterFlex maxWidth={700}>
				<Box sx={{ p: 4 }}>
					<Typography level="h3">Welcome!</Typography>
					<Typography>Select a Unit to get started.</Typography>
					<Box p={1.5} />
					<Box
						sx={{
							display: 'flex',
							flexFlow: 'column',
							gap: 3,
						}}
					>
						{roots.map((root) => (
							<Pop scale={1.01}>
								<Button
									endDecorator={<MdArrowForward />}
									fullWidth
									key={`root_${root._id}`}
									onClick={() => nav(root._id)}
									size="lg"
									sx={{
										justifyContent: 'space-between',
										textAlign: 'left',
									}}
								>
									<Box>
										<Typography level="h4">{root.name}</Typography>
										<Typography level="body-sm">{root.type}</Typography>
									</Box>
								</Button>
							</Pop>
						))}
					</Box>
				</Box>
			</CenterFlex>
		);
};

export default DashboardRoot;
