import { Box, Button, Skeleton, Typography, useTheme } from '@mui/joy';
import React, {
	FC,
	PropsWithChildren,
	ReactNode,
	useEffect,
	useRef,
	useState,
} from 'react';
import CenterFlex from '../CenterFlex';
import Loader from '@components/Display/Loader';
import { MdArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

export interface BasePageProps {
	back?: { path: string; label: string };
	loading?: boolean;
	action?: ReactNode;
	title: string | null;
	maxWidth?: number;
}

const BasePage: FC<PropsWithChildren<BasePageProps>> = ({
	loading = false,
	action,
	title,
	children,
	maxWidth = 1100,
	back,
}) => {
	const { palette } = useTheme();
	const nav = useNavigate();
	const ref = useRef<HTMLDivElement>(null);
	const [scrolled, setScrolled] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (ref.current) {
				setScrolled(ref.current.scrollTop > 0);
			}
		};

		if (ref.current) {
			ref.current.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (ref.current) {
				ref.current.removeEventListener('scroll', handleScroll);
			}
		};
	}, [ref]);

	return (
		<Box
			sx={{
				height: '100%',
				display: 'flex',
				flexFlow: 'column',
				overflow: 'hidden',
			}}
		>
			<Box>
				<CenterFlex maxWidth={maxWidth}>
					{back && (
						<Box sx={{ paddingTop: 1 }}>
							<Button
								onClick={() => nav(back.path)}
								startDecorator={<MdArrowBack />}
								size="sm"
								variant="plain"
							>
								{back.label}
							</Button>
						</Box>
					)}
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							padding: 2,
							borderBottom: `1px solid ${palette.divider}`,
							paddingTop: back ? 1 : 2,
						}}
					>
						<Typography level="h3">
							<Skeleton loading={!title || title === 'Loading'}>
								{title || 'Loading...'}
							</Skeleton>
						</Typography>
						{action}
					</Box>
				</CenterFlex>
			</Box>
			<Box sx={{ flex: 1, overflowY: 'auto' }}>
				<Box sx={{}}>
					<CenterFlex maxWidth={maxWidth}>
						<Box
							sx={{
								position: 'relative',
								padding: 2,
								paddingTop: 0,
								paddingBottom: 12,
							}}
						>
							<Loader loading={loading} />
							<Box p={2} />
							{children}
						</Box>
					</CenterFlex>
				</Box>
			</Box>
		</Box>
	);
};

export default BasePage;
