import { useError } from '@hooks/useError';
import { EvaluationFilter } from '@schema/Evaluation/EvaluationFilter';
import { ResponseSummary } from '@schema/ResponseSummary/ResponseSummary';
import schema from '@schema/index';
import React, { FC, useEffect, useState } from 'react';
import { useSection } from '../Seciton';
import { EvaluationType } from '@schema/Evaluation/EvaluationType';
import { frameworkKeys } from '@schema/Evaluation/framework';
import { QuantitySummariesQueryRes } from '@schema/QuantitySummary/QuantitySummary.Operations';
import { useStoredState } from '@utils/useStoredState';
import { Box, Select, Option, Grid } from '@mui/joy';
import { splitByCapital } from '@utils/splitByCapital';
import Benchmark from './components/Benchmarks';

export interface BenchmarksProps {
	filter: EvaluationFilter;
}

const Benchmarks: FC<BenchmarksProps> = ({ filter }) => {
	const { benchmarks, loading } = useBenchmarks(filter);

	useSection(loading);

	const [focused, setFocused] = useStoredState('BenchmarkFocus', '');

	const bechmarkSelection = benchmarks[focused] ?? [];

	useEffect(() => {
		const firstKey = Object.keys(benchmarks)[0];
		if (firstKey !== focused) setFocused(firstKey);
	}, [Object.keys(benchmarks).length]);

	return (
		<Box>
			<Box sx={{ display: 'flex' }}>
				<Select
					value={focused}
					onChange={(e, val) => {
						if (val) setFocused(val);
					}}
				>
					{Object.keys(benchmarks).map((key) => (
						<Option key={key} value={key}>
							{splitByCapital(key)}
						</Option>
					))}
				</Select>
			</Box>
			<Box p={1.5} />
			<Grid container spacing={6}>
				{bechmarkSelection.map((b, i) => {
					return (
						<Benchmark loading={loading} key={`benchmark_${i}`}>
							{b}
						</Benchmark>
					);
				})}
			</Grid>
		</Box>
	);
};

export default Benchmarks;

type BenchmarkData = Record<string, ResponseSummary[]>;

const useBenchmarks = (
	filter: EvaluationFilter
): { benchmarks: BenchmarkData; loading: boolean } => {
	const onError = useError();

	const lastAncestor =
		!filter.ancestry || filter.ancestry.length === 0
			? ''
			: filter.ancestry[filter.ancestry.length - 1];

	const keys = !filter.type ? null : evalKeys[filter.type];

	const [benchmarks, setBenchmarks] = useState<BenchmarkData>(
		getBenchmarks(null, filter.type)
	);

	const { loading } = schema.quantitySummary.get({
		variables: {
			unitFilter: { ids: [lastAncestor] },
			evaluationFilter: filter,
			keys,
		},
		onError,
		skip: !lastAncestor || !keys,
		onCompleted: (data) => {
			setBenchmarks(getBenchmarks(data, filter.type));
		},
	});

	return { benchmarks, loading };
};

// const groupKeys: Record<EvaluationType, string[]> = {
// 	[EvaluationType.InstructorReflection]: Object.keys(frameworkKeys),
// 	[EvaluationType.PeerReview]: Object.keys(frameworkKeys),
// 	[EvaluationType.StudentCourseFeedback]: ['StudentCourseFeedback'],
// };

const scfKys = [
	'UnderstoodGrades20210101',
	'RecommendCourse20210101',
	'RecommendInstructor20210101',
	'ComfortableAskingQuestions20210101',
];

const frameKeys = Object.keys(frameworkKeys)
	.map((key) => frameworkKeys[key].map((k) => `${key}_${k}`))
	.flat();

const evalKeys: Record<EvaluationType, string[]> = {
	[EvaluationType.InstructorReflection]: frameKeys,
	[EvaluationType.PeerReview]: frameKeys,
	[EvaluationType.StudentCourseFeedback]: scfKys,
};

const getBenchmarks = (
	data: QuantitySummariesQueryRes | undefined | null,
	type: EvaluationType | undefined | null
): BenchmarkData => {
	const emptyScf: BenchmarkData = {
		StudentCourseFeedback: scfKys.map((k) => ({ prompt: k, responses: [] })),
	};

	const emptyFramework: BenchmarkData = Object.fromEntries(
		Object.keys(frameworkKeys).map((key) => [
			key,
			frameworkKeys[key].map((k) => ({ prompt: `${key}_${k}`, responses: [] })),
		])
	);

	switch (type) {
		case null:
		case undefined:
			return emptyScf;
		case EvaluationType.StudentCourseFeedback: {
			if (!data || !data.QuantitySummaries.length) return emptyScf;
			else {
				return {
					StudentCourseFeedback: data.QuantitySummaries[0].responses,
				};
			}
		}
		default:
			if (!data || !data.QuantitySummaries.length) {
				return emptyFramework;
			} else {
				const res: BenchmarkData = {};

				for (const response of data.QuantitySummaries[0].responses) {
					const key = response.prompt.split('_')[0];

					if (!res[key]) res[key] = [];

					res[key].push(response);
				}

				return res;
			}
	}
};
