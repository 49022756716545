import { setContext } from '@apollo/client/link/context';
import Loader from '@components/Display/Loader';
import Collapse from '@components/Layout/Collapse';
import { Box, CircularProgress, Typography, useTheme } from '@mui/joy';
import { useStoredState } from '@utils/useStoredState';
import { lab } from 'd3';
import { motion } from 'framer-motion';
import React, {
	FC,
	PropsWithChildren,
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import { MdExpand, MdExpandMore } from 'react-icons/md';

export interface SectionProps {
	id: string;
	label: string | [key: string, map: Record<string, string>];
	labelDecorator?: ReactNode;
	minHeight?: number;
}

const Context = createContext({
	setLoading: (loading: boolean) => {},
	loading: false,
	open: false,
	setOpen: (open: boolean) => {},
});

const Section: FC<PropsWithChildren<SectionProps>> = ({
	id,
	children,
	label,
	labelDecorator,
	minHeight,
}) => {
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useStoredState<boolean>(`section-${id}`, true);
	const { palette } = useTheme();

	const title = typeof label === 'string' ? label : label[1][label[0]];

	return (
		<Context.Provider
			value={{
				open,
				setOpen,
				loading,
				setLoading,
			}}
		>
			<Box>
				<Box
					onClick={() => setOpen(!open)}
					sx={{
						borderRadius: '8px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						p: 1,
						paddingLeft: 1.5,
						paddingRight: 1.5,
						cursor: 'pointer',
						':hover': {
							background: palette.background.level1,
						},
						':focus': {
							background: palette.background.level2,
						},
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
						<Typography level="h4" endDecorator={labelDecorator}>
							{title}
						</Typography>
						{!open && (
							<motion.div
								variants={{
									loading: {
										opacity: 1,
									},
									idle: {
										opacity: 0,
									},
								}}
								animate={loading ? 'loading' : 'idle'}
								initial={loading ? 'loading' : 'idle'}
								transition={{ duration: 0.2 }}
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<CircularProgress
									sx={{
										'--CircularProgress-size': '18px',
										'--CircularProgress-trackThickness': '3px',
										'--CircularProgress-progressThickness': '3px',
									}}
									color="neutral"
								/>
							</motion.div>
						)}
					</Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<motion.div
							variants={{
								open: { rotate: 0 },
								closed: { rotate: 180 },
							}}
							animate={open ? 'open' : 'closed'}
							initial={open ? 'open' : 'closed'}
							transition={{ duration: 0.2 }}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<MdExpandMore style={{ fontSize: '1.5rem' }} />
						</motion.div>
					</Box>
				</Box>
				<Collapse open={open}>
					<Box
						sx={{
							p: 1.5,
							paddingBottom: 8,
							minHeight: minHeight ? `${minHeight}px` : 'auto',
							position: 'relative',
						}}
					>
						<Loader loading={loading} />
						{children}
					</Box>
				</Collapse>
			</Box>
		</Context.Provider>
	);
};

export default Section;

export const useSection = (watch: any) => {
	const { setLoading } = useContext(Context);

	useEffect(() => {
		setLoading(!!watch);
	}, [watch]);
};
