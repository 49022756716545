import BasePage from '@components/Layout/BasePage';
import schema from '@schema/index';
import React, { FC, ReactNode } from 'react';
import { Box, Button, Chip } from '@mui/joy';
import { useError } from '@hooks/useError';
import Section from '../../../Dashboard/components/Seciton';
import { useSuccess } from '@hooks/useSuccess';

const Field: FC<{ label: string; value: ReactNode }> = ({ label, value }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}
		>
			<Box>{label}</Box>
			<Box>{value}</Box>
		</Box>
	);
};

const Status: FC = () => {
	const onError = useError();
	const onSuccess = useSuccess();

	const { data, error, loading } = schema.admin.get({ onError });

	const [clearCache] = schema.admin.clearCache({
		onError,
		onCompleted: () => {
			onSuccess('Cache cleared');
		},
	});

	const mongo = data?.Admin.database.attributes ?? [];
	const redis = data?.Admin.cache.attributes ?? [];

	const mongoStatus = mongo.find((item) => item.key === 'ok')?.value;
	const mongoGood = mongoStatus === '1';

	const redisStatus = redis.find(
		(item) => item.key === 'uptime_in_seconds'
	)?.value;
	const redisGood = parseInt(redisStatus ?? '0', 10) > 60;

	const systemGood = mongoGood && redisGood;

	return (
		<BasePage
			maxWidth={700}
			title="System Status"
			loading={loading}
			action={
				<Chip size="lg" color={systemGood ? 'success' : 'danger'}>
					{systemGood ? 'Good' : 'Error'}
				</Chip>
			}
		>
			<Box sx={{ marginLeft: -1.5 }}>
				<Section
					id="MongoDB"
					label="MongoDB"
					labelDecorator={
						<Chip color={mongoGood ? 'success' : 'danger'}>
							{mongoGood ? 'Good' : 'Error'}
						</Chip>
					}
				>
					{mongo.map((item) => {
						const match = mongoSchema[item.key];
						if (!match) return null;
						else
							return (
								<Field
									key={item.key}
									label={typeof match === 'string' ? match : match[0]}
									value={
										typeof match === 'string'
											? item.value
											: match[1](item.value)
									}
								/>
							);
					})}
				</Section>
				<Section
					id="Redis"
					label="Redis"
					labelDecorator={
						<Chip color={redisGood ? 'success' : 'danger'}>
							{redisGood ? 'Good' : 'Error'}
						</Chip>
					}
				>
					{redis.map((item) => {
						const match = redisSchema[item.key];
						if (!match) return null;
						else
							return (
								<Field
									key={item.key}
									label={typeof match === 'string' ? match : match[0]}
									value={
										typeof match === 'string'
											? item.value
											: match[1](item.value)
									}
								/>
							);
					})}
					<Box p={2} />
					<Button
						size="sm"
						variant="soft"
						onClick={() => {
							clearCache();
						}}
					>
						Clear Cache
					</Button>
				</Section>
			</Box>
		</BasePage>
	);
};

export default Status;

const mongoSchema: Record<string, string | [string, (d: string) => ReactNode]> =
	{
		db: 'Database',
		collections: 'Collections',
		objects: 'Documents',
		indexes: 'Indexes',
		fsUsedSize: [
			'Size',
			(d) => {
				const parsed = parseInt(d, 10);
				// return from bytes to MB
				return `${(parsed / 1024 / 1024 / 1024).toFixed(2)} GB`;
			},
		],
		ok: ['Status', (d) => (d === '1' ? 'OK' : 'Error')],
	};

const redisSchema: Record<string, string | [string, (d: string) => ReactNode]> =
	{
		redis_version: 'Version',
		uptime_in_seconds: [
			'Status',
			(d) => {
				const parsed = parseInt(d, 10);
				if (parsed < 60) return 'Error';
				return 'OK';
			},
		],
		connected_clients: 'Connected Clients',
		used_memory_human: 'Used Memory',
		used_memory_peak_human: 'Peak Memory',
	};
