import { useError } from '@hooks/useError';
import { Box, Button, Grid, Skeleton, Typography } from '@mui/joy';
import { EvaluationFilter } from '@schema/Evaluation/EvaluationFilter';
import { EvaluationType } from '@schema/Evaluation/EvaluationType';
import { frameworkKeys } from '@schema/Evaluation/framework';
import { insightScores } from '@schema/Evaluation/utils';
import { ResponseGroup } from '@schema/ResponseGroup/ResponseGroup';
import { ResponseSummary } from '@schema/ResponseSummary/ResponseSummary';
import schema from '@schema/index';
import { splitByCapital } from '@utils/splitByCapital';
import React, { FC, useEffect, useState, useTransition } from 'react';
import ScoreBar from './components/ScoreBar';
import { useSection } from '../Seciton';
import { useLoading } from '@hooks/useLoading';
import NetExplanation from './components/NetExplanation';
import { useStoredState } from '@utils/useStoredState';
import { MdExpandMore } from 'react-icons/md';
import NoData from '@components/Display/NoData';

export interface NetScoresProps {
	filter: EvaluationFilter;
}

const NetScores: FC<NetScoresProps> = ({ filter }) => {
	const [scores, setScores] = useState<NetScore[]>([]);
	const [parentScores, setParentScores] = useState<NetScore[]>([]);

	const {
		data: unitData,
		error: unitError,
		loading: unitLoading,
	} = useNetScores(filter);

	const {
		data: parentData,
		error: parentError,
		loading: parentLoading,
	} = useNetScores({
		...filter,
		ancestry: filter.ancestry?.slice(0, -1),
	});

	const [open, setOpen] = useStoredState('netScoresOpen', true);

	useEffect(() => {
		if (unitData.length) {
			setScores(unitData);
		}
	}, [unitData]);

	useEffect(() => {
		if (parentData.length) {
			setParentScores(parentData);
		}
	}, [parentData]);

	const loading = unitLoading || parentLoading;
	const error = unitError || parentError;

	const defLoading = useLoading(loading);

	useSection(defLoading);

	const [parentLabel, setParentLabel] = useState('');
	const [unitLabel, setUnitLabel] = useState('');

	schema.unit.get({
		variables: {
			id: filter.ancestry![filter.ancestry!.length - 1],
		},
		onCompleted: (data) => {
			setUnitLabel(data.Unit.name);
		},
	});

	schema.unit.get({
		variables: {
			id: filter.ancestry![filter.ancestry!.length - 2],
		},
		onCompleted: (data) => {
			setParentLabel(data.Unit.name);
		},
	});

	if (error)
		return (
			<NoData>
				<Typography>{error.message}</Typography>
			</NoData>
		);

	return (
		<Box>
			<Grid container spacing={12}>
				{scores.map((score, i) => {
					const parentScore =
						parentScores.find((s) => s.label === score.label) ?? null;

					return (
						<Grid key={`netScore_${i}`} xs={12} sm={12} md={6}>
							<Box>
								<Typography>{score.label}</Typography>
								<Box p={0.25} />
								<Typography sx={{ fontSize: '2rem' }}>
									<Skeleton loading={defLoading}>
										{score.score === null
											? 'NA'
											: `${score.score > 0 ? '+' : ''}${score.score}`}
									</Skeleton>
								</Typography>
								<Box p={0.5} />
								<ScoreBar
									parentLabel={parentLabel}
									unitLabel={unitLabel}
									parentScore={parentScore?.score ?? null}
								>
									{score.score}
								</ScoreBar>
								<NetExplanation open={open} score={score} />
							</Box>
						</Grid>
					);
				})}
			</Grid>
			<Box p={3} />
			<Button
				size="sm"
				variant="soft"
				onClick={() => setOpen(!open)}
				endDecorator={
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<MdExpandMore
							style={{
								transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
								transition: 'transform 0.25s ease',
							}}
						/>
					</Box>
				}
			>
				{open ? 'Hide' : 'Show'} Explanation
			</Button>
		</Box>
	);
};

export default NetScores;

export type NetScore = {
	label: string;
	score: number | null;
	groups: ResponseGroup[];
};

export const useNetScores = (
	filter: EvaluationFilter
): {
	data: NetScore[];
	error: Error | null | undefined;
	loading: boolean;
} => {
	const [pending, startTransition] = useTransition();
	const onError = useError();
	const lastAncestor = filter.ancestry
		? filter.ancestry[filter.ancestry.length - 1]
		: null;

	const [scores, setScores] = useState<NetScore[]>([]);

	const { error, loading } = schema.quantitySummary.get({
		variables: {
			unitFilter: { ids: [lastAncestor ?? ''] },
			evaluationFilter: filter,
		},
		onError,
		skip: !lastAncestor,
		onCompleted: (data) => {
			const getNetScores = (): NetScore[] => {
				if (!filter.type) return [];

				const scores: NetScore[] = [];

				const { responses } = data?.QuantitySummaries[0] ?? {
					responses: [] as ResponseSummary[],
				};

				const evalKeys: Record<EvaluationType, string[]> = {
					[EvaluationType.InstructorReflection]: Object.keys(frameworkKeys),
					[EvaluationType.PeerReview]: Object.keys(frameworkKeys),
					[EvaluationType.StudentCourseFeedback]: [
						'RecommendInstructor',
						'RecommendCourse',
					],
				};

				const keys = evalKeys[filter.type];

				for (const key of keys) {
					const matches = responses.filter((res) => res.prompt.includes(key));
					const responseGroups = matches.flatMap((match) => match.responses);

					const { promotors, detractors, total } = responseGroups.reduce(
						(acc, res) => {
							const val = insightScores[res.value] || 0;
							acc.total += res.count;
							if (val > 0) acc.promotors += res.count;
							else if (val < 0) acc.detractors += res.count;
							return acc;
						},
						{
							promotors: 0,
							detractors: 0,
							total: 0,
						}
					);

					const promotorShare = (promotors / total) * 100;
					const detractorShare = (detractors / total) * 100;

					const score = parseFloat((promotorShare - detractorShare).toFixed(1));

					const parsed = isNaN(score) ? null : score;

					scores.push({
						label: splitByCapital(key),
						score: parsed,
						groups: responseGroups,
					});
				}

				return scores;
			};

			const netScores = getNetScores();

			startTransition(() => {
				setScores(netScores);
			});
		},
	});

	return {
		data: scores,
		error,
		loading: loading || pending,
	};
};
