import { Role } from '@auth/Role';
import { ResourceIcon } from '@schema/Resource/Resource';
import React, { ReactNode } from 'react';
import {
	MdAdminPanelSettings,
	MdOutlineAccountCircle,
	MdOutlineDashboard,
} from 'react-icons/md';

export interface Nav {
	label: string;
	icon: ReactNode;
	element:
		| string
		| {
				label: string;
				path: string;
				role?: Role;
		  }[];

	role?: Role;
}

export enum View {
	Overview = 'Overview',
	StudentFeedback = 'StudentFeedback',
	PeerReviews = 'PeerReviews',
	InstructorReflection = 'InstructorReflection',
	SentimentMap = 'SentimentMap',
}

export const nav: Record<string, Nav> = {
	Dashboard: {
		label: 'Dashboard',
		icon: <MdOutlineDashboard />,
		element: [
			{
				label: 'Overview',
				path: '/app/dashboard/Overview',
				role: Role.Expanded,
			},
			{
				label: 'Student Feedback',
				path: '/app/dashboard/StudentFeedback',
			},
			{
				label: 'Peer Reviews',
				path: '/app/dashboard/PeerReviews',
				role: Role.Expanded,
			},
			{
				label: 'Instructor Reflection',
				path: '/app/dashboard/InstructorReflection',
				role: Role.Expanded,
			},
		],
	},
	Resources: {
		label: 'Resources',
		element: '/app/resources',
		icon: <ResourceIcon />,
		role: Role.Owner,
	},
	Admin: {
		label: 'Admin',
		icon: <MdAdminPanelSettings />,
		element: [
			{
				label: 'Pilot Access',
				path: '/app/admin/access',
				role: Role.Owner,
			},
			{
				label: 'System Status',
				path: '/app/admin/status',
				role: Role.Admin,
			},
			{
				label: 'Sentiment Map',
				path: '/app/dashboard/SentimentMap',
				role: Role.Owner,
			},
		],
		role: Role.Owner,
	},

	Account: {
		label: 'Account',
		icon: <MdOutlineAccountCircle />,
		element: '/app/account',
	},
};
