import { Box, Button, Typography, useColorScheme, useTheme } from '@mui/joy';
import React, { FC } from 'react';
import { MdArrowForward } from 'react-icons/md';
import Pop from '@components/Animation/Pop';
import CenterFlex from '@components/Layout/CenterFlex';
import Device from '@components/Display/Device';
import { LandingPhotoType, landingPhoto } from '../../screenshots';
import Bar from '../Bar';
import { useNavigate } from 'react-router-dom';

const textWidth = 500;

const GetStarted: FC<{ children?: string }> = ({ children }) => {
	const nav = useNavigate();
	return (
		<Box sx={{ display: 'flex' }}>
			<Pop>
				<Button
					size="lg"
					color="success"
					endDecorator={<MdArrowForward />}
					onClick={() => nav('/app')}
				>
					{children || 'Get Started'}
				</Button>
			</Pop>
		</Box>
	);
};

const What: FC = () => {
	const { palette } = useTheme();

	const { colorScheme } = useColorScheme();

	const photoType: LandingPhotoType = colorScheme === 'dark' ? 'Dark' : 'Light';

	return (
		<Box
			sx={{
				position: 'relative',
			}}
		>
			<Box
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
				}}
			>
				<Box
					sx={{
						height: '650px',
						background: palette.background.surface,
					}}
				>
					<CenterFlex>
						<Box p={6} />
						<Typography level="h1">
							Unlock insights from your qualitative data.
						</Typography>
						<Bar />
						<GetStarted />
					</CenterFlex>
				</Box>
				<Box
					sx={{
						height: '1400px',
						background: palette.background.level1,
					}}
				/>
			</Box>
			<Box
				sx={{
					'& h2': {
						whiteSpace: 'pre-line',
						zIndex: 100,
					},
					'& p': {
						maxWidth: textWidth,
						zIndex: 100,
					},
				}}
			>
				<CenterFlex>
					<Box p={22} />
					<Device
						alt="Perceptions"
						src={landingPhoto('Perceptions', photoType)}
					/>
					<Box p={12} />
					<Box
						sx={{
							position: 'relative',
							height: '700px',
						}}
					>
						<Box
							sx={{
								position: 'absolute',
								top: 0,
								left: 0,
								width: '100%',
								transform: 'translateX(-60%)',
							}}
						>
							<Device
								alt="Perceptions"
								src={landingPhoto('Chat', photoType)}
								animation="left"
							/>
						</Box>
						<Box
							sx={{
								position: 'absolute',
								top: 0,
								left: '40%',
								paddingLeft: 8,
							}}
						>
							<Typography level="h2">
								{`Discovering Deep Insights with AI-Powered Chat`}
							</Typography>
							<Bar />
							<Typography level="body-lg">
								Our innovative AI-powered chat feature enables users to ask
								specific questions about qualitative data, facilitating a deeper
								understanding of feedback nuances. This interactive chat
								provides precise, context-aware responses grounded in custom
								data sets.
							</Typography>
						</Box>
					</Box>
					<Box p={18} />
					<Box
						sx={{
							position: 'relative',
							height: '700px',
						}}
					>
						<Box
							sx={{
								position: 'absolute',
								top: 0,
								left: 0,
								width: '40%',
								paddingRight: 67,
							}}
						>
							<Typography level="h2">
								{`From Data to Dialogue: Prioritizing Diverse Perspectives`}
							</Typography>
							<Bar />
							<Typography level="body-lg">
								Our system goes beyond traditional data analysis methods to
								reveal the human voice behind the numbers. We custom-designed
								our AI-driven perceptions and chat to deliver efficiency at
								scale while normalizing incongruous datasets. This ensures that
								every voice within the community is acknowledged and valued,
								generating meaningful insights that prioritize diverse
								perspectives.
							</Typography>
						</Box>
						<Box
							sx={{
								position: 'absolute',
								top: 0,
								right: 0,
								width: '100%',
								transform: 'translateX(60%)',
							}}
						>
							<Device
								alt="Perceptions"
								src={landingPhoto('Cluster', photoType)}
								animation="right"
							/>
						</Box>
					</Box>
					<Box p={12} />
					<Box
						sx={{
							position: 'relative',
							height: '700px',
						}}
					>
						<Box
							sx={{
								position: 'absolute',
								top: 0,
								left: 0,
								width: '100%',
								transform: 'translateX(-60%)',
							}}
						>
							<Device
								alt="Cluster"
								src={landingPhoto('Sidebar', photoType)}
								animation="left"
							/>
						</Box>
						<Box
							sx={{
								position: 'absolute',
								top: 0,
								left: '40%',
								paddingLeft: 8,
							}}
						>
							<Typography level="h2">
								{`Using Comprehensive Dashboards for Insightful Analysis`}
							</Typography>
							<Bar />
							<Typography level="body-lg">
								Users can access specialized dashboards aligned with different
								aspects of the Teaching Excellence Framework: Student Feedback,
								Peer Review, and Instructor Reflection. An Overview dashboard
								triangulates data from various sources, enabling users to gain
								quick insights from diverse perspectives. Whether examining the
								broader university landscape or delving into specific courses,
								our hierarchical views ensure precise insights regardless of
								scale. The intuitive, flexible navigation facilitates effortless
								exploration from university-level data to individual classes.
								Users can isolate parts of the data for specialized analysis
								using the filter and time slider features.
							</Typography>
						</Box>
					</Box>
					<Box p={12} />
				</CenterFlex>
			</Box>
		</Box>
	);
};

export default What;
