import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { View } from '../../navigation/Nav';
import { AttributeInputPlural } from '@schema/Attribute/AttributeInput';

export interface DashboardScope {
	ancestry: string[];
	anchors: { start: number; end: number };
	attributes: AttributeInputPlural[];
	view: View;
}

export const useDashboardScope = (): [
	{ data: DashboardScope; error: Error | null },
	(data: DashboardScope) => void
] => {
	const nav = useNavigate();
	const [searchParms, setSearchParams] = useSearchParams();

	const {
		view,
		anchorStart: anchorStartInput,
		anchorEnd: anchorEndInput,
		u1,
		u2,
		u3,
		u4,
		u5,
		u6,
		u7,
		u8,
		u9,
		u10,
	} = useParams();

	const ancestryInputs = [u1, u2, u3, u4, u5, u6, u7, u8, u9, u10];
	const ancestry = ancestryInputs.filter(
		(input) => input !== undefined
	) as string[];

	const anchorStart = parseInt(anchorStartInput || 'nan');
	const anchorEnd = parseInt(anchorEndInput || 'nan');

	const viewError = Object.keys(View).find((key) => key === view)
		? null
		: new Error('Invalid view');

	const anchorError =
		isNaN(anchorStart) || isNaN(anchorEnd)
			? new Error('Invalid anchors')
			: null;

	const getAttributes = (): AttributeInputPlural[] => {
		const res: AttributeInputPlural[] = [];

		searchParms.forEach((v, k) => {
			res.push({ key: k, values: v.split(',') });
		});

		return res;
	};

	const attributes = getAttributes();

	return [
		{
			error: anchorError || viewError,
			data: {
				ancestry,
				anchors: { start: anchorStart, end: anchorEnd },
				attributes,
				view: view as View,
			},
		},
		(newParams) => {
			const pathname = `/app/dashboard/${view}/${newParams.anchors.start}/${
				newParams.anchors.end
			}/${newParams.ancestry.join('/')}`;

			const search = new URLSearchParams();

			newParams.attributes.forEach(({ key, values }) => {
				search.set(key, values.join(','));
			});

			console.log('search', search);

			setSearchParams(search);
			nav(pathname + '?' + search.toString());
		},
	];
};
