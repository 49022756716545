import { gql } from '@apollo/client';

export class EvaluationTimelineItem {
	unit!: string;
	count!: number;
	score!: number;
}

export class EvaluationTimeline {
	anchor!: number;
	unitScores!: EvaluationTimelineItem[];
}

export const EvaluationTimelineFragment = gql`
	fragment EvaluationTimelineFragment on EvaluationTimeline {
		anchor
		unitScores {
			unit
			count
			score
		}
	}
`;
