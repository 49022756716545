import { Box, BoxProps } from '@mui/joy';
import React, { FC, PropsWithChildren } from 'react';

export interface CenterFlexProps {
	maxWidth?: number;
	sx?: BoxProps['sx'];
	overflow?: boolean;
}

const CenterFlex: FC<PropsWithChildren<CenterFlexProps>> = ({
	maxWidth = 900,
	children,
	sx,
	overflow = true,
}) => {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				overflow: overflow ? undefined : 'hidden',
				...sx,
			}}
		>
			<Box
				sx={{
					maxWidth: `${maxWidth}px`,
					flex: 1,
					overflow: overflow ? undefined : 'hidden',
				}}
			>
				{children}
			</Box>
		</Box>
	);
};

export default CenterFlex;
