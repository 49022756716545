import CenterFlex from '@components/Layout/CenterFlex';
import { Box, Button, Typography } from '@mui/joy';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

export interface ProblemProps {
	error: Error | string;
	action?: ReactNode;
}

const Problem: FC<PropsWithChildren<ProblemProps>> = ({ error, action }) => {
	const nav = useNavigate();
	const actionElement = action ?? (
		<Button onClick={() => nav('/app')} startDecorator={<MdArrowBack />}>
			Go home
		</Button>
	);
	return (
		<Box
			sx={{
				height: '100%',
				display: 'flex',
				flexFlow: 'column',
				justifyContent: 'center',
			}}
		>
			<CenterFlex>
				<Box
					sx={{
						display: 'flex',
						flexFlow: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						textAlign: 'center',
					}}
				>
					<Typography level="h3">Whoops! Something went wrong.</Typography>
					<Box p={1} />
					<Typography color="danger">
						{typeof error === 'string' ? error : error.message}
					</Typography>
					<Box p={1.5} />
					{actionElement}
					<Box p={8} />
				</Box>
			</CenterFlex>
		</Box>
	);
};

export default Problem;
