import { getLazyQueryHook, getQueryHook } from '@hooks/graphql';
import * as Ops from './EvaluationProjectionCluster.Operations';

const evaluationProjectionClusterResolver = {
	get: getQueryHook<
		Ops.EvaluationProjectionClusterQueryRes,
		Ops.EvaluationProjectionClusterQueryVars
	>(Ops.EvaluationProjectionClustersQuery),
	tagged: getLazyQueryHook<
		Ops.EvaluationProjectionClusterTagQueryRes,
		Ops.EvaluationProjectionClusterTagQueryVars
	>(Ops.EvaluationProjectionClusterTagQuery),
	summarized: getQueryHook<
		Ops.EvaluationProjectionClusterSummaryQueryRes,
		Ops.EvaluationProjectionClusterSummaryQueryVars
	>(Ops.EvaluationProjectionClusterSummaryQuery),
};

export default evaluationProjectionClusterResolver;
