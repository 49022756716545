import { Box, Typography, useTheme } from '@mui/joy';
import React, { FC, ReactNode } from 'react';
import { motion } from 'framer-motion';

export interface SideButtonProps {
	open?: boolean;
	label?: string;
	icon?: ReactNode;
	active?: boolean;
	endDecorator?: ReactNode;
	onClick?: () => void;
}

const SideButton: FC<SideButtonProps> = ({
	open = false,
	label,
	icon,
	active,
	endDecorator,
	onClick,
}) => {
	const { palette } = useTheme();

	return (
		<Box
			onClick={onClick}
			sx={{
				display: 'flex',
				background: active ? palette.background.level1 : 'transparent',
				':hover': {
					background: palette.background.level1,
				},
				':active': {
					background: palette.background.level2,
				},
				p: 0.5,
				borderRadius: '8px',
				cursor: 'pointer',
				transition: 'background 0.1s',
			}}
		>
			{icon && (
				<Box
					sx={{
						height: '32px',
						width: '32px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						'& svg': {
							fontSize: '1.5rem',
							color: active ? palette.text.primary : palette.text.secondary,
						},
					}}
				>
					{icon}
				</Box>
			)}
			{label && (
				<motion.div
					variants={variants}
					initial={open ? 'open' : 'closed'}
					animate={open ? 'open' : 'closed'}
					transition={{ duration: 0.25 }}
					style={{
						overflow: 'hidden',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Box
						sx={{
							flex: 1,
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Box sx={{ width: '8px' }} />
						<Typography
							sx={{
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								color: active ? palette.text.primary : palette.text.secondary,
							}}
						>
							{label}
						</Typography>
						<Box
							sx={{
								minWidth: '16px',
								flex: 1,
							}}
						/>
						{endDecorator && (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									height: '24px',
									width: '24px',
									'& svg': {
										fontSize: '1.5rem',
									},
								}}
							>
								{endDecorator}
							</Box>
						)}
					</Box>
				</motion.div>
			)}
		</Box>
	);
};

export default SideButton;

const variants = {
	open: {
		width: 188,
		// opacity: 1,
	},
	closed: {
		width: 0,
		// opacity: 0,
	},
};
