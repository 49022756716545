import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Access from './components/Access';
import Status from './components/Status';
import Protected from '@auth/components/Protected';
import { Role } from '@auth/Role';

const Admin: FC = () => {
	return (
		<Routes>
			<Route index element={<Navigate to="access" />} />
			<Route path="access/*" element={<Access />} />
			<Route
				path="status/*"
				element={
					<Protected role={Role.Admin}>
						<Status />
					</Protected>
				}
			/>
		</Routes>
	);
};

export default Admin;
