import Bar from '@components/Display/Bar';
import { useLoading } from '@hooks/useLoading';
import { Box, Grid, Typography } from '@mui/joy';
import { getBestResponseGroup } from '@schema/Evaluation/utils';
import { ResponseSummary } from '@schema/ResponseSummary/ResponseSummary';
import { removeDigits } from '@utils/removeDigits';
import { splitByCapital } from '@utils/splitByCapital';
import React, { FC } from 'react';

export interface BenchmarkProps {
	children: ResponseSummary;
	loading: boolean;
}

const Benchmark: FC<BenchmarkProps> = ({ children: data, loading }) => {
	const getLabel = () => {
		if (data.prompt.includes('_')) {
			return splitByCapital(data.prompt.split('_')[1]);
		} else {
			return splitByCapital(removeDigits(data.prompt));
		}
	};

	const label = getLabel();

	const responseGroup = getBestResponseGroup(
		data.responses.map((r) => r.value)
	);

	const defLoading = useLoading(loading);

	const sum = data.responses.reduce((acc, r) => acc + r.count, 0);
	const share = (val: number) => (defLoading ? 0 : (val / sum) * 100);

	return (
		<Grid xs={12} sm={12} md={6} lg={6} xl={4}>
			<Typography>{label}</Typography>
			<Box p={0.5} />
			<Box
				sx={{
					display: 'flex',
					flexFlow: 'column',
					gap: 1,
				}}
			>
				{responseGroup.map((group) => {
					const match = data.responses.find((r) => r.value === group);
					const portion = share(match?.count ?? 0);

					return (
						<Bar
							value={isNaN(portion) ? 0 : portion}
							label={
								<Typography level="body-xs">
									{group} ({match?.count ?? '0'})
								</Typography>
							}
						/>
					);
				})}
			</Box>
		</Grid>
	);
};

export default Benchmark;
