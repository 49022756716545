import { motion } from 'framer-motion';
import React, { FC, PropsWithChildren } from 'react';

export interface CollapseProps {
	open: boolean;
	orientation?: 'v' | 'h';
}

const Collapse: FC<PropsWithChildren<CollapseProps>> = ({
	children,
	open,
	orientation = 'v',
}) => {
	const verticalVariants = {
		hidden: { height: 0 },
		visible: { height: 'auto' },
	};

	const horizontalVariants = {
		hidden: { width: 0 },
		visible: { width: 'auto' },
	};

	const variants = orientation === 'v' ? verticalVariants : horizontalVariants;

	return (
		<motion.div
			variants={variants}
			initial={open ? 'visible' : 'hidden'}
			animate={open ? 'visible' : 'hidden'}
			transition={{ duration: 0.2 }}
			style={{ overflow: 'hidden' }}
		>
			{children}
		</motion.div>
	);
};

export default Collapse;
