export const insightScores: Record<string, number> = {
	// Likert scale
	'Strongly Agree': 2,
	'Somewhat Agree': 1,
	'Mildly Agree': 1,
	'Neither Agree or Disagree': 0,
	'Somewhat Disagree': -1,
	'Mildly Disagree': -1,
	'Strongly Disagree': -2,

	// Boolean
	Positive: 2,
	Negative: -2,

	Yes: 2,
	Neutral: 0,
	No: -2,

	// Time
	Always: 1,
	Usually: 0,
	Rarely: -1,
	Never: -2,

	// Materials
	'I never used them': -1,
	'I did not buy them': -1,
	'I was not asked to purchase anything': 0,
	'I used them rarely': -1,
	'I used them often': 1,
};

const responseGroups = [
	[
		'Strongly Agree',
		'Somehwat Agree',
		'Neither Agree or Disagree',
		'Somewhat Disagree',
		'Strongly Disagree',
	],
	[
		'Strongly Agree',
		'Mildly Agree',
		'Neither Agree or Disagree',
		'Mildly Disagree',
		'Strongly Disagree',
	],
	['Positive', 'Neutral', 'Negative'],
	['Yes', 'Neutral', 'No'],
	['Always', 'Usually', 'Rarely', 'Never'],
	[
		'I never used them',
		'I did not buy them',
		'I was not asked to purchase anything',
		'I used them rarely',
		'I used them often',
	],
];

export const getBestResponseGroup = (responses: string[]): string[] => {
	const responseScores = responseGroups.map((group) => {
		const score = group.reduce((acc, curr) => {
			const index = responses.indexOf(curr);
			if (index !== -1) {
				return acc + index;
			}
			return acc;
		}, 0);
		return score;
	});

	const bestGroupIndex = responseScores.indexOf(Math.max(...responseScores));

	return responseGroups[bestGroupIndex];
};

export const getQualitativeComments = (
	input: Record<string, string>
): Record<string, string> | null => {
	const qualKeys = ['explain', 'comment', 'anythingelse'];

	const comments: Record<string, string> = {};

	for (const key in input) {
		if (qualKeys.some((qualKey) => key.toLowerCase().includes(qualKey))) {
			comments[key] = input[key];
		}
	}

	return Object.keys(comments).length > 0 ? comments : null;
};

export const getQuantitativeComments = (
	input: Record<string, string>
): Record<string, string> | null => {
	const comments: Record<string, string> = {};

	const forbiddenKeys = [
		'explain',
		'comment',
		'anythingelse',
		'usedincourse',
		'other',
		'how',
		'whyenroll',
	];

	for (const key in input) {
		if (
			!forbiddenKeys.some((forbiddenKey) =>
				key.toLowerCase().includes(forbiddenKey)
			)
		) {
			comments[key] = input[key];
		}
	}

	return Object.keys(comments).length > 0 ? comments : null;
};

export const getEvaluationScore = (input: Record<string, string>): number => {
	let positive = 0;
	let negative = 0;

	for (const key in input) {
		const val = input[key];
		if (insightScores[val] !== undefined) {
			if (insightScores[val] > 0) {
				positive += insightScores[val];
			} else if (insightScores[val] < 0) {
				negative -= insightScores[val];
			}
		}
	}

	const sum = positive + negative;
	const promotionShare = (positive / sum) * 100;
	const detractionShare = (negative / sum) * 100;
	const score = parseFloat((promotionShare - detractionShare).toFixed(4));

	return isNaN(score) ? 0 : score;
};

export const getScoreFromString = (input: string): number => {
	const score = insightScores[input];
	return score === undefined ? 0 : score;
};
