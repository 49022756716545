import { gql } from '@apollo/client';
import {
	EvaluationTimeline,
	EvaluationTimelineFragment,
} from './EvaluationTimeline';
import { UnitFilter } from '../Unit/Unit.Filter';
import { EvaluationFilter } from '../Evaluation/EvaluationFilter';

export const EvaluationTimelineQuery = gql`
	${EvaluationTimelineFragment}
	query EvaluationTimeline(
		$unitFilter: UnitFilter!
		$evaluationFilter: EvaluationFilter!
	) {
		EvaluationTimeline(
			unitFilter: $unitFilter
			evaluationFilter: $evaluationFilter
		) {
			...EvaluationTimelineFragment
		}
	}
`;

export type EvaluationTimelineArgs = {
	unitFilter: UnitFilter;
	evaluationFilter: EvaluationFilter;
};

export type EvaluationTimelineQueryRes = {
	EvaluationTimeline: EvaluationTimeline[];
};
