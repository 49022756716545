import { Role } from '@auth/Role';
import { hasRole } from '@auth/hasRole';
import { useClaimsContext } from '@providers/ClaimsProvider';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

export interface ProtectedProps {
	role: Role;
	fallback?: ReactNode;
}

const Protected: FC<PropsWithChildren<ProtectedProps>> = ({
	role,
	children,
	fallback = null,
}) => {
	const { claims } = useClaimsContext();
	const isValid = hasRole(role, claims);

	return <>{isValid ? children : fallback}</>;
};

export default Protected;
