import { UserMetadata } from './UserMetadata';
import { UserInfo } from './UserInfo';
import { Claims } from '@auth/Claims';
import { gql } from '@apollo/client';

export class User {
	readonly uid!: string;
	readonly email?: string;
	readonly emailVerified!: boolean;
	readonly displayName?: string;
	readonly photoURL?: string;
	readonly phoneNumber?: string;
	readonly disabled!: boolean;
	readonly metadata!: UserMetadata;
	readonly providerData!: UserInfo[];
	readonly claims!: Claims;
	readonly assignments!: {
		_id: string;
		name: string;
		type: string;
	}[];
}

export const UserFragment = gql`
	fragment UserFragment on User {
		uid
		email
		emailVerified
		displayName
		photoURL
		phoneNumber
		disabled
		metadata {
			...UserMetadataFragmment
		}
		providerData {
			...UserInfoFragmment
		}
		claims {
			...ClaimsFragmment
		}
		assignments {
			_id
			name
			type
		}
	}
`;

export const UserMetadataFragmment = gql`
	fragment UserMetadataFragmment on UserMetadata {
		creationTime
		lastSignInTime
		lastRefreshTime
	}
`;

export const UserInfoFragmment = gql`
	fragment UserInfoFragmment on UserInfo {
		uid
		displayName
		email
		photoURL
		providerId
		phoneNumber
	}
`;

export const ClaimsFragmment = gql`
	fragment ClaimsFragmment on Claims {
		role
	}
`;
