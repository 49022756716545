import {
	Box,
	Button,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from '@mui/joy';
import { AttributeInputPlural } from '@schema/Attribute/AttributeInput';
import schema from '@schema/index';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import FilterRow, { FilterRowProps } from './components/FilterRow';
import ResponsiveDialog from '@components/Layout/ResponsiveDialog';
import { MdCheck, MdClear } from 'react-icons/md';
import { niceJoin } from '@utils/niceJoin';

export interface FilterProps {
	children: (provided: {
		suggestedLabel: string;
		loading: boolean;
		error: Error | null;
		onClick: () => void;
	}) => ReactNode;
	value: AttributeInputPlural[];
	onChange: (value: AttributeInputPlural[]) => void;
}

const Filter: FC<FilterProps> = ({ children, value, onChange }) => {
	const { palette, radius } = useTheme();

	const [open, setOpen] = useState(false);

	const { data, loading, error } = schema.attribute.get();

	const [state, setState] = useState<AttributeInputPlural[] | null>(null);

	useEffect(() => {
		if (open && !state) {
			setState(value);
		}
	}, [open]);

	const apply = () => {
		onChange(state ? state.filter((s) => s.values.length > 0) : []);
		setOpen(false);
	};

	const clearAll = () => {
		setState([]);
	};

	const getSuggestedLabel = () => {
		if (value.length === 0) return 'Filters';
		else if (value.length > 1) return `Filters (${value.length})`;
		else {
			const labels: string[] = [];

			for (const val of value) {
				const entry = Object.entries(properties).find(
					([key]) => key === val.key
				);

				if (entry) {
					const label = entry[1].label;
					const inputs = entry[1].options;

					const inputEntries = Object.entries(inputs);

					const validEntries = inputEntries.filter(([k, v]) =>
						val.values.includes(k)
					);

					labels.push(`${label}: ${niceJoin(validEntries.map(([, v]) => v))}`);
				}
			}

			return niceJoin(labels, ', ', ' & ');
		}
	};

	const suggestedLabel = getSuggestedLabel();

	return (
		<>
			{children({
				suggestedLabel,
				loading,
				error: error ?? null,
				onClick: () => {
					setOpen(!open);
				},
			})}
			<ResponsiveDialog open={open} onClose={() => setOpen(false)}>
				<Box
					sx={{
						borderRadius: radius.md,
						background: palette.background.body,
						border: `1px solid ${palette.divider}`,
						height: '90vh',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Box
						sx={{
							p: 2,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<Typography level="h3">Filters</Typography>
						<IconButton size="lg" onClick={() => setOpen(false)}>
							<MdClear />
						</IconButton>
					</Box>
					<Divider />
					<Box sx={{ p: 2, overflowY: 'auto', overflowX: 'hidden', flex: 1 }}>
						<Stack direction="column" gap={4} sx={{ width: '720px' }}>
							{[...(data?.Options ?? [])]
								.sort((a, b) => {
									const aIndex = Object.keys(properties).indexOf(a.key);
									const bIndex = Object.keys(properties).indexOf(b.key);

									return aIndex - bIndex;
								})
								.map((option) => {
									const entry = Object.entries(properties).find(
										([key]) => key === option.key
									);

									if (!entry) return null;

									const rowOption: FilterRowProps['option'] = {
										key: option.key,
										label: entry[1].label,
										values: entry[1].options,
									};

									return (
										<FilterRow
											option={rowOption}
											value={state?.find((v) => v.key === option.key) ?? null}
											onChange={(v) => {
												if (v) {
													if (state) {
														const newState = [...state];
														const index = newState.findIndex(
															(s) => s.key === v.key
														);
														if (index === -1) {
															newState.push(v);
														} else {
															newState[index] = v;
														}
														setState(newState);
													}
												} else {
													setState(
														state?.filter((s) => s.key !== option.key) ?? []
													);
												}
											}}
										/>
									);
								})}
						</Stack>
					</Box>
					<Divider />
					<Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
						<Button
							variant="soft"
							size="lg"
							onClick={clearAll}
							startDecorator={<MdClear />}
						>
							Clear All
						</Button>
						<Button size="lg" onClick={apply} endDecorator={<MdCheck />}>
							Apply
						</Button>
					</Box>
				</Box>
			</ResponsiveDialog>
		</>
	);
};

export default Filter;

const properties: Record<
	string,
	{ label: string; options: Record<string, string> }
> = {
	requirement: {
		label: 'Requirement Designation',
		options: {
			AI: 'AI',
			AS: 'AS',
			BF: 'BF',
			BFHF: 'BFHF',
			BFSF: 'BFSF',
			CW: 'CW',
			CWDV: 'CWDV',
			CWHF: 'CWHF',
			CWIR: 'CWIR',
			CWSF: 'CWSF',
			DHBF: 'DHBF',
			DV: 'DV',
			DVBF: 'DVBF',
			DVSF: 'DVSF',
			DVFF: 'DVFF',
			DVHF: 'DVHF',
			FF: 'FF',
			HF: 'HF',
			IQIB: 'IQIB',
			IR: 'IR',
			IRBF: 'IRBF',
			IRFF: 'IRFF',
			IRHF: 'IRHF',
			IRSF: 'IRSF',
			LR: 'LR',
			QA: 'QA',
			QB: 'QB',
			QBBF: 'QBBF',
			QBCW: 'QBCW',
			QBHF: 'QBHF',
			QBQI: 'QBQI',
			QIBF: 'QIBF',
			QI: 'QI',
			QICW: 'QICW',
			QIIR: 'QIIR',
			QR: 'QR',
			QRQI: 'QRQI',
			SF: 'SF',
			SFHF: 'SFHF',
			WR1: 'WR1',
			WR2: 'WR2',
			'': 'None',
		},
	},
	instructionMode: {
		label: 'Instruction Mode',
		options: {
			H: 'Hybrid',
			I: 'Interactive Video Conferencing',
			IH: 'Hybrid & IVC',
			O: 'Online',
			P: 'In Person',
		},
	},
	trait: {
		label: 'Trait',
		options: {
			AOCE: 'University Connected Learning',
			CEL: 'Community Engaged Learning',
			CLAC: 'Cultures & Languages Across the Curriculum',
			FLXU: 'Flexible Schedule',
			HON: 'Honors College',
			LEAP: 'LEAP',
			OMBA: 'Online MBA',
			PACE: 'Professional and Community Education',
			PMBA: 'Professional MBA',
			SUST: 'Sustainability',
		},
	},
};
