import { gql } from '@apollo/client';

export class ResponseGroup {
	value!: string;
	count!: number;
}

export const ResponseGroupFragment = gql`
	fragment ResponseGroupFragment on ResponseGroup {
		value
		count
	}
`;
