import React, { FC } from 'react';
import { DashboardScope } from '../../useDashboardScope';
import { EvaluationType } from '@schema/Evaluation/EvaluationType';
import { Box } from '@mui/joy';
import Section from '../../components/Seciton';
import Timeline from '../../components/Timeline';
import Summary from '../../components/Summary';

export interface OverviewProps {
	scope: DashboardScope;
	setScope: (scope: DashboardScope) => void;
	type: string;
	setType: (type: string) => void;
	options: string[];
	evaluationType: EvaluationType;
	setEvaluationType: (evaluationType: EvaluationType) => void;
}

const Overview: FC<OverviewProps> = ({
	scope,
	setScope,
	type,
	setType,
	options,
	evaluationType,
	setEvaluationType,
}) => {
	return (
		<Box>
			<Box p={1} />
			<Section id="summary" label="Overview Summary">
				<Summary
					filter={{
						ancestry: scope.ancestry,
						attributes: scope.attributes,
						anchorMin: scope.anchors.start,
						anchorMax: scope.anchors.end,
					}}
				/>
			</Section>
			{/* <Section id="timeline" label="Evaluation Score Timeline">
				<Timeline
					scope={scope}
					filter={{
						type: evaluationType,
						ancestry: scope.ancestry,
						attributes: scope.attributes,
						anchorMin: scope.anchors.start,
						anchorMax: scope.anchors.end,
					}}
					type={type}
					setType={setType}
					options={options}
					evalType={evaluationType}
					setEvalType={setEvaluationType}
					onClick={(unitId) => {
						setScope({
							...scope,
							ancestry: [...scope.ancestry, unitId],
						});
					}}
				/>
			</Section> */}
		</Box>
	);
};

export default Overview;
